import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EntryType } from "@sharedV11/classes/entradas-y-promociones/tipo-entrada/entry-type";
import { JSON2ControlComponent } from "@sharedV11/components/json2control/json2control.component";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EntryTypeService } from "@sharedV11/services/components/entradas-y-promociones/entry-type/entry-type.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-entry-type",
  templateUrl: "./edit-entry-type.component.html",
  styleUrls: ["./edit-entry-type.component.scss"],
})
export class EditEntryTypeComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  loading = true;
  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;
  @ViewChild("jsonfield") jsonfield: JSON2ControlComponent;

  myJSON = '{"UsoMonedero":"0"}'; //customan

  configJSON = [
    {
      atributo: "UsoMonedero",
      title: "Tarjeta Monedero",
      help: "Tarjeta monedero: 0: Debe ser la etiqueta 'No aplica' que será el valor por defecto.  1: Debe ser la etiqueta 'Asignación manual'. 2: Debe ser la etiqueta 'Asignación automático'",
      type: "number",
    },
  ];

  translation;

  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  editorRegistroForm: FormGroup;

  entryType: EntryType;

  //---
  // GetTiposEntradaDatos
  TiposEntradaDatosAPI: any;
  tiposEntradaDatos: any[] = [];
  // GetGruposAsociados
  GruposAsociadosAPI: any;
  gruposAsociadosDatos: any[] = [];
  // GetTiposNiveles
  TiposNivelesAPI: any;
  tiposNivelesDatos: any[] = [];
  // GetTiposProductos
  TiposProductosAPI: any;
  tiposProductosDatos: any[] = [];
  // ObtenerCustomanTipoVisita
  TipoVisitaAPI: any;
  tipoVisitaDatos: any[] = [];
  // ObtenerCustomanTipoPublico
  TipoPublicoAPI: any;
  tipoPublicoDatos: any[] = [];
  // ObtenerCustomanTipoPublico
  TipoVisitanteAPI: any;
  tipoVisitanteDatos: any[] = [];
  // ObtenerCustomanNivel
  NivelAPI: any;
  NivelDatos: any[] = [];
  // ObtenerCustomanCategoria
  CategoriaAPI: any;
  categoriaDatos: any[] = [];
  // ObtenerCustomanCentroCoste
  CentroCosteAPI: any;
  centroCosteDatos: any[] = [];
  // ObtenerCustomanCanalesSub
  CanalesAPI: any;
  canalesDatos: any[] = [];
  // getCentros
  CentrosAPI: any;
  tipoCentros: any[] = [];

  // getTiposControlAsync
  tiposTiposControl: Array<any>;

  // getGruposTiposEntradaAsync
  tiposGrupoTipoEntrada: Array<any>;
  tiposGrupoTipoEntradaSinMotivo: Array<any>;
  tiposGrupoTipoEntradaMotivo: Array<any>;

  // getRecintosAsync
  tiposRecinto: Array<any>;

  // getTiposButacaAsync
  tiposTiposButaca: Array<any>;

  //
  centrosasociados: string[] = [];
  actividadesasociadas: string[] = [];
  recintosasignados: string[] = [];
  ordenRecintosAsociados: string[] = [];
  tiposBotoneraEntrada: Array<any>; //      this.cargarBotonera();

  //recintos
  recintosSinAsignar = [];
  recintosAsignados = [];
  //actividades
  actividadesSinAsignar = [];
  actividadesAsignadas = [];
  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  constructor(
    private translator: Translator,
    private formBuilder: FormBuilder,
    private _cds: ChangeDetectorRef,
    private panelService: PanelService,
    private servicioAPI: ConectarApiService,
    private entryTypeService: EntryTypeService,
    public global: GlobalService,
    private alertaServicio: AlertService,
    private httpErrorService: HttpErrorsService,
    private snackvar_service: SnackbarInfoService
  ) {}

  ngAfterViewInit() {
    this._cds.detectChanges();
  }

  ngOnInit(): void {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };

    this.generateForm();
    this.cargarDatosAsync();
  }

  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(null);
  }
  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }
  discard() {
    //this.changeStatusClientCode(true)
    //this.newEditar(this.tarifa)
    //this.changeStatusClientCode(false)
  }
  submit() {
    this.editorRegistroForm.markAllAsTouched();
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editorRegistroForm.valid) {
      this.entryType = this.editorRegistroForm.value;
      this.manageApi(this.entryType, this.mode);
    }
  }
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.entryType = this.editorRegistroForm.value;
      this.loading = false;
      this.valuedChanged();
      //this.changeStatusClientCode(false)
    }
    if (pkId != null) {
      this.findEntryType(pkId);
    }
  }
  findEntryType(pkId) {
    let paginator = {
      page: 1,
      pagelements: 10,
      orderby: "pkId",
      ordertype: "ASC",
    };
    let filters = [{ name: "pkId", value: this.pkId, translation: "", db: "" }];
    this.entryTypeService.getTiposEntradas(paginator, filters).subscribe(
      (response) => {
        if (response.DatosResult != null) {
          this.entryType = response.DatosResult.Lista[0];
          this.setValuesForm(response.DatosResult.Lista[0]);
          this.recintosAsignados = this.daTiposRecintos(true);
          this.actividadesAsignadas = this.daTiposRecintos(false);
          this.centrosAsignados = this.daTiposCentros();

          /*         this.recintosSinAsignar= this.filtrarTiposRecintos(true)
                this.recintosAsignados= this.daTiposRecintos(true)
                this.actividadesSinAsignar= this.filtrarTiposRecintos(false)
                this.actividadesAsignadas= this.daTiposRecintos(false)
                this.centrosSinAsignar= this.filtrarTiposCentros()
                this.centrosAsignados= this.daTiposCentros() */
          //this.entryType = this.editorRegistroForm.value
          this._cds.detectChanges();
          this.loading = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  getOrdenRecinto(pkId) {
    let response;
    this.tiposRecinto.forEach((r) => {
      if (r.pkId == pkId) {
        response = this.editorRegistroForm.get(r.pkId).value; // aqui
      }
    });
    return response;
  }
  obtenerRecintosAsociadosconOrden(): string {
    let respuesta: string = "";
    let elemento: string;
    let count = 0;
    if (this.recintosAsignados) {
      this.recintosAsignados.forEach((recinto) => {
        elemento = recinto.pkId + ":" + "0" + ",";
        respuesta = respuesta + elemento;
        count++;
      });
    }
    return respuesta.substring(0, respuesta.length - 1);
  }
  obtenerActividadesAsociadosconOrden(): string {
    let respuesta: string = "";
    let elemento: string;
    let count = 0;
    if (this.actividadesAsignadas) {
      this.actividadesAsignadas.forEach((actividad) => {
        elemento = actividad.pkId + ":" + "0" + ",";
        respuesta = respuesta + elemento;
        count++;
      });
    }
    return respuesta.substring(0, respuesta.length - 1);
  }
  obtenercentrosAsociadosconOrden(): string {
    let respuesta: string = "";
    let elemento: string;
    let count = 0;
    if (this.centrosAsignados) {
      this.centrosAsignados.forEach((centro) => {
        elemento = centro.pkId + ",";
        respuesta = respuesta + elemento;
        count++;
      });
    }
    return respuesta.substring(0, respuesta.length - 1);
  }
  insertDataInObj() {
    (this.entryType.pkId = this.pkId ? this.pkId : "0"),
      (this.entryType.DivisaId = this.global.divisapordefectoSistema);
    this.entryType.PrecioUnitario = "0"; //Ver Doc Requisitos
    this.entryType.FormatoId = "1"; //Ver Doc Requisitos
    this.entryType.IVA = "0"; //Ver Doc Requisitos
    this.entryType.BaseImponible = "0"; //Ver Doc Requisitos
    this.entryType.MaximoPorDia = "0"; //Ver Doc Requisitos
    this.entryType.TicketVendido = "0"; //Ver Doc Requisitos
    this.entryType.RecintosAsociados = this.obtenerRecintosAsociadosconOrden();
    this.entryType.ActividadesAsociadas =
      this.obtenerActividadesAsociadosconOrden();
    this.entryType.CentrosIds = this.obtenercentrosAsociadosconOrden();
    this.entryType.ALaVenta =
      this.global.aBooleano(this.entryType.ALaVenta) == true ? "1" : "0";
    this.entryType.CodigoBarras =
      this.global.aBooleano(this.entryType.CodigoBarras) == true ? "1" : "0";
    this.entryType.ImprimirTicket =
      this.global.aBooleano(this.entryType.ImprimirTicket) == true ? "1" : "0";
    this.entryType.ImprimirPromoInternet =
      this.global.aBooleano(this.entryType.ImprimirPromoInternet) == true
        ? "1"
        : "0";
    this.entryType.ControlarAcceso =
      this.global.aBooleano(this.entryType.ControlarAcceso) == true ? "1" : "0";
    this.entryType.ImprimirPrecio =
      this.global.aBooleano(this.entryType.ImprimirPrecio) == true ? "1" : "0";
    this.entryType.CuentaVisitante =
      this.global.aBooleano(this.entryType.CuentaVisitante) == true ? "1" : "0";
    this.entryType.BarCodeInternet =
      this.global.aBooleano(this.entryType.BarCodeInternet) == true ? "1" : "0";
  }
  manageApi(entrytype: EntryType, mode) {
    this.insertDataInObj();
    //this.changeStatusClientCode(true)
    this.loading = true;
    switch (mode) {
      case "I":
        this.entryType.pkId = "0";
        this.InsTiposEntradas(entrytype);
        break;
      case "U":
        //update
        this.entryType.ArgumentosCaracteristica = this.jsonfield.getJSON();
        this.UpdTiposEntradas(entrytype);
        break;
      case "D":
        //duplicate
        this.entryType.pkId = "0";
        this.entryType.ArgumentosCaracteristica = this.jsonfield.getJSON();
        this.InsTiposEntradas(entrytype);
        break;

      default:
        break;
    }
    //this.changeStatusClientCode(false)
  }
  UpdTiposEntradas(tiposentradas) {
    this.entryTypeService
      .insUpdTiposEntradas(tiposentradas, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  InsTiposEntradas(tiposentradas) {
    this.entryTypeService
      .insUpdTiposEntradas(tiposentradas, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  insUpdTiposEntradas() {
    this.servicioAPI.insUpdTiposEntradas(this.entryType, this.mode).subscribe(
      (response) => {
        this.save();
        this.destroy();
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      response.Mensajes.forEach((element) => {
        this.snackvar_service.openSnackBar(
          element.DescripcionMensaje,
          "",
          "red-snackbar",
          element.CodigoMensaje
        );
      });
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["MensajeSuccessSnackBar"],
        "",
        "green-snackbar"
      );
    }
  }
  valuedChanged() {
    this.editorRegistroForm.valueChanges.subscribe((value) => {
      if (this.entryType != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.entryType)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }
  generateForm() {
    this.editorRegistroForm = this.formBuilder.group({
      Nombre: ["", [Validators.required, Validators.maxLength(30)]],
      Descripcion: ["", Validators.maxLength(255)],
      TextoPantalla: ["", Validators.maxLength(30)],
      TextoDisplay: ["", Validators.maxLength(30)],
      TextoImpresion: ["", Validators.maxLength(50)],
      TipoAbreviado: ["", Validators.maxLength(15)],
      GrupoId: [""],
      GrupoMotivoId: [""],
      ControlarAcceso: [""],
      CodigoTipoControl: [""],
      ImpresoraSecundaria: [""],
      ALaVenta: [""],
      ImprimirTicket: [""],
      ImprimirPromoInternet: [""],
      CodigoBarras: [""],
      BarCodeInternet: [""],
      ImprimirPrecio: [""],
      TipoButaca: [""],
      CuentaVisitante: [""],
      TeclaRapida: [""],
      TipoProducto: [""],
      TipoVisita: [""],
      TipoPublicoId: [""],
      TipoVisitanteId: [""],
      NivelesId: [""],
      TipoNivel: [""],
      TipoButacaId: [""],
      Categoria: [""],
      TEDDatos: [""],
      GrupoAsociado: [""],
      CentroCosteId: [""],
      CanalId: [""],
    });

    const asociarCentrosCosteCanales = JSON.parse(
      localStorage.getItem("AsociarCentrosCosteCanales")
    );
    const ObligatorioCentrosDeCoste = JSON.parse(
      localStorage.getItem("ObligatorioCentrosDeCoste")
    );
    const ObligatorioCanalesEntradas = JSON.parse(
      localStorage.getItem("ObligatorioCanalesEntradas")
    );
    if (asociarCentrosCosteCanales == "1") {
      if (ObligatorioCentrosDeCoste == "1") {
        this.editorRegistroForm.addControl(
          "CentroCosteId",
          new FormControl("", Validators.required)
        );
      } else {
        this.editorRegistroForm.addControl(
          "CentroCosteId",
          new FormControl("")
        );
      }

      if (ObligatorioCanalesEntradas == "1") {
        this.editorRegistroForm.addControl(
          "CanalId",
          new FormControl("", Validators.required)
        );
      } else {
        this.editorRegistroForm.addControl("CanalId", new FormControl(""));
      }
    }
    //this.entryType = this.editorRegistroForm.value
  }

  setValuesForm(entryType) {
    this.f["Nombre"].setValue(entryType.Nombre);
    this.f["TipoAbreviado"].setValue(entryType.TipoAbreviado);
    this.f["Descripcion"].setValue(entryType.Descripcion);
    this.f["TextoPantalla"].setValue(entryType.TextoPantalla);
    this.f["TextoDisplay"].setValue(entryType.TextoDisplay);
    this.f["TextoImpresion"].setValue(entryType.TextoImpresion);
    this.f["GrupoMotivoId"].setValue(entryType.GrupoMotivoId);
    this.entryType.GrupoMotivoId = entryType.GrupoMotivoId;

    this.f["ControlarAcceso"].setValue(
      this.global.aBooleano(entryType.ControlarAcceso)
    );
    this.f["CodigoTipoControl"].setValue(entryType.CodigoTipoControl);
    this.entryType.CodigoTipoControl = entryType.CodigoTipoControl;
    this.f["ImpresoraSecundaria"].setValue(entryType.ImpresoraSecundaria);
    this.f["ALaVenta"].setValue(this.global.aBooleano(entryType.ALaVenta));
    this.f["ImprimirTicket"].setValue(
      this.global.aBooleano(entryType.ImprimirTicket)
    );
    this.f["ImprimirPromoInternet"].setValue(
      this.global.aBooleano(entryType.ImprimirPromoInternet)
    );
    this.f["CodigoBarras"].setValue(
      this.global.aBooleano(entryType.CodigoBarras)
    );
    this.f["BarCodeInternet"].setValue(
      this.global.aBooleano(entryType.BarCodeInternet)
    );
    this.f["ImprimirPrecio"].setValue(
      this.global.aBooleano(entryType.ImprimirPrecio)
    );
    this.f["TipoButaca"].setValue(entryType.TipoButaca);
    this.f["CuentaVisitante"].setValue(
      this.global.aBooleano(entryType.CuentaVisitante)
    );
    this.f["TeclaRapida"].setValue(entryType.TeclaRapida);
    this.f["GrupoId"].setValue(entryType.GrupoId);
    this.entryType.GrupoId = entryType.GrupoId;
    this.f["TipoProducto"].setValue(entryType.TipoProducto);
    this.entryType.TipoProducto = entryType.TipoProducto;
    this.f["GrupoAsociado"].setValue(entryType.GrupoAsociado);
    this.entryType.GrupoAsociado = entryType.GrupoAsociado;
    this.f["TipoVisita"].setValue(entryType.TipoVisita);
    this.f["TipoPublicoId"].setValue(entryType.TipoPublicoId);
    this.f["TipoVisitanteId"].setValue(entryType.TipoVisitanteId);
    this.f["NivelesId"].setValue(entryType.NivelesId);
    this.entryType.TipoVisita = entryType.TipoVisita;
    this.f["TipoNivel"].setValue(entryType.TipoNivel);
    this.entryType.TipoNivel = entryType.TipoNivel;
    this.f["TipoButacaId"].setValue(entryType.TipoButacaId);
    this.entryType.TipoButacaId = entryType.TipoButacaId;

    this.f["Categoria"].setValue(entryType.Categoria);
    this.entryType.Categoria = entryType.Categoria;
    this.f["TEDDatos"].setValue(entryType.TEDatos);
    this.entryType.TEDDatos = entryType.TEDatos;
    this.f["CentroCosteId"].setValue(entryType.CentroCosteId);
    this.entryType.CentroCosteId = entryType.CentroCosteId;
    this.f["CanalId"].setValue(entryType.CanalId);
    this.entryType.CanalId = entryType.CanalId;

    if (entryType.ArgumentosCaracteristica != "") {
      this.jsonfield.setJSON(entryType.ArgumentosCaracteristica);
      this.jsonfield.refresh();
    }

    this._cds.detectChanges();
  }

  cambiaNombre(evento) {
    //Si los modelos de Display, Taquilla y Imprimir estan vacios les pongo el Nombre del evento.

    if (
      this.f["TextoDisplay"].value == null ||
      this.f["TextoDisplay"].value.trim() == ""
    ) {
      this.f["TextoDisplay"].setValue(evento.target.value);
    }
    if (
      this.f["TextoPantalla"].value == null ||
      this.f["TextoPantalla"].value.trim() == ""
    ) {
      this.f["TextoPantalla"].setValue(evento.target.value);
    }
    if (
      this.f["TextoImpresion"].value == null ||
      this.f["TextoImpresion"].value.trim() == ""
    ) {
      this.f["TextoImpresion"].setValue(evento.target.value);
    }
  }
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2
      ? o1.id === o2.id || o1 === o2.id || o1.id === o2
      : o1 === o2;
  }
  asignarTipoCentro(item, evento) {
    this.centrosasociados.push(item.pkId);
  }
  desasignarTipoCentro(item, evento) {
    let arrtemp = [];
    arrtemp = this.centrosasociados.filter(function (x) {
      return x != item.pkId;
    });
    this.centrosasociados = arrtemp;
  }

  filtrarTiposCentros() {
    // return this.tipoCentros.filter(x => x.Activo == "True");
    let respuesta = [];
    let Centros = this.tipoCentros.filter((x) => x.Activo == "True");
    let tmpCentrosAsociados = this.centrosasociados;
    if (Centros != null && Centros.length > 0) {
      respuesta = Centros.filter(function (item) {
        return (
          tmpCentrosAsociados.findIndex(function (itemSub) {
            return itemSub == item.pkId;
          }) === -1
        );
      });
    } else {
      respuesta = this.tipoCentros.filter((x) => x.Activo == "True");
    }
    return respuesta;
  }

  deasignarTodosCentros() {
    this.centrosasociados = [];
  }
  asignarTodosCentros() {
    this.centrosasociados = this.tipoCentros
      .filter((x) => x.Activo == "True")
      .map(function (x) {
        return x.pkId;
      });
  }

  daTiposCentros(): any[] {
    let respuesta = [];
    if (this.tipoCentros != null) {
      if (this.entryType.CentrosIds) {
        let arrayCentrosString = this.entryType.CentrosIds.split(",");
        let arrayCentros = [];
        arrayCentrosString.map((asociado) => {
          let asociadoCopy = asociado.split(":");
          arrayCentros.push(asociadoCopy);
        });

        let allCentros = this.tipoCentros.filter((x) => x.Activo == "True");

        let array = arrayCentros.map((recinto) => {
          let act = allCentros.find((find) => find.pkId == recinto[0]);
          respuesta.push(act);
        });
      }
    }

    return respuesta;
  }

  //True = recinto, false = Actividad
  filtrarTiposRecintos(tipo?: boolean): any[] {
    let respuesta = [];

    if (tipo != null && this.tiposRecinto != null) {
      if (tipo) {
        let Recintos = this.tiposRecinto.filter((x) => x.Taller != "1");
        let tmpRecintosAsociados = this.recintosasignados;
        if (Recintos != null && Recintos.length > 0) {
          respuesta = Recintos.filter(function (item) {
            return (
              tmpRecintosAsociados.findIndex(function (itemSub) {
                return itemSub == item.pkId;
              }) === -1
            );
          });
        } else {
          respuesta = this.tiposRecinto;
        }
      } else {
        let Talleres = this.tiposRecinto.filter((x) => x.Taller == "1");
        let tmpActividadesAsociadas = this.actividadesasociadas;
        if (Talleres != null && Talleres.length > 0) {
          respuesta = Talleres.filter(function (item) {
            return (
              tmpActividadesAsociadas.findIndex(function (itemSub) {
                return itemSub == item.pkId;
              }) === -1
            );
          });
        } else {
          respuesta = this.tiposRecinto;
        }
      }
    }
    return respuesta;
  }

  deasignarTodasActividades() {
    this.actividadesasociadas = [];
  }
  asignarTodasActividades() {
    this.actividadesasociadas = this.tiposRecinto
      .filter((x) => x.Taller == "1")
      .map(function (x) {
        return x.pkId;
      });
  }
  //---Asignacion Actividades
  asignarTipoActividad(item, evento) {
    this.actividadesasociadas.push(item.pkId);
  }
  desasignarTipoActividad(item, evento) {
    let arrtemp = [];
    arrtemp = this.actividadesasociadas.filter(function (x) {
      return x != item.pkId;
    });
    this.actividadesasociadas = arrtemp;
  }

  //True = recinto, false = Actividad heree
  daTiposRecintos(tipo?: boolean): any[] {
    let respuesta = [];

    if (tipo != null && this.tiposRecinto != null) {
      if (tipo) {
        if (this.entryType.RecintosAsociados) {
          let arrayRecintosString = this.entryType.RecintosAsociados.split(",");
          let arrayRecintos = [];
          arrayRecintosString.map((asociado) => {
            let asociadoCopy = asociado.split(":");
            arrayRecintos.push(asociadoCopy);
          });
          let allRecintos = this.tiposRecinto.filter((x) => x.Taller != "1");

          let array = arrayRecintos.map((recinto) => {
            let act = allRecintos.find((find) => find.pkId == recinto[0]);
            respuesta.push(act);
          });
        }
      } else {
        if (this.entryType.ActividadesAsociadas) {
          let arrayAsociadosString =
            this.entryType.ActividadesAsociadas.split(",");
          let arrayAsociados = [];
          arrayAsociadosString.map((asociado) => {
            let asociadoCopy = asociado.split(":");
            arrayAsociados.push(asociadoCopy);
          });

          let allAsociados = this.tiposRecinto.filter((x) => x.Taller == "1");
          arrayAsociados.map((asociado) => {
            let act = allAsociados.find((find) => find.pkId == asociado[0]);
            respuesta.push(act);
          });
        }
      }
    }
    return respuesta;
  }

  //---Asignacion Recintos
  asignarTipoRecinto(item, evento) {
    this.recintosasignados.push(item.pkId);
    this.ordenRecintosAsociados.push(null);
  }

  desasignarTipoRecinto(item, evento) {
    let arrtemp = [];
    let index = this.recintosasignados.indexOf(item.pkId);
    if (index >= 0) {
      this.ordenRecintosAsociados.splice(index, 1);
    }
    arrtemp = this.recintosasignados.filter(function (x) {
      return x != item.pkId;
    });
    this.recintosasignados = arrtemp;
  }

  deasignarTodosRecintos() {
    this.recintosasignados = [];
    this.ordenRecintosAsociados = [];
  }
  asignarTodosRecintos() {
    // Coger los Ordenes Actuales y ponerlos en el nuevo array
    let numItem: number = 0;
    let tmpElementos: { idRecinto: string; orden: string }[] = [];
    this.ordenRecintosAsociados.forEach((element) => {
      if (element != null && element.trim() != "") {
        tmpElementos.push({
          idRecinto: this.recintosasignados[numItem],
          orden: element,
        });
      }
      numItem++;
    });
    //cargamos todos
    this.recintosasignados = this.tiposRecinto
      .filter((x) => x.Taller != "1")
      .map(function (x) {
        return x.pkId;
      });
    this.ordenRecintosAsociados = [];
    //Repasamos para poner los ordenes correctos y lo demas a null
    this.recintosasignados.forEach((element) => {
      let tmpIndex = tmpElementos.findIndex((y) => y.idRecinto == element);
      if (tmpIndex >= 0) {
        this.ordenRecintosAsociados.push(tmpElementos[tmpIndex].orden);
      } else {
        this.ordenRecintosAsociados.push(null);
      }
    });
  }

  validacion() {
    const separador = document.getElementsByClassName("separadorLateral");
    const campos = document.getElementsByClassName("editor-campo-registro");
    for (let i = 0; i < campos.length; i++) {
      if (campos[i].classList.contains("ng-valid")) {
        if (campos[i].classList.contains("editor-campo-registro-check")) {
          separador[i].setAttribute("style", "border-color:#002c76");
        } else {
          separador[i].setAttribute("style", "border-color:green");
        }
      } else {
        separador[i].setAttribute("style", "border-color:#002c76");
      }
    }
  }
  async cargarBotonera(): Promise<boolean> {
    let resultado: boolean = false;
    await this.entryTypeService
      .getBotoneraAsync(
        null,
        this.global.maxBotonesRapidosTPV.toString().trim()
      )
      .then(
        (data) => {
          if (data.DatosResult != null) {
            this.tiposBotoneraEntrada = data.DatosResult.Lista;
          } else {
            this.alertaServicio.error(
              "Botonera: " + data.Mensajes[0].DescripcionMensaje
            );
            resultado = false;
          }
        },
        (error) => {
          //this.alertaServicio.error('Botonera: ' + error);
          this.httpErrorService.identificarErrores(error);
          resultado = false;
        }
      );
    return resultado;
  }
  createOrderFormFields() {
    this.tiposRecinto.forEach((r) => {
      this.editorRegistroForm.addControl(r.pkId, new FormControl(""));
    });
  }
  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;

    this.entryTypeService.GetTiposEntradaDatos().subscribe(
      (data) => {
        this.TiposEntradaDatosAPI = data;
        this.tiposEntradaDatos = [];
        if (this.TiposEntradaDatosAPI.DatosResult != null) {
          if (
            this.TiposEntradaDatosAPI.DatosResult.ListadoTiposEntradaDatos !=
            null
          ) {
            this.TiposEntradaDatosAPI.DatosResult.ListadoTiposEntradaDatos.forEach(
              (element) => {
                this.tiposEntradaDatos.push(element);
              }
            );
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.GetGruposAsociados().subscribe(
      (data) => {
        this.GruposAsociadosAPI = data;
        this.gruposAsociadosDatos = [];
        if (this.GruposAsociadosAPI.DatosResult != null) {
          if (
            this.GruposAsociadosAPI.DatosResult.ListadoGruposAsociados != null
          ) {
            this.GruposAsociadosAPI.DatosResult.ListadoGruposAsociados.forEach(
              (element) => {
                this.gruposAsociadosDatos.push(element);
              }
            );
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.GetTiposNiveles().subscribe(
      (data) => {
        this.TiposNivelesAPI = data;
        this.tiposNivelesDatos = [];
        if (this.TiposNivelesAPI.DatosResult != null) {
          if (this.TiposNivelesAPI.DatosResult.ListadoTiposNiveles != null) {
            this.TiposNivelesAPI.DatosResult.ListadoTiposNiveles.forEach(
              (element) => {
                this.tiposNivelesDatos.push(element);
              }
            );
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.GetTiposProductos().subscribe(
      (data) => {
        this.TiposProductosAPI = data;
        this.tiposProductosDatos = [];
        if (this.TiposProductosAPI.DatosResult != null) {
          if (
            this.TiposProductosAPI.DatosResult.ListadoTiposProductos != null
          ) {
            this.TiposProductosAPI.DatosResult.ListadoTiposProductos.forEach(
              (element) => {
                this.tiposProductosDatos.push(element);
              }
            );
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.ObtenerCustomanTipoVisita().subscribe(
      (data) => {
        this.TipoVisitaAPI = data;
        this.tipoVisitaDatos = [];
        if (this.TipoVisitaAPI.DatosResult != null) {
          if (this.TipoVisitaAPI.DatosResult.lista != null) {
            this.TipoVisitaAPI.DatosResult.lista.forEach((element) => {
              this.tipoVisitaDatos.push(element);
            });
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.ObtenerCustomanTipoPublico().subscribe(
      (data) => {
        this.TipoPublicoAPI = data;
        this.tipoPublicoDatos = [];
        if (this.TipoPublicoAPI.DatosResult != null) {
          if (this.TipoPublicoAPI.DatosResult.lista != null) {
            this.TipoPublicoAPI.DatosResult.lista.forEach((element) => {
              this.tipoPublicoDatos.push(element);
            });
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.ObtenerCustomanTipoVisitante().subscribe(
      (data) => {
        this.TipoVisitanteAPI = data;
        this.tipoVisitanteDatos = [];
        if (this.TipoVisitanteAPI.DatosResult != null) {
          if (this.TipoVisitanteAPI.DatosResult.lista != null) {
            this.TipoVisitanteAPI.DatosResult.lista.forEach((element) => {
              this.tipoVisitanteDatos.push(element);
            });
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.ObtenerCustomanNivel().subscribe(
      (data) => {
        this.NivelAPI = data;
        this.NivelDatos = [];
        if (this.NivelAPI.DatosResult != null) {
          if (this.NivelAPI.DatosResult.lista != null) {
            this.NivelAPI.DatosResult.lista.forEach((element) => {
              this.NivelDatos.push(element);
            });
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.ObtenerCustomanCategoria().subscribe(
      (data) => {
        this.CategoriaAPI = data;
        this.categoriaDatos = [];
        if (this.CategoriaAPI.DatosResult != null) {
          if (this.CategoriaAPI.DatosResult.lista != null) {
            this.CategoriaAPI.DatosResult.lista.forEach((element) => {
              this.categoriaDatos.push(element);
            });
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.ObtenerCustomanCentroCoste().subscribe(
      (data) => {
        this.CentroCosteAPI = data;
        this.centroCosteDatos = [];
        if (this.CentroCosteAPI.DatosResult != null) {
          if (this.CentroCosteAPI.DatosResult.Lista != null) {
            this.CentroCosteAPI.DatosResult.Lista.forEach((element) => {
              this.centroCosteDatos.push(element);
            });
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.ObtenerCustomanCanalesSub().subscribe(
      (data) => {
        this.CanalesAPI = data;
        this.canalesDatos = [];
        if (this.CanalesAPI.DatosResult != null) {
          if (this.CanalesAPI.DatosResult.Lista != null) {
            this.CanalesAPI.DatosResult.Lista.forEach((element) => {
              if (element.Seleccionable == "1") this.canalesDatos.push(element);
            });
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.entryTypeService.getCentros().subscribe(
      (data) => {
        this.CentrosAPI = data;
        this.tipoCentros = [];
        if (this.CentrosAPI.DatosResult != null) {
          if (this.CentrosAPI.DatosResult.ListaCentros != null) {
            this.tipoCentros = this.CentrosAPI.DatosResult.ListaCentros;
            /*this.CentrosAPI.DatosResult.ListaCentros.forEach(element => {
            this.centrosDatos.push(element);
          });*/
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    await this.entryTypeService.getTiposControlAsync().then(
      (data) => {
        if (data.DatosResult != null) {
          this.tiposTiposControl = data.DatosResult.customanTiposControl;
        } else {
          this.alertaServicio.error(data.Mensajes[0].DescripcionMensaje);
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.entryTypeService.getGruposTiposEntradaAsync().then(
      (data) => {
        if (data.DatosResult != null) {
          this.tiposGrupoTipoEntrada =
            data.DatosResult.customanGrupoTipoEntrada;
          this.tiposGrupoTipoEntradaSinMotivo =
            data.DatosResult.customanGrupoTipoEntrada.filter(
              (x) => x.ConMotivo == "0"
            );
          this.tiposGrupoTipoEntradaMotivo =
            data.DatosResult.customanGrupoTipoEntrada.filter(
              (x) => x.ConMotivo == "1"
            );
        } else {
          //this.alertaServicio.error(this.tiposGruposActividadesAPI.Mensajes[0].DescripcionMensaje);
          this.tiposGrupoTipoEntrada = [];
          this.tiposGrupoTipoEntradaSinMotivo = [];
          this.tiposGrupoTipoEntradaMotivo = [];
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.entryTypeService.getRecintosAsync().then(
      (data) => {
        if (data.DatosResult != null) {
          this.tiposRecinto = data.DatosResult.ListaRecintos;
          //this.createOrderFormFields();
        } else {
          this.tiposRecinto = [];
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.entryTypeService.getTiposButacaAsync().then(
      (data) => {
        if (data.DatosResult != null) {
          this.tiposTiposButaca = data.DatosResult.DatosListas;
        } else {
          this.tiposTiposButaca = [];
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    this.cargarBotonera();
    this.manageForm(this.pkId, this.mode);
    this.recintosSinAsignar = this.filtrarTiposRecintos(true);
    this.actividadesSinAsignar = this.filtrarTiposRecintos(false);
    this.centrosSinAsignar = this.filtrarTiposCentros();

    return resultado;
  }

  closeInputFilter(enventValues) {
    this.editorRegistroForm.get(enventValues.name).setValue(enventValues.value);
  }
}
