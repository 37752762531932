import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
  TipoEntrada,
  TiposEntrada_Respuesta,
} from "@sharedV11/classes/clients/client/tipos-entrada-Respuesta.model";
import { Recintos } from "@sharedV11/classes/recintos/recintos-visita/recintos";
import { TipoControl } from "@sharedV11/classes/recintos/recintos-visita/tipoControl";
import { Recintos_Respuesta } from "@sharedV11/classes/recintos/tipos_sesion";
import { IdiomaGR } from "@sharedV11/classes/tarifaResponse/idiomaGR";
import { EmailComponent } from "@sharedV11/components/dialogs/email/email.component";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { RecintosVisitaService } from "@sharedV11/services/components/recintos/recintos-visita/recintos-visita.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import moment from "moment";

@Component({
  selector: "app-edit-recintos-visita",
  templateUrl: "./edit-recintos-visita.component.html",
  styleUrls: ["./edit-recintos-visita.component.scss"],
})
export class EditRecintosVisitaComponent implements OnInit {
  loading = true;
  @Input() pkId: any;
  @Input() mode: any;
  @Input() modificar: boolean;

  editorRegistroForm: FormGroup;
  recintoProfile: Recintos;

  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  translation: any;

  butacasCheck: boolean = false;

  // Sub grupos Recintos
  subGruposRecintos;

  // Grupos Recintos
  gruposRecintos: any[];

  //Tipos Grupos Actividades
  tiposGruposActividades: Array<any>;

  //Idiomas
  editorListaIdiomas: Array<IdiomaGR>;

  // Tipo Entrada
  tiposTipoEntrada: Array<TipoEntrada>;

  // Tipo Entrada Taller
  tiposTipoEntradaTaller: Array<TipoEntrada>;
  // Tipo Entrada Recinto
  tiposTipoEntradaRecinto: Array<TipoEntrada>;

  //tiposTiposControl
  tiposTiposControl: Array<TipoControl>;

  // cargarDatos
  tiposGruposActividadesAPI: any;
  tiposTiposControlAPI: any;
  tiposIdiomasAPI: any;
  tiposIdiomas: any;
  tiposGruposRecintoAPI: any;
  tiposGruposRecinto: Array<any>;
  respuestaAPI: Recintos_Respuesta;
  tiposTiposEntradaAPI: TiposEntrada_Respuesta;

  //emails
  listaEmailsEnvio: any;

  //entradas dual-list
  entradasSinAsignar: any = [];
  entradasAsignadas: any = [];

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros

  constructor(
    private translator: Translator,
    private fb: FormBuilder,
    private panelService: PanelService,
    private servicioAPI: ConectarApiService,
    public global: GlobalService,
    private recintosVisitaService: RecintosVisitaService,
    private alertaServicio: AlertService,
    private _cds: ChangeDetectorRef,
    public dialog: MatDialog,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.cargarDatosAsync();
    //this.initializeFormValidations();
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }
  get f() {
    return this.editorRegistroForm.controls;
  }
  initializeFormWithoutData() {
    this.editorRegistroForm = this.fb.group({
      Nombre: ["", Validators.required],
      NumProj: [""],
      Activo: [""],
      PorDefecto: [""],
      HoraInicio: [""],
      HoraFin: [""],
      ControlarAforo: [""],
      ControlarAforoReserva: [""],
      TipoAforo: ["", Validators.required],
      AforoMaximo: ["", [Validators.pattern("^-?[0-9]*$"), Validators.min(0)]],
      LimiteAforo: ["", [Validators.pattern("^-?[0-9]*$")]],
      MinimoAforo: ["", [Validators.pattern("^-?[0-9]*$"), Validators.min(0)]],
      MaximoInternet: [
        "",
        [Validators.pattern("^-?[0-9]*$"), Validators.min(0)],
      ],
      TiempoExtra: ["", [Validators.pattern("^-?[0-9]*$")]],
      MostrarInternet: [""],
      //NumSesion: [''],
      Taller: [""],
      EmailEnvioBD: [""],
      ControlExterno: [""],
      GrupoActividadId: [""],
      GrupoRecintosId: [""],
      SubGrupoRecinto: [""],
      Butacas: [""],
      Shape: [""],
      Coords: [""],
      OrdenFilas: [""],
      OrdenButacas: [""],
      CodigoTipoControl: [""],
    });
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.recintoProfile = this.editorRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != undefined) {
      this.serviceCall(pkId);
    }
  }

  serviceCall(pkId) {
    let paginator = {
      page: 1,
      pagelements: 10,
      orderby: "pkid",
      ordertype: "ASC",
    };
    let filters = [{ name: "pkId", value: pkId, translation: "", db: "" }];
    this.servicioAPI.getRecintos(paginator, filters).subscribe(
      (result) => {
        if (result.DatosResult != null) {
          let recintoProfile = result["DatosResult"]["ListaRecintos"][0];

          if (recintoProfile != null) {
            this.changeValueForm(recintoProfile);
            this.recintoProfile = this.editorRegistroForm.value;
            this.recintoProfile = recintoProfile;
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados = this.gsCentros.daTiposCentros(
              this.recintoProfile
            );
            //
            this.valuedChanged();
            this.loading = false;
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  valuedChanged() {
    this.editorRegistroForm.valueChanges.subscribe((value) => {
      if (this.recintoProfile != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.recintoProfile)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }
  changeInputFilter(input) {}
  submit() {
    //this.insertDataInObj()

    this.editorRegistroForm.markAllAsTouched();
    // let client:Cliente=this.editorRegistroForm.value;

    if (this.editorRegistroForm.valid) {
      this.recintoProfile = this.editorRegistroForm.value;
      this.manageApi(this.mode);
    } else {
      if (!this.editorRegistroForm.controls["TipoAforo"].valid)
        this.snackvar_service.openSnackBar(
          this.translation.especificar_tipo_aforo,
          "",
          "green-snackbar"
        );
    }

    /*     if (this.editorRegistroForm.valid) {
      this.recintoProfile = this.editorRegistroForm.value
      this.manageApi(this.mode)
    } */
  }
  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(null);
  }
  destroy() {
    this.panelService.setDiscardChanges(true);
    this.loading = false;
    this.panelService.setClosePanel(null);
  }

  discard() {
    this.changeValueForm(this.recintoProfile);
  }
  /* insertDataInForm() {
    this.f["TipoAforo"].setValue((<HTMLInputElement>document.getElementById("TipoAforo-hidden")).value)
    this.f["CodigoTipoControl"].setValue((<HTMLInputElement>document.getElementById("CodigoTipoControl-hidden")).value)
    this.f["GrupoActividadId"].setValue((<HTMLInputElement>document.getElementById("GrupoActividadId-hidden")).value)
    this.f["GrupoRecintosId"].setValue((<HTMLInputElement>document.getElementById("GrupoRecintosId-hidden")).value)
    this.f["SubGrupoRecinto"].setValue((<HTMLInputElement>document.getElementById("SubGrupoRecinto-hidden")).value)
  }
  insertDataInObj() {
    this.recintoProfile.TipoAforo = (<HTMLInputElement>document.getElementById("TipoAforo-hidden")).value
    this.recintoProfile.CodigoTipoControl = (<HTMLInputElement>document.getElementById("CodigoTipoControl-hidden")).value
    this.recintoProfile.GrupoActividadId = (<HTMLInputElement>document.getElementById("GrupoActividadId-hidden")).value
    this.recintoProfile.GrupoRecintosId = (<HTMLInputElement>document.getElementById("GrupoRecintosId-hidden")).value
    this.recintoProfile.SubGrupoRecinto = (<HTMLInputElement>document.getElementById("SubGrupoRecinto-hidden")).value
  } */
  manageApi(mode) {
    this.loading = true;

    //this.insertDataInObj()
    //this.changeStatusClientCode(true)
    switch (mode) {
      case "I":
        //insert
        //this.f["Codigo"].setValue("0");
        //this.RegistrarModificar = "0"
        //this.newGuardar()
        this.recintoProfile.pkId = "0";
        //this.newGuardar();
        this.guardarPopup();
        break;
      case "U":
        //this.RegistrarModificar = "1"
        //update
        //this.newGuardar()

        //this.newGuardar();
        this.guardarPopup();

        break;
      case "D":
        //duplicate
        //this.RegistrarModificar = "0"
        // this.f["Codigo"].setValue("0");
        //this.newGuardar()
        this.mode = "I";
        this.recintoProfile.pkId = "0";

        //this.newGuardar();
        this.guardarPopup();

        break;

      default:
        break;
    }
    //this.changeStatusClientCode(false)
  }
  newGuardar() {
    /* this.entradasList = [];
    if (this.tiposTipoEntradaTaller.length !== 0) {
      this.tiposTipoEntradaTaller.forEach(item => {
        this.entradasList.push(item.EntradaId);
      });
    } else if (this.tiposTipoEntradaRecinto.length !== 0) {
      this.tiposTipoEntradaRecinto.forEach(item => {
        this.entradasList.push(item.EntradaId);
      });
    } */
    let entradasAsignadasWithPkId = [];
    this.entradasAsignadas.map((entrada) => {
      entradasAsignadasWithPkId.push(entrada.EntradaId);
    });

    this.editorListaIdiomas.map((idioma) => {
      idioma.Nombre = this.f["Nombre-" + idioma.CodIdioma].value;
      idioma.Descripcion = this.f["Descripcion-" + idioma.CodIdioma].value;
      //idioma.Descripcion1 = this.f["Descripcion1-" + idioma.CodIdioma].value
      //idioma.Descripcion2 = this.f["Descripcion2-" + idioma.CodIdioma].value
      idioma.URL = this.f["Url-" + idioma.CodIdioma].value;
    });
    this.recintoProfile.CentrosIds =
      this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    let body = {
      pkId: this.pkId,
      Nombre: this.recintoProfile.Nombre,
      //"ControlarAforo": this.recintoProfile.ControlarAforo === true ? '1' : '0',
      ControlarAforo: this.global.aBit(this.recintoProfile.ControlarAforo),
      ControlarAforoReserva: this.global.aBit(
        this.recintoProfile.ControlarAforoReserva
      ),
      TipoAforo: this.recintoProfile.TipoAforo,
      AforoMaximo: this.recintoProfile.AforoMaximo,
      LimiteAforo: this.recintoProfile.LimiteAforo,
      HoraInicio: this.recintoProfile.HoraInicio,
      HoraFin: this.recintoProfile.HoraFin,
      PorDefecto: this.global.aBit(this.recintoProfile.PorDefecto),
      Activo: this.global.aBit(this.recintoProfile.Activo),
      MinimoAforo: this.recintoProfile.MinimoAforo,
      Taller: this.global.aBit(this.recintoProfile.Taller),
      Butacas: this.global.aBit(this.recintoProfile.Butacas),
      MostrarInternet: this.global.aBit(this.recintoProfile.MostrarInternet),
      MaximoInternet: this.recintoProfile.MaximoInternet,
      GrupoActividadId: this.recintoProfile.GrupoActividadId,
      CodigoTipoControl: this.recintoProfile.CodigoTipoControl,
      Secciones: "1",
      GrupoRecintosId: this.recintoProfile.GrupoRecintosId,
      Shape: this.recintoProfile.Shape,
      Coords: this.recintoProfile.Coords,
      OrdenFilas: this.recintoProfile.OrdenFilas,
      OrdenButacas: this.recintoProfile.OrdenButacas,
      Numeracion: "1",
      //"NumSesion": this.editorRegistroForm.value.NumSesion === true ? '1' : '0',
      NumSesion: "0",
      TiempoExtra: this.recintoProfile.TiempoExtra,
      SubGrupoRecinto: this.recintoProfile.SubGrupoRecinto,
      Idiomas: this.editorListaIdiomas,
      Entradas_Recinto_Taller: entradasAsignadasWithPkId,
      EmailEnvio: this.listaEmailsEnvio,
      EmailEnvioBD: this.replaceEnterToSemicolon(
        this.recintoProfile.EmailEnvioBD
      ),
      //"EmailEnvioBD": this.recintoProfile.EmailEnvioBD,
      NumProj: this.recintoProfile.NumProj,
      Funcion: this.mode, // vacio
      ControlExterno: this.recintoProfile.ControlExterno, // vacio
      CentrosIds: this.recintoProfile.CentrosIds,
    };

    this.recintosVisitaService.insUpdRecintosByBody(body).subscribe(
      (res) => {
        /*if (res.DatosResult != null) {
        //this.editando = false;
        this.alertaServicio.success("Datos Guardados Correctamente")
        this.save();
        this.destroy();
      } else {
        this.alertaServicio.error(res.Mensajes[0].DescripcionMensaje);
        //this.editando = false;
      }*/
        this.nextWindow(res);
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  replaceEnterToSemicolon(item: string) {
    if (item !== "" && item !== undefined) {
      return item.replace("\n", ",");
    } else {
      return "";
    }
  }
  changeValueForm(profileRecinto: Recintos) {
    this.f["Nombre"].setValue(profileRecinto.Nombre);
    this.f["NumProj"].setValue(profileRecinto.NumProj);
    this.f["Activo"].setValue(this.global.aBooleano(profileRecinto.Activo));
    this.f["PorDefecto"].setValue(
      this.global.aBooleano(profileRecinto.PorDefecto)
    );
    // this.f["HoraInicio"].setValue(this.global.anadirCeroHoraLeft(moment(profileRecinto.HoraInicio,"h:mm").format("h:mm")))

    if (moment(profileRecinto.HoraInicio).isValid()) {
      this.f["HoraInicio"].setValue(
        moment(profileRecinto.HoraInicio).format("HH:mm")
      );
    } else {
      this.f["HoraInicio"].setValue(profileRecinto.HoraInicio);
    }
    if (moment(profileRecinto.HoraFin).isValid()) {
      this.f["HoraFin"].setValue(
        moment(profileRecinto.HoraFin).format("HH:mm")
      );
    } else {
      this.f["HoraFin"].setValue(profileRecinto.HoraFin);
    }
    this.f["ControlarAforo"].setValue(
      this.global.aBooleano(profileRecinto.ControlarAforo)
    );
    this.f["ControlarAforoReserva"].setValue(
      this.global.aBooleano(profileRecinto.ControlarAforoReserva)
    );
    this.f["TipoAforo"].setValue(profileRecinto.TipoAforo);
    this.f["AforoMaximo"].setValue(profileRecinto.AforoMaximo);
    this.f["LimiteAforo"].setValue(profileRecinto.LimiteAforo);
    this.f["MinimoAforo"].setValue(profileRecinto.MinimoAforo);
    this.f["MaximoInternet"].setValue(profileRecinto.MaximoInternet);
    this.f["TiempoExtra"].setValue(profileRecinto.TiempoExtra);
    this.f["CodigoTipoControl"].setValue(profileRecinto.CodigoTipoControl);
    this.f["MostrarInternet"].setValue(
      this.global.aBooleano(profileRecinto.MostrarInternet)
    );
    //this.f["NumSesion"].setValue(this.global.aBooleano(profileRecinto.NumSesion))
    this.f["Taller"].setValue(this.global.aBooleano(profileRecinto.Taller));
    this.f["EmailEnvioBD"].setValue(
      this.replaceSemicolonToEnter(profileRecinto.EmailEnvioBD)
    );
    this.f["ControlExterno"].setValue(profileRecinto.ControlExterno);
    this.f["GrupoActividadId"].setValue(profileRecinto.GrupoActividadId);
    this.f["GrupoRecintosId"].setValue(profileRecinto.GrupoRecintosId);
    this.f["SubGrupoRecinto"].setValue(profileRecinto.SubGrupoRecinto);
    this.f["Butacas"].setValue(this.global.aBooleano(profileRecinto.Butacas));
    this.butacasCheck = this.f["Butacas"].value;
    this.f["Shape"].setValue(profileRecinto.Shape);
    this.f["Coords"].setValue(profileRecinto.Coords);
    this.f["OrdenFilas"].setValue(profileRecinto.OrdenFilas);
    this.f["OrdenButacas"].setValue(profileRecinto.OrdenButacas);

    if (!profileRecinto.Idiomas) {
      this.editorListaIdiomas.map((idioma) => {
        let idiomaToData: any = {};

        this.f["Nombre-" + idioma.CodIdioma].setValue(
          profileRecinto["Nombre-" + idioma.CodIdioma]
        );
        this.f["Descripcion-" + idioma.CodIdioma].setValue(
          profileRecinto["Descripcion-" + idioma.CodIdioma]
        );
        //this.f["Descripcion1-" + idioma.CodIdioma].setValue(profileRecinto["Descripcion1-" + idioma.CodIdioma])
        //this.f["Descripcion2-" + idioma.CodIdioma].setValue(profileRecinto["Descripcion2-" + idioma.CodIdioma])
        this.f["Url-" + idioma.CodIdioma].setValue(
          profileRecinto["Url-" + idioma.CodIdioma]
        );
      });
    } else {
      profileRecinto.Idiomas.map((idioma) => {
        this.f["Nombre-" + idioma.CodIdioma].setValue(idioma.Nombre);
        this.f["Descripcion-" + idioma.CodIdioma].setValue(idioma.Descripcion);
        //this.f["Descripcion1-" + idioma.CodIdioma].setValue(idioma.Descripcion1)
        //this.f["Descripcion2-" + idioma.CodIdioma].setValue(idioma.Descripcion2)
        this.f["Url-" + idioma.CodIdioma].setValue(idioma.URL);
      });
    }
    let inputTipoAforo = <HTMLInputElement>document.getElementById("TipoAforo");
    if (inputTipoAforo)
      (<HTMLInputElement>document.getElementById("TipoAforo")).value =
        this.global.tiposAforo.find(
          (find) => find.id == profileRecinto.TipoAforo
        ).value;

    if (profileRecinto.TiposEntrada)
      this.insertEntradasAsignadas(profileRecinto.TiposEntrada);
    this._cds.detectChanges();
  }
  insertEntradasAsignadas(asignadas) {
    asignadas.map((asignada) => {
      let entradaFind = this.entradasSinAsignar.find(
        (find) => find.pkId == asignada
      );
      if (entradaFind) {
        this.entradasAsignadas.push(entradaFind);
      }
    });
  }
  toggleAforoMaximo() {
    const controlAforo = this.editorRegistroForm.get("ControlarAforo").value;
    if (controlAforo) {
      this.editorRegistroForm.controls["AforoMaximo"].setValidators([
        Validators.required,
        Validators.pattern("^-?[0-9]*$"),
        Validators.min(0),
      ]);
      this.editorRegistroForm.controls["AforoMaximo"].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls["AforoMaximo"].clearValidators();
      this.editorRegistroForm.controls["AforoMaximo"].setValidators([
        Validators.pattern("^-?[0-9]*$"),
        Validators.min(0),
      ]);
      this.editorRegistroForm.controls["AforoMaximo"].updateValueAndValidity();
    }
  }
  cambiaButacas() {
    this.butacasCheck = !this.butacasCheck;
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Descripcion =
      evento;
    //
  }
  cambiaURL(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).URL = evento;
    //
  }
  cambiaNombre(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Nombre = evento;
    //
  }

  asignarTipoentrada(item, evento) {
    if (this.recintoProfile.Taller) {
      this.tiposTipoEntradaTaller.push(item);
    } else {
      this.tiposTipoEntradaRecinto.push(item);
    }
  }

  desasignarTipoentrada(item, evento) {
    let arrtemp = [];
    if (this.recintoProfile.Taller) {
      arrtemp = this.tiposTipoEntradaTaller.filter(function (x) {
        return x.EntradaId != item.EntradaId;
      });
      //
      this.tiposTipoEntradaTaller = arrtemp;
    } else {
      arrtemp = this.tiposTipoEntradaRecinto.filter(function (x) {
        return x.EntradaId != item.EntradaId;
      });
      //
      this.tiposTipoEntradaRecinto = arrtemp;
    }
  }

  deasignarTodas() {
    if (this.recintoProfile.Taller) {
      this.tiposTipoEntradaTaller = [];
    } else {
      this.tiposTipoEntradaRecinto = [];
    }
  }

  asignarTodas() {
    if (this.recintoProfile.Taller) {
      this.tiposTipoEntradaTaller = this.tiposTipoEntrada;
    } else {
      this.tiposTipoEntradaRecinto = this.tiposTipoEntrada;
    }
  }
  validarAforoInternet() {
    const aforoMaximo = this.editorRegistroForm.get("MaximoInternet").value;
    this.editorRegistroForm.controls["MaximoInternet"].setValidators([
      Validators.max(aforoMaximo),
      Validators.pattern("^-?[0-9]*$"),
      Validators.min(0),
    ]);
    this.editorRegistroForm.controls["MaximoInternet"].updateValueAndValidity();
  }
  toggleAforoInternet() {
    const controlAforo = this.editorRegistroForm.get(
      "ControlarAforoReserva"
    ).value;
    if (controlAforo) {
      this.editorRegistroForm.controls["MaximoInternet"].setValidators([
        Validators.required,
        Validators.pattern("^-?[0-9]*$"),
        Validators.min(0),
      ]);
      this.editorRegistroForm.controls[
        "MaximoInternet"
      ].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls["MaximoInternet"].clearValidators();
      this.editorRegistroForm.controls["MaximoInternet"].setValidators([
        Validators.pattern("^-?[0-9]*$"),
        Validators.min(0),
      ]);
      this.editorRegistroForm.controls[
        "MaximoInternet"
      ].updateValueAndValidity();
    }
  }

  /*   cargarDatos() {
    //Cargamos Datos
    this.recintosVisitaService.getGruposActividadTaller().pipe(first())
      .subscribe(
        data => {
          this.tiposGruposActividadesAPI = data;
          if (this.tiposGruposActividadesAPI.DatosResult != null) {
            this.tiposGruposActividades = this.tiposGruposActividadesAPI.DatosResult.customanGrupoActividadTaller;
            //--2-->
            this.recintosVisitaService.getTiposControl().pipe(first())
              .subscribe(
                data => {
                  this.tiposTiposControlAPI = data;
                  if (this.tiposTiposControlAPI.DatosResult != null) {
                    this.tiposTiposControl = this.tiposTiposControlAPI.DatosResult.customanTiposControl;
                    //--1-->
                    this.recintosVisitaService.getIdiomas(null, null, '1').pipe(first())
                      .subscribe(
                        data => {
                          this.tiposIdiomasAPI = data;
                          if (this.tiposIdiomasAPI.DatosResult != null) {
                            this.tiposIdiomas = this.tiposIdiomasAPI.DatosResult.ListaIdiomas;
                            //
                            //this.tiposIdiomasConMotivo = this.tiposIdiomas.filter(x => x.Activo.trim().toLowerCase() == 'true' || x.Activo.trim().toLowerCase() == '1')
                            //var tiposUsuariotmp = this.tiposIdiomas;
                            this.recintosVisitaService.getGruposRecintos(null, null, '1').pipe(first())
                              .subscribe(
                                data => {
                                  this.tiposGruposRecintoAPI = data;
                                  if (this.tiposGruposRecintoAPI.DatosResult != null) {

                                    this.gruposRecintos = this.tiposGruposRecintoAPI.DatosResult.ListaGrupoRecintos;
                                    //this.tiposGruposActivos = this.tiposGrupos.filter(x=> this.dataSource.data.findIndex(y => y.GrupoId === x.PkId) >=0 )

                                  } else {
                                    this.alertaServicio.error(this.tiposGruposRecintoAPI.Mensajes[0].DescripcionMensaje);
                                    //return false;
                                  }

                                },
                                error => {
                                  this.httpErrorService.identificarErrores(error);
                                  //return false;
                                }
                              );
                          } else {
                            this.alertaServicio.error(this.tiposIdiomasAPI.Mensajes[0].DescripcionMensaje);
                            //return false;
                          }

                        },
                        error => {
                          this.httpErrorService.identificarErrores(error);
                          //return false;
                        }
                      );
                    //<--1--
                  } else {
                    this.alertaServicio.error(this.tiposTiposControlAPI.Mensajes[0].DescripcionMensaje);
                    //return false;
                  }

                },
                error => {
                  this.httpErrorService.identificarErrores(error);
                  //return false;
                }
              );
            //<--2--
          } else {
            //this.alertaServicio.error(this.tiposGruposActividadesAPI.Mensajes[0].DescripcionMensaje);
            //return false;
          }

        },
        error => {
          this.httpErrorService.identificarErrores(error);
          //return false;
        }
      );
  } */
  addIdiomasControlInForm(tiposIdiomas) {
    tiposIdiomas.map((idioma) => {
      this.editorRegistroForm.addControl(
        "Nombre-" + idioma.CodIdioma,
        new FormControl("")
      );
      this.editorRegistroForm.addControl(
        "Descripcion-" + idioma.CodIdioma,
        new FormControl("")
      );
      //this.editorRegistroForm.addControl("Descripcion1-" + idioma.CodIdioma, new FormControl(""))
      //this.editorRegistroForm.addControl("Descripcion2-" + idioma.CodIdioma, new FormControl(""))
      this.editorRegistroForm.addControl(
        "Url-" + idioma.CodIdioma,
        new FormControl("")
      );
    });
  }
  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    var tiposUsuariotmp;
    //centros en cargar datos
    await this.servicioAPI.getIdiomasAsync(null, null, "1").then(
      (data) => {
        this.tiposIdiomasAPI = data;
        if (this.tiposIdiomasAPI.DatosResult != null) {
          this.tiposIdiomas = this.tiposIdiomasAPI.DatosResult.ListaIdiomas;
          this.editorListaIdiomas = this.tiposIdiomas;
          this.addIdiomasControlInForm(this.tiposIdiomas);
          //
          //this.tiposGruposConMotivo = this.tiposGrupos.filter(x => x.ConMotivo.trim().toLowerCase() == 'true' || x.ConMotivo.trim().toLowerCase() == '1')
          //tiposUsuariotmp = this.tiposGrupos;
        } else {
          this.alertaServicio.error(
            this.tiposIdiomasAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.recintosVisitaService
      .getGruposRecintosAsync(null, null, "1")
      .then(
        (data) => {
          this.tiposGruposRecintoAPI = data;

          if (this.tiposGruposRecintoAPI.DatosResult != null) {
            this.tiposGruposRecinto =
              this.tiposGruposRecintoAPI.DatosResult.ListaGrupoRecintos;
            //

            this.gruposRecintos = [];
            this.subGruposRecintos = [];

            this.tiposGruposRecinto.forEach((item) => {
              if (item.subGrupo == "0") {
                this.gruposRecintos.push(item);
              } else {
                this.subGruposRecintos.push(item);
              }
            });
          } else {
            this.alertaServicio.error(
              "GruposRecintos: " +
                this.tiposGruposRecintoAPI.Mensajes[0].DescripcionMensaje
            );
            resultado = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.recintosVisitaService.getTiposControlAsync().then(
      (data) => {
        this.tiposTiposControlAPI = data;
        if (this.tiposTiposControlAPI.DatosResult != null) {
          this.tiposTiposControl =
            this.tiposTiposControlAPI.DatosResult.customanTiposControl;
        } else {
          this.alertaServicio.error(
            this.tiposTiposControlAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.recintosVisitaService.getGruposActividadTallerAsync().then(
      (data) => {
        this.tiposGruposActividadesAPI = data;
        if (this.tiposGruposActividadesAPI.DatosResult != null) {
          this.tiposGruposActividades =
            this.tiposGruposActividadesAPI.DatosResult.customanGrupoActividadTaller;
        } else {
          //this.alertaServicio.error(this.tiposGruposActividadesAPI.Mensajes[0].DescripcionMensaje);
          this.tiposGruposActividades = [];
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado

    await this.CargarDatosTiposEntradas();
    this.manageForm(this.pkId, this.mode);

    return resultado;
  }

  async CargarDatosTiposEntradas(
    tipo?: number,
    recinto?: string
  ): Promise<boolean> {
    //tipo 0-null:Todas, 1:Recinto, 2:Taller/actividad
    let resultado: boolean = false;
    await this.recintosVisitaService
      .getTiposEntradasAsync(
        null,
        null,
        tipo == null || tipo == 0 || recinto == null ? null : recinto,
        tipo == null || tipo == 0 ? null : (tipo - 1).toString()
      )
      .then(
        (data) => {
          this.tiposTiposEntradaAPI = new TiposEntrada_Respuesta().deserialize(
            data
          );
          if (this.tiposTiposEntradaAPI.DatosResult != null) {
            switch (tipo) {
              case 1:
                this.tiposTipoEntradaRecinto =
                  this.tiposTiposEntradaAPI.DatosResult.Lista;
                break;

              case 2:
                this.tiposTipoEntradaTaller =
                  this.tiposTiposEntradaAPI.DatosResult.Lista;
                break;

              default:
                this.tiposTipoEntrada =
                  this.tiposTiposEntradaAPI.DatosResult.Lista;
                this.entradasSinAsignar = this.tiposTipoEntrada;
                break;
            }

            resultado = true;
          } else {
            switch (tipo) {
              case 1:
                this.tiposTipoEntradaRecinto = [];
                break;

              case 2:
                this.tiposTipoEntradaTaller = [];
                break;

              default:
                this.tiposTipoEntrada = [];
                break;
            }
            resultado = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
          resultado = false;
        }
      );

    return resultado;
  }

  guardarPopup() {
    if (this.editorRegistroForm.value.EmailEnvioBD !== "") {
      const dialogRef = this.dialog.open(EmailComponent, {
        // height: '220px',
        data: {
          mensaje: "test",
          name: "test",
          eMails: this.editorRegistroForm.value.EmailEnvioBD,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // 1 = Cancelar
        // 2 = No Enviar
        // 3 = Modificar y Enviar
        // 4 = Enviar

        this.listaEmailsEnvio = [];

        switch (result.action) {
          case 1: // Cancelar
            break;
          case 2: // No Enviar
            this.newGuardar();
            break;
          case 3: // Modificar y Enviar
            this.listaEmailsEnvio = this.stringToEmailList(result.data);
            this.editorRegistroForm.get("EmailEnvioBD").setValue(result.data);
            this.newGuardar();
            break;
          case 4: // Enviar
            this.listaEmailsEnvio = this.stringToEmailList(result.data);
            //this.listaEmailsEnvio = this.stringToEmailList(this.editorRegistroForm.value.EmailEnvioBD);
            // this.enviandoEmail = true;
            this.newGuardar();
            break;
        }
      });
    } else {
      this.newGuardar();
    }
  }

  stringToEmailList(dataDB: string) {
    var emilios = dataDB.split("\n");

    let ans = [];
    emilios.forEach((email) => {
      ans.push({
        email: email,
      });
    });

    return ans;
  }

  replaceSemicolonToEnter(item: string) {
    if (item !== "" && item !== undefined) {
      return item.replace(",", "\n");
    } else {
      return "";
    }
  }
  closeInputFilter(enventValues) {
    //this.insertDataInForm()
    this.editorRegistroForm.get(enventValues.name).setValue(enventValues.value);

    // this.f[enventValues.name].value == enventValues.value ? this.panelService.setDiscardChanges(true):this.panelService.setDiscardChanges(false)
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.f;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editorRegistroForm.get(field);

    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        return this.translation["CampoRequerido"];
      } else if (
        field === "AforoMaximo" ||
        field === "LimiteAforo" ||
        field === "MinimoAforo" ||
        field === "MaximoInternet" ||
        field === "TiempoExtra"
      ) {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloNumeros"];
        } else if (fc_pv.hasError("min")) {
          return this.translation["MinimoCantidad"];
        }
      } else if (field === "Nombre") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloLetras"];
        }
      }
    }
    return "Error";
  }

  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      response.Mensajes.forEach((element) => {
        this.snackvar_service.openSnackBar(
          element.DescripcionMensaje,
          "",
          "red-snackbar",
          element.CodigoMensaje
        );
      });
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["MensajeSuccessSnackBar"],
        "",
        "green-snackbar"
      );
    }
  }
}
