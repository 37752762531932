import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TipoControl } from "@sharedV11/classes/core/api-maestras.model";
import { TableInteractiveComponent } from "@sharedV11/components/tables/table-interactive/table-interactive.component";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EditClientGroupService } from "@sharedV11/services/components/edit_client_group/edit-client-group.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";

@Component({
  selector: "app-edit-type-access-control",
  templateUrl: "./edit-type-access-control.component.html",
  styleUrls: ["./edit-type-access-control.component.scss"],
})
export class EditAccessControlTypeComponent implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  @ViewChild("ti_tiposControlCalendario")
  ti_tiposControlCalendario: TableInteractiveComponent;
  @ViewChild("ti_tiposControlCalendarioHoras")
  ti_tiposControlCalendarioHoras: TableInteractiveComponent;

  translation: any;
  editGroupModelForm: FormGroup;
  tipoAccesoControl: TipoControl;

  respuestaAPI: Object;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  CodigoTipoControl: string;
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  constructor(
    private snackvar_service: SnackbarInfoService,
    private conectarAPI: ConectarApiService,
    private editClientService: EditClientGroupService,
    private panelService: PanelService,
    private fb: FormBuilder,
    private translator: Translator,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros,
    public globales: GlobalService,
    public _cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode);
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeFormWithData(tipoAccesoControls: TipoControl) {
    this.editGroupModelForm = this.fb.group({
      pkId: [tipoAccesoControls.pkId],
      CodigoTipoControl: [tipoAccesoControls.CodigoTipoControl],
      Descripcion: [tipoAccesoControls.Descripcion],
      Nombre: [tipoAccesoControls.Nombre],
      DiasIntervalo: [tipoAccesoControls.DiasIntervalo],
      DiasIntervaloDesde: [tipoAccesoControls.DiasIntervaloDesde],
      NivelEntrada: [tipoAccesoControls.NivelEntrada],
      NumPasosPrevios: [tipoAccesoControls.NumPasosPrevios],
      NumPasosPreviosDiarios: [tipoAccesoControls.NumPasosPreviosDiarios],
      CentrosIds: [tipoAccesoControls.CentrosIds],
      CentrosNombres: [tipoAccesoControls.CentrosNombres],
      PermisoSinFecha: [
        tipoAccesoControls.PermisoSinFecha == "True" ? true : false,
      ],
      AccesoDomingo: [
        tipoAccesoControls.AccesoDomingo == "True" ? true : false,
      ],
      AccesoJueves: [tipoAccesoControls.AccesoJueves == "True" ? true : false],
      AccesoLunes: [tipoAccesoControls.AccesoLunes == "True" ? true : false],
      AccesoMartes: [tipoAccesoControls.AccesoMartes == "True" ? true : false],
      AccesoMiercoles: [
        tipoAccesoControls.AccesoMiercoles == "True" ? true : false,
      ],
      AccesoSabado: [tipoAccesoControls.AccesoSabado == "True" ? true : false],
      AccesoViernes: [
        tipoAccesoControls.AccesoViernes == "True" ? true : false,
      ],
      CompCalendHorario: [
        tipoAccesoControls.CompCalendHorario == "True" ? true : false,
      ],
      CompCalendario: [
        tipoAccesoControls.CompCalendario == "True" ? true : false,
      ],
      CompDiaSemana: [
        tipoAccesoControls.CompDiaSemana == "True" ? true : false,
      ],
      CompDiaria: [tipoAccesoControls.CompDiaria == "True" ? true : false],
      CompHoraria: [tipoAccesoControls.CompHoraria == "True" ? true : false],
      CompIntervalo: [
        tipoAccesoControls.CompIntervalo == "True" ? true : false,
      ],
      CompPasoPrevio: [
        tipoAccesoControls.CompPasoPrevio == "True" ? true : false,
      ],
      DenegarAcceso: [
        tipoAccesoControls.DenegarAcceso == "True" ? true : false,
      ],
      PorDefecto: [tipoAccesoControls.PorDefecto == "True" ? true : false],
    });
  }

  initializeFormWithoutData() {
    this.editGroupModelForm = this.fb.group({
      pkId: [""],
      CodigoTipoControl: [
        "",
        [Validators.required, Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)],
      ],
      Nombre: ["", [Validators.required, Validators.pattern(/[A-Za-z]/)]],
      //Nombre:[""],
      Descripcion: [""],
      DiasIntervalo: [""],
      DiasIntervaloDesde: [""],
      NivelEntrada: ["", Validators.required],
      NumPasosPrevios: [""],
      NumPasosPreviosDiarios: [""],
      CentrosIds: [""],
      CentrosNombres: [""],
      PermisoSinFecha: [""],
      AccesoDomingo: [""],
      AccesoJueves: [""],
      AccesoLunes: [""],
      AccesoMartes: [""],
      AccesoMiercoles: [""],
      AccesoSabado: [""],
      AccesoViernes: [""],
      CompCalendHorario: [""],
      CompCalendario: [""],
      CompDiaSemana: [""],
      CompDiaria: [""],
      CompHoraria: [""],
      CompIntervalo: [""],
      CompPasoPrevio: [""],
      DenegarAcceso: [""],
      PorDefecto: [""],
    });
  }

  changeValueForm(tipoAccesoControls: TipoControl) {
    this.formControls["pkId"].setValue(tipoAccesoControls.pkId);
    this.formControls["CodigoTipoControl"].setValue(
      tipoAccesoControls.CodigoTipoControl
    );
    this.formControls["Nombre"].setValue(tipoAccesoControls.Nombre);
    //this.formControls["Nombre"].setValue(tipoAccesoControls.Nombre);
    this.formControls["Descripcion"].setValue(tipoAccesoControls.Descripcion);
    this.formControls["DiasIntervalo"].setValue(
      tipoAccesoControls.DiasIntervalo
    );
    this.formControls["DiasIntervaloDesde"].setValue(
      tipoAccesoControls.DiasIntervaloDesde
    );
    this.formControls["NivelEntrada"].setValue(tipoAccesoControls.NivelEntrada);
    this.formControls["NumPasosPrevios"].setValue(
      tipoAccesoControls.NumPasosPrevios
    );
    this.formControls["NumPasosPreviosDiarios"].setValue(
      tipoAccesoControls.NumPasosPreviosDiarios
    );
    this.formControls["CentrosIds"].setValue(tipoAccesoControls.CentrosIds);
    this.formControls["CentrosNombres"].setValue(
      tipoAccesoControls.CentrosNombres
    );
    this.formControls["PermisoSinFecha"].setValue(
      tipoAccesoControls.PermisoSinFecha == "True" ? true : false
    );
    this.formControls["AccesoDomingo"].setValue(
      tipoAccesoControls.AccesoDomingo == "True" ? true : false
    );
    this.formControls["AccesoJueves"].setValue(
      tipoAccesoControls.AccesoJueves == "True" ? true : false
    );
    this.formControls["AccesoLunes"].setValue(
      tipoAccesoControls.AccesoLunes == "True" ? true : false
    );
    this.formControls["AccesoMartes"].setValue(
      tipoAccesoControls.AccesoMartes == "True" ? true : false
    );
    this.formControls["AccesoMiercoles"].setValue(
      tipoAccesoControls.AccesoMiercoles == "True" ? true : false
    );
    this.formControls["AccesoSabado"].setValue(
      tipoAccesoControls.AccesoSabado == "True" ? true : false
    );
    this.formControls["AccesoViernes"].setValue(
      tipoAccesoControls.AccesoViernes == "True" ? true : false
    );
    this.formControls["CompCalendHorario"].setValue(
      tipoAccesoControls.CompCalendHorario == "True" ? true : false
    );
    this.formControls["CompCalendario"].setValue(
      tipoAccesoControls.CompCalendario == "True" ? true : false
    );
    this.formControls["CompDiaSemana"].setValue(
      tipoAccesoControls.CompDiaSemana == "True" ? true : false
    );
    this.formControls["CompDiaria"].setValue(
      tipoAccesoControls.CompDiaria == "True" ? true : false
    );
    this.formControls["CompHoraria"].setValue(
      tipoAccesoControls.CompHoraria == "True" ? true : false
    );
    this.formControls["CompIntervalo"].setValue(
      tipoAccesoControls.CompIntervalo == "True" ? true : false
    );
    this.formControls["CompPasoPrevio"].setValue(
      tipoAccesoControls.CompPasoPrevio == "True" ? true : false
    );
    this.formControls["DenegarAcceso"].setValue(
      tipoAccesoControls.DenegarAcceso == "True" ? true : false
    );
    this.formControls["PorDefecto"].setValue(
      tipoAccesoControls.PorDefecto == "True" ? true : false
    );

    if (this.mode == "D") {
      this.formControls["CodigoTipoControl"].setValue("");
      this.formControls["Nombre"].setValue("");
    }

    if (this.formControls["CompIntervalo"].value) {
      this.formControls["DiasIntervaloDesde"].setValidators([
        Validators.min(1),
      ]);
      this.formControls["DiasIntervalo"].setValidators([Validators.min(1)]);
    }

    if (this.formControls["CompPasoPrevio"].value) {
      this.formControls["NumPasosPrevios"].setValidators([Validators.min(1)]);
      this.formControls["NumPasosPreviosDiarios"].setValidators([
        Validators.min(1),
      ]);
    }
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.tipoAccesoControl = this.editGroupModelForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }

    if (pkId != null) {
      //this.finduser(pkId)
      this.findTipoControl();
    }
  }

  get formControls() {
    return this.editGroupModelForm.controls;
  }

  submit(cerrarpanel: boolean = true) {
    let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;

    this.editGroupModelForm.markAllAsTouched();
    if (
      this.editGroupModelForm.valid &&
      (this.formControls.CompDiaSemana.value == false ||
        (this.formControls.CompDiaSemana.value == true &&
          this.haydiasseleccionados())) &&
      (this.formControls.CompIntervalo.value == false ||
        (this.formControls.CompIntervalo.value == true &&
          this.hayIntervalos())) &&
      (this.formControls.CompPasoPrevio.value == false ||
        (this.formControls.CompPasoPrevio.value == true &&
          this.hayPasosPrevios())) &&
      (this.formControls.CompCalendario.value == false ||
        (this.formControls.CompCalendario.value == true &&
          this.hayCalendariodias())) &&
      (this.formControls.CompCalendHorario.value == false ||
        (this.formControls.CompCalendHorario.value == true &&
          this.hayCalendariohoras()))
    ) {
      this.manageApi(tipoAccesoControls, cerrarpanel);

      /* if (this.mode!="D") { */

      //}
    } else if (
      this.formControls.CompDiaSemana.value == true &&
      !this.haydiasseleccionados()
    ) {
      this.snackvar_service.openSnackBar(
        this.translation["debeselccionardisponibilidad"],
        "",
        "red-snackbar"
      );
    } else if (
      this.formControls.CompIntervalo.value == true &&
      !this.hayIntervalos()
    ) {
      this.snackvar_service.openSnackBar(
        this.translation["reviseintervalos"],
        "",
        "red-snackbar"
      );
    } else if (
      this.formControls.CompPasoPrevio.value == true &&
      !this.hayPasosPrevios()
    ) {
      this.snackvar_service.openSnackBar(
        this.translation["reviseprevios"],
        "",
        "red-snackbar"
      );
    } else if (
      this.formControls.CompCalendario.value == true &&
      !this.hayCalendariodias()
    ) {
      this.snackvar_service.openSnackBar(
        this.translation["introducirdiascalendario"],
        "",
        "red-snackbar"
      );
    } else if (
      this.formControls.CompCalendHorario.value == true &&
      !this.hayCalendariohoras()
    ) {
      this.snackvar_service.openSnackBar(
        this.translation["introducirhorascalendario"],
        "",
        "red-snackbar"
      );
    }
  }

  borrarCalendarios(calaendarios) {
    /*   if (calaendarios.length>0  ) { */

    calaendarios.forEach(async (ti_ele) => {
      let peticion = {};
      peticion["pkId"] = ti_ele.pkId ? ti_ele.pkId : 0;
      peticion["CodigoTipoControl"] = ti_ele.CodigoTipoControl;

      await this.conectarAPI
        .delCalendariosTiposControlAsync(
          peticion["pkId"],
          peticion["CodigoTipoControl"]
        )
        .then((delcal) => {
          if (delcal.Mensajes != null)
            this.snackvar_service.openSnackBar(
              delcal.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar"
            );
        });
    });
    /*     } */
  }

  submitTables(tipoAccesoControls, id: any = null) {
    if (this.formControls["CompCalendario"].value) {
      //this.submitCal(false);
      this.submitCalTable(tipoAccesoControls, id);
    } else {
      if (
        this.ti_tiposControlCalendario &&
        this.ti_tiposControlCalendario.dataSource
      ) {
        this.borrarCalendarios(this.ti_tiposControlCalendario.dataSource.data);
      }
    }

    if (this.formControls["CompCalendHorario"].value) {
      //this.submitCalHoras(false);
      this.submitCalHorasTable(tipoAccesoControls, id);
    } else {
      if (
        this.ti_tiposControlCalendarioHoras &&
        this.ti_tiposControlCalendarioHoras.dataSource
      ) {
        this.borrarCalendarios(
          this.ti_tiposControlCalendarioHoras.dataSource.data
        );
      }
    }
  }

  submitCal(cerrarpanel: boolean = true) {
    //let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
    this.submit(false);
  }

  submitCalTable(tipoAccesoControls, id: any = null) {
    if (
      this.ti_tiposControlCalendario.dataSource.data.length > 0 ||
      this.ti_tiposControlCalendario.dataSource.data.length > 0
    ) {
      this.ti_tiposControlCalendario.dataSource.data.forEach((ti_ele) => {
        let date_FechaAccesoStr = ti_ele.FechaAcceso.value.format("YYYY-MM-DD");
        let date_FechaAccesoFinStr =
          ti_ele.FechaAccesoFin.value.format("YYYY-MM-DD");

        let peticion = {};
        peticion["pkId"] = ti_ele.pkId ? ti_ele.pkId : 0;
        peticion["TipoControlId"] = this.tipoAccesoControl.pkId
          ? this.tipoAccesoControl.pkId
          : id;
        peticion["CodigoTipoControl"] = tipoAccesoControls.CodigoTipoControl;
        peticion["NombreTipoControl"] = tipoAccesoControls.Nombre;
        peticion["FechaAcceso"] = date_FechaAccesoStr + " 00:00:00";
        peticion["FechaAccesoFin"] = date_FechaAccesoFinStr + " 00:00:00";

        if (ti_ele.updated) {
          this.conectarAPI
            .insUpdCalendariosTiposControlAsync(
              peticion["pkId"],
              peticion["TipoControlId"],
              peticion["CodigoTipoControl"],
              peticion["NombreTipoControl"],
              peticion["FechaAcceso"],
              peticion["FechaAccesoFin"]
            )
            .then((insup) => {
              if (insup.Mensajes != null && insup.Mensajes.length > 0)
                this.snackvar_service.openSnackBar(
                  insup.Mensajes[0].DescripcionMensaje,
                  "",
                  "red-snackbar"
                );
            });
        }
      });
      this.ti_tiposControlCalendario.reloadTable();
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["nodatosaguardar"],
        "",
        "red-snackbar"
      );
    }
  }

  submitCalHoras(cerrarpanel: boolean = true) {
    //let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
    this.submit(false);
  }

  submitCalHorasTable(tipoAccesoControls, id: any = null) {
    if (
      this.ti_tiposControlCalendarioHoras.dataSource.data.length > 0 ||
      this.ti_tiposControlCalendarioHoras.dataSource.data.length > 0
    ) {
      this.ti_tiposControlCalendarioHoras.dataSource.data.forEach((ti_ele) => {
        let tmp1 = ti_ele.FechaAcceso.split(":");
        if (tmp1.length == 2) ti_ele.FechaAcceso += ":00";

        let tmp2 = ti_ele.FechaAccesoFin.split(":");
        if (tmp2.length == 2) ti_ele.FechaAccesoFin += ":00";

        let peticion = {};
        peticion["pkId"] = ti_ele.pkId ? ti_ele.pkId : 0;
        peticion["TipoControlId"] = this.tipoAccesoControl.pkId
          ? this.tipoAccesoControl.pkId
          : id;
        peticion["CodigoTipoControl"] = tipoAccesoControls.CodigoTipoControl;
        peticion["NombreTipoControl"] = tipoAccesoControls.Nombre;
        peticion["FechaAcceso"] = "1900-01-01 " + ti_ele.FechaAcceso;
        peticion["FechaAccesoFin"] = "1900-01-01 " + ti_ele.FechaAccesoFin;

        if (ti_ele.updated) {
          this.conectarAPI
            .insUpdCalendariosTiposControlAsync(
              peticion["pkId"],
              peticion["TipoControlId"],
              peticion["CodigoTipoControl"],
              peticion["NombreTipoControl"],
              peticion["FechaAcceso"],
              peticion["FechaAccesoFin"]
            )
            .then((insup) => {
              if (insup.Mensajes != null && insup.Mensajes.length > 0)
                this.snackvar_service.openSnackBar(
                  insup.Mensajes[0].DescripcionMensaje,
                  "",
                  "red-snackbar"
                );
            });
        }
      });
      this.ti_tiposControlCalendarioHoras.reloadTable();
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["nodatosaguardar"],
        "",
        "red-snackbar"
      );
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.loading = false;
    this.panelService.setClosePanel(null);
  }
  save(cerrarpanel?) {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(cerrarpanel);
  }

  discard() {
    this.changeValueForm(this.tipoAccesoControl);
  }

  manageApi(grupo_cl: TipoControl, cerrarpanel?) {
    grupo_cl.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );
    //grupo_cl.Nombre = grupo_cl.NombreTipoControl;
    this.loading = true;
    switch (this.mode) {
      case "I":
        this.InsTiposControl(grupo_cl, cerrarpanel);
        //insert
        //this.insUpdUsuario(user)
        break;
      case "U":
        //update
        //this.insUpdUsuario(user)
        this.UpdTiposControl(grupo_cl, cerrarpanel);
        break;
      case "D":
        //duplicate
        this.editGroupModelForm.value.pkId = "0";
        this.InsTiposControl(grupo_cl, cerrarpanel);
        break;

      default:
        break;
    }
  }

  async UpdTiposControl(clientegrupo, cerrarpanel?) {
    await this.editClientService.updTipoControlAsync(clientegrupo, "U").then(
      (response) => {
        if (response.DatosResult != null) {
          this.submitTables(clientegrupo);
          this.nextWindow(response, cerrarpanel);
        } else {
          this.snackvar_service.openSnackBar(
            response.Mensajes[0].DescripcionMensaje,
            "",
            "red-snackbar"
          );
          this.nextWindow(response, cerrarpanel);
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  async InsTiposControl(clientegrupo, cerrarpanel?) {
    //let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
    await this.editClientService.insTipoControlAsync(clientegrupo, "I").then(
      (response) => {
        if (response.DatosResult != null) {
          this.submitTables(clientegrupo, response.DatosResult.id);

          if (!cerrarpanel) {
            //this.findTipoCodigoPromocional(response.DatosResult.id);
            this.mode = "U";
            if ((this.pkId = response.DatosResult)) {
              this.pkId = response.DatosResult.id;
              //this.cdRef.detectChanges();
              this.ti_tiposControlCalendario.reloadTable();
              this.ti_tiposControlCalendarioHoras.reloadTable();
              //this.entryType.CentrosIds = this.obtenercentrosAsociadosconOrden()
              this.initializeTranslate();
              this.initializeFormWithoutData();
              this.manageForm(this.pkId, this.mode);
              this.translation = this.translator.GetTranslations();
              this.findTipoControl();
              this.gsCentros.getCentros();
            }
          } else {
            this.nextWindow(response, cerrarpanel);
          }
        } else {
          this.snackvar_service.openSnackBar(
            response.Mensajes[0].DescripcionMensaje,
            "",
            "red-snackbar"
          );
          this.nextWindow(response, cerrarpanel);
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  nextWindow(response: any, cerrarpanel?) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save(cerrarpanel);
      if (cerrarpanel && cerrarpanel == true) this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach((element) => {
          this.snackvar_service.openSnackBar(
            element.DescripcionMensaje,
            "",
            "red-snackbar",
            element.CodigoMensaje
          );
        });
      } else {
        this.snackvar_service.openSnackBar(
          this.translation["MensajeSuccessSnackBar"],
          "",
          "green-snackbar"
        );
      }
    }
  }

  notEmptyFields(grupo_cl: TipoControl): boolean {
    let res = true;
    if (
      grupo_cl.Nombre.length === 0 ||
      grupo_cl.CodigoTipoControl.length === 0
    ) {
      res = false;
    }
    return res;
  }

  valuedChanged() {
    this.editGroupModelForm.valueChanges.subscribe((value) => {
      if (this.tipoAccesoControl != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.tipoAccesoControl)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  findTipoControl() {
    this.editClientService.findTiposControlByPkId(this.pkId).subscribe(
      (response) => {
        if (response["DatosResult"]) {
          let tipoAccesosControl: TipoControl =
            response["DatosResult"]["customanTiposControl"][0];
          if (tipoAccesosControl != null) {
            this.CodigoTipoControl = tipoAccesosControl.CodigoTipoControl;
            this.changeValueForm(tipoAccesosControl);
            this.tipoAccesoControl = this.editGroupModelForm.value;
            this.tipoAccesoControl.CentrosIds = tipoAccesosControl.CentrosIds;
            this.valuedChanged();
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados = this.gsCentros.daTiposCentros(
              this.tipoAccesoControl
            );
            this.loading = false;
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  actualizarCodigoenTabla(ev) {
    if (this.mode == "D") {
      let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
      this.ti_tiposControlCalendario.dataSource.data.forEach((tiele, i) => {
        this.ti_tiposControlCalendario.dataSource.data[i].TipoControlId =
          tipoAccesoControls.CodigoTipoControl.toString();
        this.ti_tiposControlCalendario.dataSource.data[i].updated = true;
      });
      this.ti_tiposControlCalendarioHoras.dataSource.data.forEach(
        (tiele, i) => {
          this.ti_tiposControlCalendarioHoras.dataSource.data[i].TipoControlId =
            tipoAccesoControls.CodigoTipoControl.toString();
          this.ti_tiposControlCalendarioHoras.dataSource.data[i].updated = true;
        }
      );
    }
  }

  tableloaded(tabla) {
    if (this.mode == "D") {
      let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
      tabla.dataSource.data.forEach((tiele, i) => {
        tabla.dataSource.data[i].pkId = "0";
        tabla.dataSource.data[i].TipoControlId =
          tipoAccesoControls.CodigoTipoControl;
        tabla.dataSource.data[i].updated = true;
      });
    }
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    let valido = fc_pv[field].errors != null ? true : false;
    if (
      field == "DiasIntervaloDesde" &&
      this.formControls.CompIntervalo.value == true
    )
      valido = fc_pv[field].value >= 0 ? false : true;

    return valido;
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editGroupModelForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        return this.translation["CampoRequerido"];
      } else if (field === "CodigoTipoControl") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloNumeros"];
        }
      } else if (field === "Nombre") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloLetras"];
        }
      }
    }

    if (
      field == "DiasIntervalo" &&
      this.formControls.CompIntervalo.value == true
    )
      return "Dias intervalo hasta debe ser mayor que cero...";

    if (
      field == "DiasIntervaloDesde" &&
      this.formControls.CompIntervalo.value == true
    )
      return "Dias intervalo desde debe ser mayor que cero...";

    if (
      field == "NumPasosPrevios" &&
      this.formControls.CompPasoPrevio.value == true
    )
      return "Número pasos previos debe ser mayor que cero...";

    if (
      field == "NumPasosPreviosDiarios" &&
      this.formControls.CompPasoPrevio.value == true
    )
      return "Número pasos previos diarios debe ser mayor que cero...";

    return "Error";
  }

  existErrors() {
    return this.formControls.CodigoTipoControl.errors ||
      this.formControls.Nombre.errors
      ? true
      : false;
  }

  diasemana(e) {
    if (e.checked == false) {
      this.formControls.AccesoLunes.setValue(false);
      this.formControls.AccesoMartes.setValue(false);
      this.formControls.AccesoMiercoles.setValue(false);
      this.formControls.AccesoJueves.setValue(false);
      this.formControls.AccesoViernes.setValue(false);
      this.formControls.AccesoSabado.setValue(false);
      this.formControls.AccesoDomingo.setValue(false);
    }
  }

  haydiasseleccionados() {
    let hay = false;
    if (
      this.formControls.AccesoLunes.value == true ||
      this.formControls.AccesoMartes.value ||
      this.formControls.AccesoMiercoles.value ||
      this.formControls.AccesoJueves.value ||
      this.formControls.AccesoViernes.value ||
      this.formControls.AccesoSabado.value ||
      this.formControls.AccesoDomingo.value
    )
      hay = true;
    return hay;
  }

  reseteaIntervalos(e) {
    if (e.checked == false) {
      this.formControls.DiasIntervaloDesde.setValue("");
      this.formControls.DiasIntervalo.setValue("");
      this.formControls["DiasIntervalo"].clearValidators();
      this.formControls["DiasIntervaloDesde"].clearValidators();
      this.formControls["DiasIntervalo"].updateValueAndValidity();
      this.formControls["DiasIntervaloDesde"].updateValueAndValidity();
    } else {
      this.formControls["DiasIntervalo"].setValidators([Validators.min(1)]);
      this.formControls["DiasIntervaloDesde"].setValidators([
        Validators.min(1),
      ]);
    }
  }

  hayIntervalos() {
    let hay = true;
    if (
      this.formControls.DiasIntervaloDesde.value == "" ||
      this.formControls.DiasIntervalo.value == ""
    )
      hay = false;
    return hay;
  }

  reseteaPasosPrevios(e) {
    if (e.checked == false) {
      this.formControls.NumPasosPrevios.setValue("");
      this.formControls.NumPasosPreviosDiarios.setValue("");
      this.formControls["NumPasosPrevios"].clearValidators();
      this.formControls["NumPasosPreviosDiarios"].clearValidators();
      this.formControls["NumPasosPrevios"].updateValueAndValidity();
      this.formControls["NumPasosPreviosDiarios"].updateValueAndValidity();
    } else {
      this.formControls["NumPasosPrevios"].setValidators([Validators.min(1)]);
      this.formControls["NumPasosPreviosDiarios"].setValidators([
        Validators.min(1),
      ]);
    }
  }

  hayPasosPrevios() {
    let hay = true;
    if (
      this.formControls.NumPasosPrevios.value == "" ||
      this.formControls.NumPasosPreviosDiarios.value == ""
    )
      hay = false;
    return hay;
  }

  hayCalendariodias() {
    let hay = true;
    if (this.ti_tiposControlCalendario.dataSource.data.length < 1) hay = false;
    return hay;
  }

  hayCalendariohoras() {
    let hay = true;
    if (this.ti_tiposControlCalendarioHoras.dataSource.data.length < 1)
      hay = false;
    return hay;
  }

  reseteaCalendario(e) {
    if (e.checked == false) {
      this.ti_tiposControlCalendario.dataSource.data = [];
    }
  }

  reseteaCalendarioHorario(e) {
    if (e.checked == false) {
      this.ti_tiposControlCalendarioHoras.dataSource.data = [];
    }
  }

  CalendarioHorasChange(e) {}
}
