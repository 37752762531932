import { Injectable } from "@angular/core";
import esES from "../../languages/es-ES.json";
import caES from "../../languages/ca-ES.json";
import ptPT from "../../languages/pt-PT.json";
import frFR from "../../languages/fr-FR.json";
import enGB from "../../languages/en-GB.json";

@Injectable({
  providedIn: "root",
})
export class Translator {
  GetTranslations() {
    const language = localStorage.getItem("Language");

    switch (language) {
      case "es-ES":
        return esES;
      case "ca":
        return caES;
      case "fr-FR":
        return frFR;
      case "pt-PT":
        return ptPT;
      case "en-GB":
      case "en":
        return enGB;
      default:
        localStorage.setItem("Language", "es-ES");
        return esES;
    }
  }
}
