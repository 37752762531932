import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  GrupoClientes,
  grupos_cliente_Respuesta,
} from "@sharedV11/classes/clients/client/grupos-cliente-Respuesta.model";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { EditClientGroupService } from "@sharedV11/services/components/edit_client_group/edit-client-group.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-client-group",
  templateUrl: "./edit-client-group.component.html",
  styleUrls: ["./edit-client-group.component.scss"],
})
export class EditClientGroupComponent implements OnInit {
  public loading: boolean = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  editGroupModelForm: FormGroup;
  grupoCliente: GrupoClientes;

  respuestaAPI: grupos_cliente_Respuesta;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  constructor(
    private snackvar_service: SnackbarInfoService,
    private editClientService: EditClientGroupService,
    private panelService: PanelService,
    private fb: FormBuilder,
    private translator: Translator,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();

    this.manageForm(this.pkId, this.mode);
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeFormWithData(grupoClientes: GrupoClientes) {
    this.editGroupModelForm = this.fb.group({
      pkId: [grupoClientes.pkId],
      CodigoGruposClientes: [grupoClientes.CodigoGruposClientes],
      NombreGruposClientes: [grupoClientes.NombreGruposClientes],
    });
  }

  initializeFormWithoutData() {
    this.editGroupModelForm = this.fb.group({
      pkId: [""],
      CodigoGruposClientes: [
        "",
        [Validators.required, Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)],
      ],
      NombreGruposClientes: [
        "",
        [Validators.required, Validators.pattern(/[A-Za-z]/)],
      ],
    });
  }

  changeValueForm(grupoClientes: GrupoClientes) {
    this.formControls["pkId"].setValue(grupoClientes.pkId);
    this.formControls["CodigoGruposClientes"].setValue(
      grupoClientes.CodigoGruposClientes
    );
    this.formControls["NombreGruposClientes"].setValue(
      grupoClientes.NombreGruposClientes
    );
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.grupoCliente = this.editGroupModelForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      //this.finduser(pkId)
      this.findClientGroup();
    }
  }

  get formControls() {
    return this.editGroupModelForm.controls;
  }

  submit() {
    let grupoClientes: GrupoClientes = this.editGroupModelForm.value;
    //grupoClientes.PkId = this.grupoCliente.PkId;
    this.editGroupModelForm.markAllAsTouched();
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editGroupModelForm.valid) {
      this.manageApi(grupoClientes);
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }
  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.grupoCliente);
  }

  manageApi(grupo_cl: GrupoClientes) {
    grupo_cl.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );
    this.loading = true;
    switch (this.mode) {
      case "I":
        this.InsClienteGroup(grupo_cl);
        //insert
        break;
      case "U":
        //update
        this.UpdClienteGroup(grupo_cl);
        break;
      case "D":
        //duplicate
        this.editGroupModelForm.value.pkId = "0";
        this.InsClienteGroup(grupo_cl);
        break;

      default:
        break;
    }
  }

  UpdClienteGroup(clientegrupo) {
    this.editClientService
      .updGruposCliente(clientegrupo, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  InsClienteGroup(clientegrupo) {
    this.editClientService
      .insGruposCliente(clientegrupo, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      response.Mensajes.forEach((element) => {
        this.snackvar_service.openSnackBar(
          element.DescripcionMensaje,
          "",
          "red-snackbar",
          element.CodigoMensaje
        );
      });
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["MensajeSuccessSnackBar"],
        "",
        "green-snackbar"
      );
    }
  }

  notEmptyFields(grupo_cl: GrupoClientes): boolean {
    let res = true;
    if (
      grupo_cl.NombreGruposClientes.length === 0 ||
      grupo_cl.CodigoGruposClientes.length === 0
    ) {
      res = false;
    }
    return res;
  }

  valuedChanged() {
    this.editGroupModelForm.valueChanges.subscribe((value) => {
      if (this.grupoCliente != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.grupoCliente)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  findClientGroup() {
    this.editClientService.findGruposClienteByPkId(this.pkId).subscribe(
      (response) => {
        let grupoClientes: GrupoClientes =
          response["DatosResult"]["customanGrupoCliente"][0];
        if (grupoClientes != null) {
          this.changeValueForm(grupoClientes);
          this.grupoCliente = this.editGroupModelForm.value;
          this.grupoCliente.CentrosIds = grupoClientes.CentrosIds;
          this.valuedChanged();
          this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
          this.centrosAsignados = this.gsCentros.daTiposCentros(
            this.grupoCliente
          );
          this.loading = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return fc_pv[field].errors != null ? true : false;
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editGroupModelForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        return this.translation["CampoRequerido"];
      } else if (field === "CodigoGruposClientes") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloNumeros"];
        }
      } else if (field === "NombreGruposClientes") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloLetras"];
        }
      }
    }
    return "Error";
  }

  existErrors() {
    return this.formControls.CodigoGruposClientes.errors ||
      this.formControls.NombreGruposClientes.errors
      ? true
      : false;
  }
}
