import { ChangeDetectorRef, EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import data from 'src/app/shared/jsons/tables/tables.json';
import { Filter } from '../../classes/filters/filter';
import { table } from '../../classes/tables/table';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from '../alerts/alert/alert.service';
import { LowerCasePipe } from '@angular/common';
import moment, { localeData } from 'moment';
import { Usuario_response } from '../../classes/userResponse/usuarioResponse/usuario_response';
import { GlobalService } from '../global/global.service';
import { Request_tarifa_list } from '../../classes/tarifas/request_tarifa_list';
import { Tarifas } from '../../classes/tarifaResponse/tarifas';
import { Tarifas_response } from '../../classes/tarifaResponse/tarifas_response';
import { Tarifas_show } from '../../classes/tarifaResponse/tarifas_show';
import { Select_option } from '../../classes/tables/select_option';
import { UserType_response } from '@sharedV11/classes/userType/UserType_response';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
// import { MatPaginatorIntlEses } from '@otrosProveedoresV6/ES-es.paginator';
import { InternetGroups_response } from '@sharedV11/classes/tarifaResponse/internetgroups_response';
import { InternetGroup } from '@sharedV11/classes/tarifaResponse/internetgroup';
import { InternetGroups_show } from '@sharedV11/classes/tarifaResponse/internetgroups_show';
import { Client_show } from '@sharedV11/classes/userResponse/clientResponse/client_show';
import { Paginator } from '@sharedV11/classes/paginator/paginator';
import { Translator } from '../translator/translator.service';
import { first, takeUntil, map } from 'rxjs/operators';
import { formas_pago_Respuesta } from '@sharedV11/classes/userRequest/clientes/formas-pago-Respuesta.model';
import { TiposSesion_show } from '@sharedV11/classes/tables/tipossesion_show';
import { SinButaca_show } from '@sharedV11/classes/tables/sinbutaca_show';
import { Breakpoints } from '@angular/cdk/layout';
import { grupos_tipos_entrada_Respuesta } from '@sharedV11/classes/EntradasPromociones/GrupoEntradas/grupo_tipos_entrada_Respuesta';
import { EntryType } from '@sharedV11/classes/entradas-y-promociones/tipo-entrada/entry-type';
import { EditUserService } from '../components/edit_user/edit-user.service';
import { UserType } from '@sharedV11/classes/userType/user_type';
import { Customan_usuario } from '@sharedV11/classes/userResponse/usuarioResponse/customan_usuario';
import { async } from '@angular/core/testing';
import { DatosResult } from '../../classes/clients/client/usuario-Respuesta.model';
import { EntryTypeService } from '../components/entradas-y-promociones/entry-type/entry-type.service';
import { ItemsMotivosGroup } from '../../classes/EntradasPromociones/ItemsMotivosGroup/items_motivos_group';
import { ItemsMotivosGruposV11Service } from '../components/entradas-y-promociones/items-motivos-grupos-v11/items-motivos-grupos-v11.service';
import { GrupoTiposEntrada } from '@sharedV11/classes/EntradasPromociones/GrupoEntradas/GrupoTiposEntrada';
import { RecintosVisitaService } from '../components/recintos/recintos-visita/recintos-visita.service';
import { Recintos } from '@sharedV11/classes/recintos/recintos-visita/recintos';
import { HttpErrorsService } from '../alerts/errors/http-errors.service';
import { SnackbarInfoService } from '../alerts/snackbar-info/snackbar-info.service';
import { CategoriasV11 } from '@sharedV11/classes/maestros/categorias/categoria';
import { FilterService } from './filter.service';
import { EditTipoCodigoPromocionesService } from '../components/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.service';
import { EditCodigoPromocionesService } from '../components/edit-codigo-promociones/edit-codigo-promociones.service';
import { FormControl } from '@angular/forms';
import { EspectaculosService } from '../espectaculos/espectaculos.service';




@Injectable({
  providedIn: 'root'
})



export class TableInteractiveService extends FilterService {



  constructor(
     servicioAPI: ConectarApiService,
     espectaculosService:EspectaculosService,
     entryTypeService: EntryTypeService,
     alertaServicio: AlertService,
     global: GlobalService,
     translator: Translator,
     editUserService: EditUserService,
     itemsMotivosGruposService: ItemsMotivosGruposV11Service,
     recintosVisitaService: RecintosVisitaService,
     httpErrorService: HttpErrorsService,
     snackvar_service: SnackbarInfoService,
     editTipoCodigoPromocionesService:EditTipoCodigoPromocionesService,
     editCodigoPromocionesService:EditCodigoPromocionesService
    

  ) {
    super(servicioAPI,
      espectaculosService,
      entryTypeService,
      alertaServicio,
      global,
      translator,
      editUserService,
      itemsMotivosGruposService,
      recintosVisitaService,
      httpErrorService,
      snackvar_service,
      editTipoCodigoPromocionesService,
      editCodigoPromocionesService
      );
    
    //this.translation = this.translator.GetTranslations();
  }



/*   findTable(table) {
    this.filters = [];
    this.arrTable.push(data.tables.find(data => data.tableName == table));
    this.setTable(this.arrTable);
  }
 */

  manageFilterGetDataDB(tableName) {
    this.tableLoaded.emit(false);
    switch (tableName) {
      case 'tbTiposCodigosPromocionales_tiposEntrada':
        this.cargarDatosTipoEntrada_tiposEntrada();
        break;
      case 'tbTiposCodigosPromocionales_tiposPromociones':
        this.cargarDatosTipoEntrada_tiposPromociones();
        break;
        //
      case 'tbTiposCodigosPromocionales_tiposEntrada_calco':
        this.cargarDatosTipoEntrada_tiposEntrada();
        this.cargarDatosIva();
        break;
      case 'tbTiposCodigosPromocionales_tiposPromociones_calco':
        this.cargarDatosTipoEntrada_tiposPromociones();
        this.cargarDatosTipoEntrada_tiposEntrada();
        this.cargarDatosIva();
        break;  
       // 
      case 'tbCodigoPromocionalTiposBonoEntradas_calco':
        this.cargarDatosTipoEntrada_tiposPromociones();
        this.cargarDatosTipoEntrada_tiposEntrada();
        this.cargarDatosIva();
        break;  
      case 'tbCodigoPromocionalEntradas':
        this.cargarDatosTipoEntrada_tiposEntrada();
        this.cargarDatosIva();
        break;
       // 
      case 'tbCodigoPromocionalTipoBono':
        this.cargarDatosTipoEntrada_tiposEntrada();
        this.cargarDatosTipoEntrada_tiposPromociones();
        break; 
      case 'tbCodigoPromocionalTiposBonoEntradas':
        this.cargarDatosTipoEntrada_tiposEntrada();
        this.cargarDatosIva();
        break; 
      case 'tbTiposPromocionEntradas':
        this.cargarDatosTipoEntrada_tiposEntrada();
        break;
      case 'Calendariodias_tiposcontrol':
      //this.cargarDatosTipoEntrada_tiposEntrada();
      break;
      case 'Calendariohoras_tiposcontrol':
      //this.cargarDatosTipoEntrada_tiposPromociones();
      break;      
      default:
      break;
    }
  }
  
  manageGetDataDB(tableName, paginator: Paginator, parent_pkId?,subparent_pkId?) {
    /*
    this.manageFilterGetDataDB(tableName)
    this.tableLoaded.emit(false);
    this.alertaServicio.limpiar();
    */

    this.manageFilterGetDataDB(tableName);
    this.tableLoaded.emit(false);
    this.paginator = paginator;
    //this.paginator.page = 1;

 
    const filters = [...this.filters];
    filters.map(filter => {
      filter.name = filter.filterApi;
    });
    const filterIndex = filters.findIndex(find => find.name == 'keyValue');
    if (filterIndex != -1) {
      filters.splice(filterIndex, 1);
    }
    //super.manageGetDataDB(tableName, paginator);
    switch (tableName) {

      case 'tbTiposCodigosPromocionales_tiposEntrada':
        this.cargarTiposCodigosPromocionales_tiposEntrada(paginator, filters, parent_pkId,false);
        break;

      case 'tbTiposCodigosPromocionales_tiposPromociones':
        this.cargarTiposCodigosPromocionales_tiposPromociones(paginator, filters, parent_pkId, false);
        break;  
      case 'tbTiposCodigosPromocionales_tiposEntrada_calco':
        this.cargarTiposCodigosPromocionales_tiposEntrada(paginator, filters, parent_pkId, true);
        break;

      case 'tbTiposCodigosPromocionales_tiposPromociones_calco':
        this.cargarTiposCodigosPromocionales_tiposPromociones(paginator, filters, parent_pkId, true);
        break;  
      case 'tbCodigoPromocionalEntradas':
        this.cargarCodigosPromocionalesEntradas(paginator, filters, parent_pkId);
        break;

      case 'tbCodigoPromocionalTipoBono':
        this.cargarCodigosPromocionalesTipoBono(paginator, filters, parent_pkId);
        break; 
      case 'tbCodigoPromocionalTiposBonoEntradas':
        this.cargarCodigoPromocionalTiposBonoEntradas(paginator, filters, parent_pkId, subparent_pkId);
        break; 
      case 'tbCodigoPromocionalTiposBonoEntradas_calco':
        this.cargarTipoCodigoPromocionalTiposBonoEntradas(paginator, filters, parent_pkId,subparent_pkId, true);
        break;  
      case 'tbTiposPromocionEntradas':
        this.cargarTiposPromocionEntradas_tipoBono(paginator, filters, parent_pkId);
        break;
      case 'Calendariodias_tiposcontrol':
        this.cargarTiposControlAcceso_calendario(paginator, filters, parent_pkId);
        break; 
      case 'Calendariohoras_tiposcontrol':
      this.cargarTiposControlAcceso_calendarioHoras(paginator, filters, parent_pkId);
      break; 
      default:
        break;
    }
  }

  cargarTiposCodigosPromocionales_tiposEntrada(paginator: Paginator, filters: Filter[], parent_pkId, calco?) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    

    this.editTipoCodigoPromocionesService.getTiposCodigoPromocionalByPkId(parent_pkId).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let ListaTiposCodigosPromocionales_tiposEntrada = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaCodigoPromocional[0].TipoCodigoPromocionalEntradas);
          var transFormDataTiposCodigosPromocionales_tiposEntradaShow = this.transFormDataTiposCodigosPromocionales_tiposEntrada(ListaTiposCodigosPromocionales_tiposEntrada);
          if (calco) 
            transFormDataTiposCodigosPromocionales_tiposEntradaShow = this.transFormDataTiposCodigosPromocionales_tiposEntrada_calco(ListaTiposCodigosPromocionales_tiposEntrada);
          this.dataSource = new MatTableDataSource(transFormDataTiposCodigosPromocionales_tiposEntradaShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }


  cargarCodigosPromocionalesEntradas(paginator: Paginator, filters: Filter[], parent_pkId) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    

    this.editTipoCodigoPromocionesService.getCodigoPromocionalByPkId(parent_pkId).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let ListaTiposCodigosPromocionales_tiposEntrada = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaCodigoPromocional[0].CodigoPromocionalEntradas);
          const transFormDataTiposCodigosPromocionales_tiposEntradaShow = this.transFormDataCodigosPromocionalesEntradas(ListaTiposCodigosPromocionales_tiposEntrada);
          this.dataSource = new MatTableDataSource(transFormDataTiposCodigosPromocionales_tiposEntradaShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  cargarCodigosPromocionalesTipoBono(paginator: Paginator, filters: Filter[], parent_pkId) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    

    this.editTipoCodigoPromocionesService.getCodigoPromocionalByPkId(parent_pkId).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let ListaTiposCodigosPromocionales_tiposEntrada = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaCodigoPromocional[0].CodigoPromocionalTiposBono);
          const transFormDataTiposCodigosPromocionales_tiposEntradaShow = this.transFormDataCodigosPromocionalesTipoBono(ListaTiposCodigosPromocionales_tiposEntrada);
          this.dataSource = new MatTableDataSource(transFormDataTiposCodigosPromocionales_tiposEntradaShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  


  
  cargarCodigoPromocionalTiposBonoEntradas(paginator: Paginator, filters: Filter[], parent_pkId, subparent_pkId) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    
    //aqui necesitamos el pkId del Codigo Promoicion y el pkId de CodigoPromocionalTiposBono para obtener la lista 
    //CodigoPromocionalTiposBonoEntradas
    this.editTipoCodigoPromocionesService.getCodigoPromocionalByPkId(parent_pkId).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let ListaTiposCodigosPromocionales_tiposEntrada_parent = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaCodigoPromocional[0].CodigoPromocionalTiposBono);
          if (ListaTiposCodigosPromocionales_tiposEntrada_parent && ListaTiposCodigosPromocionales_tiposEntrada_parent.length>0) {
          let ListaTiposCodigosPromocionales_tiposEntrada = ListaTiposCodigosPromocionales_tiposEntrada_parent.find(f => f["pkId"]==subparent_pkId)["CodigoPromocionalTiposBonoEntradas"];
          const transFormDataTiposCodigosPromocionales_tiposEntradaShow = this.transFormDataCodigoPromocionalTiposBonoEntradas(ListaTiposCodigosPromocionales_tiposEntrada);
          this.dataSource = new MatTableDataSource(transFormDataTiposCodigosPromocionales_tiposEntradaShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          }
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  cargarTipoCodigoPromocionalTiposBonoEntradas(paginator: Paginator, filters: Filter[], parent_pkId, subparent_pkId, calco?) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    
    //aqui necesitamos el pkId del Codigo Promoicion y el pkId de CodigoPromocionalTiposBono para obtener la lista 
    //CodigoPromocionalTiposBonoEntradas
    this.editTipoCodigoPromocionesService.getTiposCodigoPromocionalByPkId(parent_pkId).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let ListaTiposCodigosPromocionales_tiposEntrada_parent = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaCodigoPromocional[0].TipoCodigoPromocionalTiposBono);
          let ListaTiposCodigosPromocionales_tiposEntrada = ListaTiposCodigosPromocionales_tiposEntrada_parent.find(f => f["pkId"]==subparent_pkId)["TipoCodigoPromocionalTiposBonoEntradas"];
          const transFormDataTiposCodigosPromocionales_tiposEntradaShow = this.transFormDataTipoCodigoPromocionalTiposBonoEntradas(ListaTiposCodigosPromocionales_tiposEntrada,subparent_pkId);
          this.dataSource = new MatTableDataSource(transFormDataTiposCodigosPromocionales_tiposEntradaShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  transFormDataTiposCodigosPromocionales_tiposEntrada(transFormDataTiposCodigosPromocionales_tiposEntrada) {
    const TiposCodigosPromocionales_tiposEntradaShow = [];

    transFormDataTiposCodigosPromocionales_tiposEntrada.map(value => {
      const NewTiposCodigosPromocionales_tiposEntrada = {};
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'CantidadLimite', 'CantidadLimite');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoEntradaId', 'TipoEntradaId');
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "HeredarDeBajaEnCodigoPromocional", "0");

      switch (value.DeBaja) {
        case "0":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", false);
          break;
        case "1":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", true);
          break;
      }

      TiposCodigosPromocionales_tiposEntradaShow.push(NewTiposCodigosPromocionales_tiposEntrada);
    });
    return TiposCodigosPromocionales_tiposEntradaShow;
  }

  transFormDataTiposCodigosPromocionales_tiposEntrada_calco(transFormDataTiposCodigosPromocionales_tiposEntrada) {
    const TiposCodigosPromocionales_tiposEntradaShow = [];

    transFormDataTiposCodigosPromocionales_tiposEntrada.map(value => {
      const NewTiposCodigosPromocionales_tiposEntrada = {};
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoEntradaId', 'TipoEntradaId');
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "Cantidad", "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "CantidadUtilizada", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "BaseImponible", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "TipoIvaId", "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "IVA", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "Precio", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DesglosarComision", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "ComisionBI", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "TipoIvaComisionId", "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "Comision", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "IvaComision", "0.0");

      switch (value.DeBaja) {
        case "0":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", false);
          break;
        case "1":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", true);
          break;
      }

      TiposCodigosPromocionales_tiposEntradaShow.push(NewTiposCodigosPromocionales_tiposEntrada);
    });
    return TiposCodigosPromocionales_tiposEntradaShow;
  }

  transFormDataCodigosPromocionalesEntradas(transFormDataTiposCodigosPromocionales_tiposEntrada) {
    const TiposCodigosPromocionales_tiposEntradaShow = [];

    transFormDataTiposCodigosPromocionales_tiposEntrada.map(value => {

      const NewTiposCodigosPromocionales_tiposEntrada = {};
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'CodigoPromocionalId', 'CodigoPromocionalId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoCodigoPromocionalEntradaId', 'TipoCodigoPromocionalEntradaId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoEntradaId', 'TipoEntradaId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoEntradaNombre', 'TipoEntradaNombre');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'Cantidad', 'Cantidad');



      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'CantidadUtilizada', 'CantidadUtilizada');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'BaseImponible', 'BaseImponible');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoIvaId', 'TipoIvaId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'IVA', 'IVA');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'Precio', 'Precio');

      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'DesglosarComision', 'DesglosarComision');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'ComisionBI', 'ComisionBI');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoIvaComisionId', 'TipoIvaComisionId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'Comision', 'Comision');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'IvaComision', 'IvaComision');

      //this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "IvaVal", false);


      switch (value.DeBaja) {
        case "0":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", false);
          break;
        case "1":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", true);
          break;
      }

      TiposCodigosPromocionales_tiposEntradaShow.push(NewTiposCodigosPromocionales_tiposEntrada);
    });
    return TiposCodigosPromocionales_tiposEntradaShow;
  }


  transFormDataCodigoPromocionalTiposBonoEntradas(transFormDataTiposCodigosPromocionales_tiposEntrada) {
    const TiposCodigosPromocionales_tiposEntradaShow = [];

    transFormDataTiposCodigosPromocionales_tiposEntrada.map(value => {
      const NewTiposCodigosPromocionales_tiposEntrada = {};
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'CodigoPromocionalTipoBonoId', 'CodigoPromocionalTipoBonoId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoCodigoPromocionalTipoBonoEntradaId', 'TipoCodigoPromocionalTipoBonoEntradaId');      
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoEntradaNombre', 'TipoEntradaNombre');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoEntradaId', 'TipoEntradaId');

      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'BaseImponible', 'BaseImponible');
      //this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoIvaId', 'TipoIvaId');
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "TipoIvaId", parseInt(value.TipoIvaId).toString());
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'IVA', 'IVA');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'Precio', 'Precio');

      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'DesglosarComision', 'DesglosarComision');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'ComisionBI', 'ComisionBI');
      //this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoIvaComisionId', 'TipoIvaComisionId');
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "TipoIvaComisionId", parseInt(value.TipoIvaComisionId).toString());
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", false);
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'Comision', 'Comision');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'IvaComision', 'IvaComision');

      TiposCodigosPromocionales_tiposEntradaShow.push(NewTiposCodigosPromocionales_tiposEntrada);
    });
    return TiposCodigosPromocionales_tiposEntradaShow;
  }

  transFormDataTipoCodigoPromocionalTiposBonoEntradas(transFormDataTiposCodigosPromocionales_tiposEntrada, subparent_pkId) {
    const TiposCodigosPromocionales_tiposEntradaShow = [];

    transFormDataTiposCodigosPromocionales_tiposEntrada.map(value => {
      const NewTiposCodigosPromocionales_tiposEntrada = {};
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoCodigoPromocionalTipoBonoEntradaId', 'pkId');      
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoEntradaId', 'TipoBonoEntradaId');

      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'CodigoPromocionalTipoBonoId', subparent_pkId);
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'TipoEntradaNombre', "");
      

      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'BaseImponible', "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'TipoIvaId', "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'IVA', "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'Precio', "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'DesglosarComision', "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'ComisionBI', "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'TipoIvaComisionId', "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'Comision', "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, 'IvaComision', "0");


      TiposCodigosPromocionales_tiposEntradaShow.push(NewTiposCodigosPromocionales_tiposEntrada);
    });
    return TiposCodigosPromocionales_tiposEntradaShow;
  }


  transFormDataCodigosPromocionalesTipoBono(transFormDataTiposCodigosPromocionales_tiposEntrada) {
    const TiposCodigosPromocionales_tiposEntradaShow = [];

    transFormDataTiposCodigosPromocionales_tiposEntrada.map(value => {
      const NewTiposCodigosPromocionales_tiposEntrada = {};
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'pkId', 'pkId');

      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'CodigoPromocionalId', 'CodigoPromocionalId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoCodigoPromocionalTipoBonoId', 'TipoCodigoPromocionalTipoBonoId');

      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'DescripcionTipoBono', 'DescripcionTipoBono');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'TipoBonoId', 'TipoBonoId');

      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'Cantidad', 'Cantidad');


      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'CantidadUtilizada', 'CantidadUtilizada');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'BaseImponible', 'BaseImponible');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'Precio', 'Precio');

      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'DesglosarComision', 'DesglosarComision');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'ComisionBI', 'ComisionBI');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposEntrada, value, 'Comision', 'Comision');


      switch (value.DeBaja) {
        case "0":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", false);
          break;
        case "1":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposEntrada, "DeBaja", true);
          break;
      }

      TiposCodigosPromocionales_tiposEntradaShow.push(NewTiposCodigosPromocionales_tiposEntrada);
    });
    return TiposCodigosPromocionales_tiposEntradaShow;
  }
  cargarTiposCodigosPromocionales_tiposPromociones(paginator: Paginator, filters: Filter[], parent_pkId, calco?) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;

    this.editTipoCodigoPromocionesService.getTiposCodigoPromocionalByPkId(parent_pkId).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {

          for (var i=0;i<this.respuestaAPI.DatosResult.ListaCodigoPromocional[0].TipoCodigoPromocionalTiposBono.length;i++) {
            this.respuestaAPI.DatosResult.ListaCodigoPromocional[0].TipoCodigoPromocionalTiposBono[i]["Idiomas"] =  [{"CodIdioma":"ca","Nombre":"Agents de Viatge","Descripcion":"Descrip 1"},{"CodIdioma":"es-ES","Nombre":"Agentes de Viaje","Descripcion":"Desccripción 1"},{"CodIdioma":"en-GB","Nombre":"","Descripcion":""},{"CodIdioma":"fr-FR","Nombre":"","Descripcion":""}];
          }

          let ListaTiposCodigosPromocionales_tiposPromociones = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaCodigoPromocional[0].TipoCodigoPromocionalTiposBono);
/*           for (var i=0;i<ListaTiposCodigosPromocionales_tiposPromociones.length;i++) {
            ListaTiposCodigosPromocionales_tiposPromociones[i]["Idipomas"] =  [{"CodIdioma":"ca","Nombre":"Agents de Viatge","Descripcion":"Descrip 1"},{"CodIdioma":"es-ES","Nombre":"Agentes de Viaje","Descripcion":"Desccripción 1"},{"CodIdioma":"en-GB","Nombre":"","Descripcion":""},{"CodIdioma":"fr-FR","Nombre":"","Descripcion":""}];
          } */

          var transFormDataTiposCodigosPromocionales_tiposPromocionesShow = this.transFormDataTiposCodigosPromocionales_tiposPromociones(ListaTiposCodigosPromocionales_tiposPromociones);
          if (calco)
          transFormDataTiposCodigosPromocionales_tiposPromocionesShow = this.transFormDataTiposCodigosPromocionales_tiposPromociones_calco(ListaTiposCodigosPromocionales_tiposPromociones);

          this.dataSource = new MatTableDataSource(transFormDataTiposCodigosPromocionales_tiposPromocionesShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
    
  }

  
  transFormDataTiposCodigosPromocionales_tiposPromociones(transFormDataTiposCodigosPromocionales_tiposPromociones) {
    const TiposCodigosPromocionales_tiposPromocionesShow = [];

    transFormDataTiposCodigosPromocionales_tiposPromociones.map(value => {
      const NewTiposCodigosPromocionales_tiposPromociones = {};
      
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposPromociones, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposPromociones, value, 'TipoBonoId', 'TipoBonoId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposPromociones, value, 'CantidadLimite', 'CantidadLimite');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposPromociones, value, 'Idiomas', 'Idiomas');
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "HeredarDeBajaEnCodigoPromocional", "0");

      switch (value.DeBaja) {
        case "0":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "DeBaja", false);
          break;
        case "1":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "DeBaja", true);
          break;
      }

      TiposCodigosPromocionales_tiposPromocionesShow.push(NewTiposCodigosPromocionales_tiposPromociones);
    });
    return TiposCodigosPromocionales_tiposPromocionesShow;
  }

  transFormDataTiposCodigosPromocionales_tiposPromociones_calco(transFormDataTiposCodigosPromocionales_tiposPromociones) {
    const TiposCodigosPromocionales_tiposPromocionesShow = [];

    transFormDataTiposCodigosPromocionales_tiposPromociones.map(value => {
      const NewTiposCodigosPromocionales_tiposPromociones = {};
      
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposPromociones, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposPromociones, value, 'TipoCodigoPromocionalTipoBonoId', 'pkId');
      this.assignDataByObj(NewTiposCodigosPromocionales_tiposPromociones, value, 'TipoBonoId', 'TipoBonoId');
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "Cantidad", "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "CantidadUtilizada", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "CodigoPromocionalId", "0");
      //this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "TipoCodigoPromocionalTipoBonoId", "0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "DescripcionTipoBono", "");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "BaseImponible", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "Precio", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "DesglosarComision", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "ComisionBI", "0.0");
      this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "Comision", "0.0");


      switch (value.DeBaja) {
        case "0":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "DeBaja", false);
          break;
        case "1":
          this.assignDataByValue(NewTiposCodigosPromocionales_tiposPromociones, "DeBaja", true);
          break;
      }

      TiposCodigosPromocionales_tiposPromocionesShow.push(NewTiposCodigosPromocionales_tiposPromociones);
    });
    return TiposCodigosPromocionales_tiposPromocionesShow;
  }

  cargarTiposPromocionEntradas_tipoBono(paginator: Paginator, filters: Filter[], parent_pkId) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;

    this.servicioAPI.getTiposBonoAsync(parent_pkId).then(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {

          let ListaTiposPromocionEntradas_tipoBono = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.lista[0].TipoBonoEntadas);

          const transFormDataListaTiposPromocionEntradas_tipoBonoShow = this.transFormDatacargarTiposPromocionEntradas_tipoBono(ListaTiposPromocionEntradas_tipoBono);

          this.dataSource = new MatTableDataSource(transFormDataListaTiposPromocionEntradas_tipoBonoShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
    
  }


  transFormDatacargarTiposPromocionEntradas_tipoBono(transFormDataTiposPromocionEntradas_tipoBono) {
    const TiposPromocionEntradas_tipoBonoShow = [];

    transFormDataTiposPromocionEntradas_tipoBono.map(value => {
      const NewtransFormDataTiposPromocionEntradas_tipoBono = {};
      
      let date_FechaInicio = this.global.aFecha(value.dtFechaInicio);
      let date_FechaFin = this.global.aFecha(value.dtFechaFin);

      let date_FechaInicioStr = date_FechaInicio.getFullYear()+"-"+(date_FechaInicio.getMonth()+1)+"-"+date_FechaInicio.getDate();
      let date_FechaFinStr = date_FechaFin.getFullYear()+"-"+(date_FechaFin.getMonth()+1)+"-"+date_FechaFin.getDate();

      let date_FechaInicioStrlocal = date_FechaInicio.getDate()+"/"+(date_FechaInicio.getMonth()+1)+"/"+date_FechaInicio.getFullYear();
      let date_FechaFinStrlocal = date_FechaFin.getDate()+"/"+(date_FechaFin.getMonth()+1)+"/"+date_FechaFin.getFullYear();


      //this.assignDataByObj(NewInternetGroup, value, 'pkId', 'pkId');
      this.assignDataByObj(NewtransFormDataTiposPromocionEntradas_tipoBono, value, 'pkId', 'pkId');
      this.assignDataByObj(NewtransFormDataTiposPromocionEntradas_tipoBono, value, 'TipoEntradaId', 'iTipoEntradaId');
      this.assignDataByObj(NewtransFormDataTiposPromocionEntradas_tipoBono, value, 'iNumEntradas', 'iNumEntradas');
      this.assignDataByValue(NewtransFormDataTiposPromocionEntradas_tipoBono,'rPorcentaje', value.rPorcentaje.replace(/,/g, '.'));
      
      this.assignDataByValue(NewtransFormDataTiposPromocionEntradas_tipoBono, 'dtFechaInicio', new FormControl(moment(date_FechaInicioStrlocal,"DD/MM/YYYY")));
      this.assignDataByValue(NewtransFormDataTiposPromocionEntradas_tipoBono, 'dtFechaFin',new FormControl(moment(date_FechaFinStrlocal,"DD/MM/YYYY")));

      if (date_FechaInicioStr!="1900-1-1" && date_FechaFinStr!="1900-1-1") 
        TiposPromocionEntradas_tipoBonoShow.push(NewtransFormDataTiposPromocionEntradas_tipoBono);
 
      //TiposPromocionEntradas_tipoBonoShow.push(NewtransFormDataTiposPromocionEntradas_tipoBono);
    
    });
    return TiposPromocionEntradas_tipoBonoShow;
  }



  cargarTiposControlAcceso_calendario(paginator: Paginator, filters: Filter[], parent_pkId) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    

    this.servicioAPI.getCalendariosTiposControlbyId(parent_pkId).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let ListaTiposControlAcceso_calendario = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.customanCalendariosTiposControl);
          const transFormDataTiposTiposControlAcceso_calendarioShow = this.transFormDataTiposControlAcceso_calendario(ListaTiposControlAcceso_calendario);
          this.dataSource = new MatTableDataSource(transFormDataTiposTiposControlAcceso_calendarioShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          //this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }


  transFormDataTiposControlAcceso_calendario(transFormDataTiposTiposControlAcceso_calendario) {
    const transFormDataTiposTiposControlAcceso_calendarioShow = [];

    transFormDataTiposTiposControlAcceso_calendario.map((value,i) => {
      const NewTiposTiposControlAcceso_calendario = {};
      
      let date_FechaAcceso = this.global.aFecha(value.FechaAcceso);
      let date_FechaAccesoFin = this.global.aFecha(value.FechaAccesoFin);

      let date_FechaAccesoStr = date_FechaAcceso.getFullYear()+"-"+(date_FechaAcceso.getMonth()+1)+"-"+date_FechaAcceso.getDate();
      let date_FechaAccesoFinStr = date_FechaAccesoFin.getFullYear()+"-"+(date_FechaAccesoFin.getMonth()+1)+"-"+date_FechaAccesoFin.getDate();

      let date_FechaAccesoStrlocal = date_FechaAcceso.getDate()+"/"+(date_FechaAcceso.getMonth()+1)+"/"+date_FechaAcceso.getFullYear();
      let date_FechaAccesoFinStrlocal = date_FechaAccesoFin.getDate()+"/"+(date_FechaAccesoFin.getMonth()+1)+"/"+date_FechaAccesoFin.getFullYear();


      //this.assignDataByObj(NewInternetGroup, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposTiposControlAcceso_calendario, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposTiposControlAcceso_calendario, value, 'TipoControlId', 'TipoControlId');
      //this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso',  new FormControl(new Date(value.FechaAcceso)) );
      this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso', new FormControl(moment(date_FechaAccesoStrlocal,"DD/MM/YYYY")));
      this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAccesoFin',new FormControl(moment(date_FechaAccesoFinStrlocal,"DD/MM/YYYY")));

/*       this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso',date_FechaAccesoStrlocal);
      this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAccesoFin',date_FechaAccesoFinStrlocal); */


/*       this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso_day', date_FechaAcceso.getDate());
      this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso_mes', (date_FechaAcceso.getMonth()+1)); */
      
     
/*       this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso', moment(value.FechaAcceso, 'DD/MM/YYYY').format('DD-MM-YYYY'));
      this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAccesoFin', moment(value.FechaAccesoFin, 'DD/MM/YYYY').format('DD-MM-YYYY')); */

 
      if (date_FechaAccesoStr!="1900-1-1" && date_FechaAccesoFinStr!="1900-1-1") 
        transFormDataTiposTiposControlAcceso_calendarioShow.push(NewTiposTiposControlAcceso_calendario);
    });

    return transFormDataTiposTiposControlAcceso_calendarioShow;
  }

  
  cargarTiposControlAcceso_calendarioHoras(paginator: Paginator, filters: Filter[], parent_pkId) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    

    this.servicioAPI.getCalendariosTiposControlbyId(parent_pkId).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let ListaTiposControlAcceso_calendario = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.customanCalendariosTiposControl);
          const transFormDataTiposTiposControlAcceso_calendarioShow = this.transFormDataTiposControlAcceso_calendarioHoras(ListaTiposControlAcceso_calendario);
          this.dataSource = new MatTableDataSource(transFormDataTiposTiposControlAcceso_calendarioShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          //this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }
        

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }


  transFormDataTiposControlAcceso_calendarioHoras(transFormDataTiposTiposControlAcceso_calendario) {
    const transFormDataTiposTiposControlAcceso_calendarioShow = [];

    transFormDataTiposTiposControlAcceso_calendario.map(value => {
      const NewTiposTiposControlAcceso_calendario = {};
      
      let date_FechaAcceso = this.global.aFecha(value.FechaAcceso);
      let date_FechaAccesoFin = this.global.aFecha(value.FechaAccesoFin);

      let date_FechaAccesoStr = date_FechaAcceso.getDate()+"/"+(date_FechaAcceso.getMonth()+1)+"/"+date_FechaAcceso.getFullYear();
      let date_FechaAccesoFinStr = date_FechaAccesoFin.getDate()+"/"+(date_FechaAccesoFin.getMonth()+1)+"/"+date_FechaAccesoFin.getFullYear();

      let date_FechaAccesoStrHora =  this.global.aHora(value.FechaAcceso);
      let date_FechaAccesoFinStrHora = this.global.aHora(value.FechaAccesoFin);

      //this.assignDataByObj(NewInternetGroup, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposTiposControlAcceso_calendario, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiposTiposControlAcceso_calendario, value, 'TipoControlId', 'TipoControlId');
      //this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso',  new FormControl(new Date(value.FechaAcceso)) );
      this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso', date_FechaAccesoStrHora);
      this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAccesoFin',date_FechaAccesoFinStrHora);
     
/*       this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAcceso', moment(value.FechaAcceso, 'DD/MM/YYYY').format('DD-MM-YYYY'));
      this.assignDataByValue(NewTiposTiposControlAcceso_calendario, 'FechaAccesoFin', moment(value.FechaAccesoFin, 'DD/MM/YYYY').format('DD-MM-YYYY')); */

 
      if (date_FechaAccesoStr=="1/1/1900" && date_FechaAccesoFinStr=="1/1/1900") 
        transFormDataTiposTiposControlAcceso_calendarioShow.push(NewTiposTiposControlAcceso_calendario);
    });
    return transFormDataTiposTiposControlAcceso_calendarioShow;
  }


/*   cargarTest(paginator: Paginator, filters: Filter[]) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;

    this.servicioAPI.getGruposInternet(paginator, filters).subscribe(
      data => {
        let globalInterno = this.global;
        this.respuestaAPI = new InternetGroups_response().deserialize(data);

        if (this.respuestaAPI.DatosResult != null) {
          let ListaInternetGroup: InternetGroup[];
          ListaInternetGroup = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.listaGrupoInternet);
          const internetGroupListShow = this.transFormDataTest(ListaInternetGroup);
          this.dataSource = new MatTableDataSource(internetGroupListShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          this.loadData(this.dataSource);


          this.filterInRquest();
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  transFormDataTest(internetgroups: InternetGroup[]) {
    const listInternetGroupShow: InternetGroups_show[] = [];

    internetgroups.map(value => {
      const NewInternetGroup = new InternetGroups_show();
      this.assignDataByObj(NewInternetGroup, value, 'pkId', 'pkId');
      this.assignDataByObj(NewInternetGroup, value, 'Nombre', 'Nombre');
      this.assignDataByObj(NewInternetGroup, value, 'Cantidad', 'Maximo');
      listInternetGroupShow.push(NewInternetGroup);
    });
    return listInternetGroupShow;
  } */

  cargarDatosTipoEntrada_tiposEntrada() {
    let tiposEntradas;
    this.servicioAPI.getTiposEntradas().subscribe(tiposEntrada => {

      if (tiposEntrada.DatosResult && tiposEntrada.DatosResult.Lista.length > 0) {

        tiposEntradas = tiposEntrada.DatosResult.Lista;
      } else {

        tiposEntradas = [];
      }


      this.loadSelect('TipoEntradaId', tiposEntradas, 'Nombre', 'pkId'); 
      //this.loadSelect('iTipoEntradaId', tiposEntradas, 'Nombre', 'pkId'); 
      return tiposEntrada;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  

/*   cargarDatosTipoEntrada_tipobono() {
    let tiposEntradas;
    this.servicioAPI.getTiposEntradas().subscribe(tiposEntrada => {

      if (tiposEntrada.DatosResult && tiposEntrada.DatosResult.Lista.length > 0) {

        tiposEntradas = tiposEntrada.DatosResult.Lista;
      } else {

        tiposEntradas = [];
      }


      this.loadSelect('TipoBonoId', tiposEntradas, 'Nombre', 'pkId'); 
      //this.loadSelect('iTipoEntradaId', tiposEntradas, 'Nombre', 'pkId'); 
      return tiposEntrada;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  } */

  cargarTiposPromocionEntradas() {
/*     let tiposBonos;
    this.servicioAPI.getTiposBonos().subscribe(tiposBono => {

      if (tiposBono.DatosResult && tiposBono.DatosResult.lista.length > 0) {

        tiposBonos = tiposBono.DatosResult.lista;
      } else {

        tiposBonos = [];
      }

    
      this.loadSelect('TipoBonoId', tiposBonos, 'chNombrePromo', 'pkId'); 
      return tiposBono;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    ); */
  }


  cargarDatosTipoEntrada_tiposPromociones() {
    let tiposBonos;
    this.servicioAPI.getTiposBonos().subscribe(tiposBono => {

      if (tiposBono.DatosResult && tiposBono.DatosResult.lista.length > 0) {

        tiposBonos = tiposBono.DatosResult.lista;
      } else {

        tiposBonos = [];
      }

    
      this.loadSelect('TipoBonoId', tiposBonos, 'chNombrePromo', 'pkId'); 
      return tiposBono;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  cargarDatosIva() {
    let tipoIVAs;
    this.servicioAPI.getCalendariosTiposIVA().subscribe(tipoIVA => {
      if (tipoIVA.DatosResult && tipoIVA.DatosResult.DatosListas.length > 0) {
      
        tipoIVAs = tipoIVA.DatosResult.DatosListas;
        localStorage.setItem("TiposIvaCalendario",JSON.stringify(tipoIVAs));
      } else {

        tipoIVAs = [];
      }

    
      tipoIVAs.forEach(e => e.Nombre=e.Iva+"%");
      tipoIVAs.push({"Nombre":this.translation["ExentoONA"],"pkId":"0"});
      this.loadSelect('TipoIvaId', tipoIVAs, 'Nombre', 'pkId'); 
      this.loadSelect('TipoIvaComisionId', tipoIVAs, 'Nombre', 'pkId'); 
      return tipoIVA;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );

/*       if (tipoIVA.DatosResult && tipoIVA.DatosResult.ListaTipoIva.length > 0) {
        this.tiposIVA = tipoIVA.DatosResult.ListaTipoIva;
      } else {
        this.tiposIVA = [];
        resultado = false;
      }
    }); */
  }



  
}



