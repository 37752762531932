import { Deserializable } from "../deserializable";

export class Tarifas_show implements Deserializable {
    pkId:                    string;
    Internet:                string;
    Taquilla:                string;
    AlaVenta:                string;
    Semana:                  string;
    FecIniVisita:            string;
    FecFinVisita:            string;
    FecCompraDesde:          string;
    FecCompraHasta:          string;
    PrecioPVP:               string;
    caNombre:                string;
    "en-GBNombre":           string;
    "es-ESNombre":           string;
    caDescripcion:           string;
    "en-GBDescripcion":      string;
    "es-ESDescripcion":      string;
    DescripcionEntTPromTAbo: string;

 
  
    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }


    assign(param,data){
      this[param] = data
    }

  }