import { SelectionModel } from "@angular/cdk/collections";
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { first } from "rxjs/operators";

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import {
  Recintos,
  Recintos_Respuesta,
} from "../../_modelos/recintos-Respuesta.model";
import {
  TipoButaca,
  TipoButaca_Respuesta,
} from "../../_modelos/tipos-butacas-Respuesta.model";
import {
  TipoControl,
  TiposControl_Respuesta,
} from "../../_modelos/tipos-control-Respuesta.model";
import {
  Botonera,
  RespuestaBotonera,
  TipoPromocion,
  TiposPromocion_Respuesta,
} from "../../_modelos/tipos-promocion-Respuesta.model";

import { ConectarAPIService } from "../../_API/conectar-api.service";
//import {AlertaService} from '../../_otrosproveedores/alerta.service';
import { ActivatedRoute, Router } from "@angular/router";
import { AutentificacionService } from "../../_otrosproveedores/autentificacion.service";
import { AlertaService } from "../../shared/_alerta/alerta.service";
//import { DialogoPopUp } from '../../_otrosproveedores/popup.service';
import { MatTable } from "@angular/material/table";
import { Globales } from "@clasesV6/globales";
import { Categoria_Respuesta } from "@modelosV6/categoria-Respuesta.model";
import { GruposAsociados_Respuesta } from "@modelosV6/grupos-asociados-Respuesta.model";
import { GruposTipoBono_Respuesta } from "@modelosV6/grupos-tipo-bono-Respuesta.model";
import { TipoNivel_Respuesta } from "@modelosV6/tipo-nivel-Respuesta.model";
import { TipoProducto_Respuesta } from "@modelosV6/tipo-producto-Respuesta.model";
import { TipoVisita_Respuesta } from "@modelosV6/tipo-visita-Respuesta.model";
import { TiposPromocionEntrada_Respuesta } from "@modelosV6/tipos-promocion-entrada-Respuesta.model";
import { DialogoPopUp } from "../../shared/_alerta/popup.service";
//import { _MatTabHeaderMixinBase } from '@angular/material/tabs/typings/tab-header';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Translator } from "src/app/shared/services/translator/translator.service";

export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class ColumnaIdiomas {
  textoHeader: string;
  CodIdioma: string;
}

export class traspasomodelo {
  pkId: string;
  chCodigo: string;
  chNombrePromo: string;
  chDescripcionNombre: string;
  chTextoPantalla: string;
  chTextoImpresion: string;
  chTextoDisplay: string;
  chTipoValidez: string;
  iValidez: string;
  iGrupoId: string;
  iTipoProductoId: string;
  iGrupoAsociadoId: string;
  iTipoVisitaId: string;
  iTipoNivelId: string;
  iCategoriaId: string;
  bALaVenta: boolean;
  bImprimirPromo: boolean;
  bImprimirPrecio: boolean;
  chTeclaRapida: string;
  iTEDatosId: string;

  deserialize: null;
}

export class filtrosActivos {
  pkId: string = "";
  chCodigo: string = "";
  chNombrePromo: string = "";
  chDescripcionNombre: string = "";
  chTextoPantalla: string = "";
  chTextoImpresion: string = "";
  chTextoDisplay: string = "";
  chTipoValidez: string = "";
  iValidez: string = "";
  iGrupoId: string = "";
  iTipoProductoId: string = "";
  iGrupoAsociadoId: string = "";
  iTipoVisitaId: string = "";
  iTipoNivelId: string = "";
  iCategoriaId: string = "";
  bALaVenta: string = "";
  bImprimirPromo: string = "";
  bImprimirPrecio: string = "";
  chTeclaRapida: string = "";
  iTEDatosId: string = "";
  textoGeneral: string = "";
}

export class tipoBonoEntradaPlantilla {
  NombreTipoEntrada: string = "";
  dtFechaFin: string = "";
  dtFechaInicio: string = "";
  iNumEntradas: string = "";
  iTipoBonoEntradasId: string = "";
  iTipoBonoId: string = "";
  iTipoEntradaId: string = "";
  rPorcentaje: string = "";
}

@Component({
  selector: "app-tipos-promocion",
  templateUrl: "./tipos-promocion.component.html",
  styleUrls: ["./tipos-promocion.component.css"],
})
export class TiposPromocionComponent implements OnInit {
  displayedColumns: string[] = [
    "Acciones",
    "Nombre",
    "Descripcion",
    "TextoPantalla",
    "TextoImpresion",
    "TextoDisplay",
    "TipoValidez",
    "Validez",
    "Grupo",
    "TipoProducto",
    "GrupoAsociado",
    "TipoVisita",
    "TipoNivel",
    "Categoria",
    "ALaVenta",
  ];
  columnsToDisplay: string[] = [
    "Acciones",
    "Nombre",
    "Descripcion",
    "Grupo",
    "GrupoMotivo",
    "ControlaAcceso",
    "TipoControl",
    "AlaVenta",
    "RecintosAsociados",
    "ActividadesAsociados",
  ];
  displayedFilters: string[] = [
    "Nombre",
    "Descripcion",
    "TextoPantalla",
    "TextoImpresion",
    "TextoDisplay",
    "TipoValidez",
    "Validez",
    "Grupo",
    "TipoProducto",
    "GrupoAsociado",
    "TipoVisita",
    "TipoNivel",
    "Categoria",
    "ALaVenta",
  ];
  filtersToDisplay: string[] = this.displayedFilters.slice();
  dataSource: MatTableDataSource<TipoPromocion>;
  dataSourceTarifaPromocionEntrada: MatTableDataSource<tipoBonoEntradaPlantilla>;
  seleccion = new SelectionModel<TipoPromocion>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatTable) entradastable: MatTable<any>;

  usuarios;
  respuestaAPI: Recintos_Respuesta;
  editando: boolean = false;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  formControlListaColumnas: FormControl;
  //editorListaIdiomas: Array<IdiomaGR>;
  //--
  // tiposIdiomasAPI: Idiomas_Respuesta;
  // tiposIdiomas: Array<Idiomas>;
  // tipoControl = new FormControl('', [Validators.required]);
  // tipoControlIdiomas = new FormControl('', [Validators.required]);
  //---
  tiposRecintosAPI: Recintos_Respuesta;
  tiposRecintoTotal: Array<Recintos>;
  tiposRecinto: Array<Recintos>;
  tiposActividad: Array<Recintos>;
  //--
  tiposTiposControlAPI: TiposControl_Respuesta;
  tiposTiposControl: Array<TipoControl>;
  tipocontrol = new FormControl("", [Validators.required]);
  tipocontrolbotonera = new FormControl("");
  grupoentradacontrol = new FormControl("");
  grupoentradamotivo = new FormControl("");
  tipoControlTipoButacas = new FormControl("");
  tipoimpresora = new FormControl("");

  // nuevos form
  /*
  iGrupoAsociadoId = new FormControl('');
  iGrupoId = new FormControl('');
  iTipoProductoId = new FormControl('');
  iTipoVisitaId = new FormControl('');
  iTipoNivelId = new FormControl('');
  iCategoriaId = new FormControl('');
  iTEDatosId = new FormControl('');
  */
  //--
  tiposTiposButacaAPI: TipoButaca_Respuesta;
  tiposTiposButaca: Array<TipoButaca>;
  //--
  tiposBotoneraPromocionAPI: RespuestaBotonera;
  tiposBotoneraPromocion: Array<Botonera>;
  //--
  //// tiposGruposTipoPromocionAPI: grupos_tipos_promocion_Respuesta;
  //// tiposGrupoTipoPromocion: Array<GrupoTiposPromocion>;
  //// tiposGrupoTipoPromocionMotivo: Array<GrupoTiposPromocion>;
  //--
  tiposTiposPromocionAPI: TiposPromocion_Respuesta;
  tiposTipoPromocion: Array<TipoPromocion>;
  tiposTipoPromocionTaller: Array<TipoPromocion>;
  tiposTipoPromocionRecinto: Array<TipoPromocion>;
  //--
  columnasIdiomas: Array<ColumnaIdiomas>;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  accionselecionada: string = null;
  panelColumnasvisible: boolean = false;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos();
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  //compruebaPorDefectovalor:boolean = true;
  tallerActividad: boolean = false;
  butacasCheck: boolean = false;
  recintosasociados: string[] = [];
  ordenRecintosAsociados: string[] = [];
  panelSelectorFiltrosvisible: boolean = false;
  actividadesasociadas: string[] = [];
  ordenActividadesAsociadas: string[] = [];
  translation = this.translator.GetTranslations();
  formControlListaFiltros: FormControl;
  tiposBonoAPI: TiposPromocion_Respuesta;
  tiposBono;
  gruposAsociadosAPI: GruposAsociados_Respuesta;
  gruposAsociados: TipoControl[];
  gruposTipoBonoAPI: GruposTipoBono_Respuesta;
  gruposTipoBono: any;
  tipoProductoAPI: TipoProducto_Respuesta;
  tipoProducto: any;
  tipoVisitaAPI: TipoVisita_Respuesta;
  tipoVisita: any;
  tiposNivelesAPI: TipoNivel_Respuesta;
  tiposNiveles: any;
  categoriaAPI: Categoria_Respuesta;
  categoria: any[];
  TiposEntradaDatosAPI: any;
  tiposEntradaDatos: any[];
  tiposBonoEntradaAPI: TiposPromocionEntrada_Respuesta;
  tiposBonoEntrada: tipoBonoEntradaPlantilla[];
  columnsToDisplayTarifaEntrada: string[];
  promocionEntradaFormGroup: FormArray = this.formBuilder.array([]);
  TiposEntradaAPI: any;
  tiposEntrada: any[];
  tipoBonoEntradaPlantilla: tipoBonoEntradaPlantilla;
  idBonoEnEdicion: any;
  idsBonoEntradaASobreescribir: any[];
  tablavalida;

  permiteBorrar: boolean = true;
  elementosBorrados: string[] = [];

  formchanges: boolean = false;

  //
  constructor(
    private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    public globales: Globales,
    private _snackBar: MatSnackBar,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,

    private _cds: ChangeDetectorRef
  ) {}

  @HostBinding("class.col") true;

  ngAfterViewInit() {
    //this.initializeForms()
    this.cdRef.detectChanges();
  }
  ngOnInit() {
    this.tablavalida = true;
    this.tipoBonoEntradaPlantilla = {
      NombreTipoEntrada: "",
      dtFechaFin: "",
      dtFechaInicio: "",
      iNumEntradas: "",
      iTipoBonoEntradasId: "",
      iTipoBonoId: "",
      iTipoEntradaId: "",
      rPorcentaje: "",
    };

    this.displayedColumns = [
      "Acciones",
      "Nombre",
      "Descripcion",
      "TextoPantalla",
      "TextoImpresion",
      "Grupo",
      "ALaVenta",
    ];
    this.columnsToDisplay = [
      "Acciones",
      "Nombre",
      "Descripcion",
      "Grupo",
      "ALaVenta",
    ];
    this.columnsToDisplayTarifaEntrada = [
      "Acciones",
      "NombreTipoEntrada",
      "iNumEntradas",
      "rPorcentaje",
      "dtFechaInicio",
      "dtFechaFin",
    ];
    this.displayedFilters = [
      this.translation.chCodigo,
      this.translation.chNombrePromo,
      this.translation.chDescripcionNombre,
      this.translation.chTextoPantalla,
      this.translation.chTextoImpresion,
      this.translation.chTextoDisplay,
      this.translation.chTipoValidez,
      this.translation.iValidez,
      this.translation.iGrupoId,
      this.translation.iTipoProductoId,
      this.translation.iGrupoAsociadoId,
      this.translation.iTipoVisitaId,
      this.translation.iTipoNivelId,
      this.translation.iCategoriaId,
      this.translation.bALaVenta,
    ];
    this.filtersToDisplay = this.displayedFilters.slice();

    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(
      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      (res) => {
        let ruta = "/";
        this._ruta.snapshot.pathFromRoot[
          this._ruta.snapshot.pathFromRoot.length - 1
        ].url.forEach((elemento) => {
          ruta = ruta + elemento.path + "/";
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(
          ruta,
          "VER"
        );
        if (!this.permisoFormulario) {
          this.router.navigate(["/portada"]);
        }
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(
          ruta,
          "NUEVO"
        );
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(
          ruta,
          "MODIFICAR"
        );
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(
          ruta,
          "ELIMINAR"
        );
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(
          ruta,
          "DUPLICAR"
        );
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({
            accion: "borrar",
            texto: this.translation.Borrar,
            funcion: "BorrarSeleccionados",
          });
        }
      }
    );
    //Seguimos
    //this.cargarDatos();
    this.resetearFiltrosActivos();

    this.editorRegistroForm = this.formBuilder.group({
      chCodigo: ["", Validators.required],
      chNombrePromo: [""],
      chDescripcionNombre: [""],
      chTextoPantalla: [""],
      chTextoImpresion: [""],
      chTextoDisplay: [""],
      chTipoValidez: [undefined, Validators.required],
      iValidez: ["", Validators.required],
      iGrupoId: ["", Validators.required],
      iTipoProductoId: [""],
      iGrupoAsociadoId: [""],
      iTipoVisitaId: [""],
      iTipoNivelId: [""],
      iCategoriaId: [""],
      bALaVenta: [false],
      bImprimirPromo: [false],
      bImprimirPrecio: [false],
      chTeclaRapida: [""],
      iTEDatosId: [""],
      promocionEntradaFormGroupArray: this.promocionEntradaFormGroup,
    });

    this.editorFiltrosForm = this.formBuilder.group({
      chCodigo: [""],
      chNombrePromo: [""],
      chDescripcionNombre: [""],
      chTextoPantalla: [""],
      chTextoImpresion: [""],
      chTextoDisplay: [""],
      chTipoValidez: [""],
      iValidez: [""],
      iGrupoId: [""],
      iTipoProductoId: [""],
      iGrupoAsociadoId: [""],
      iTipoVisitaId: [""],
      iTipoNivelId: [""],
      iCategoriaId: [""],
      bALaVenta: [""],
      bImprimirPromo: [""],
      bImprimirPrecio: [""],
      chTeclaRapida: [""],
      iTEDatosId: [""],
    });

    this.formControlListaColumnas = new FormControl(this.displayedColumns);
    this.formControlListaFiltros = new FormControl(this.displayedFilters);
  }

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  get fFiltro() {
    return this.editorFiltrosForm.controls;
  } //Filtros

  get chCodigo() {
    return this.editorRegistroForm.get("chCodigo");
  }
  get chNombrePromo() {
    return this.editorRegistroForm.get("chNombrePromo");
  }
  get chDescripcionNombre() {
    return this.editorRegistroForm.get("chDescripcionNombre");
  }
  get chTextoPantalla() {
    return this.editorRegistroForm.get("chTextoPantalla");
  }
  get chTextoImpresion() {
    return this.editorRegistroForm.get("chTextoImpresion");
  }
  get chTextoDisplay() {
    return this.editorRegistroForm.get("chTextoDisplay");
  }
  get chTipoValidez() {
    return this.editorRegistroForm.get("chTipoValidez");
  }
  get iValidez() {
    return this.editorRegistroForm.get("iValidez");
  }
  get iGrupoId() {
    return this.editorRegistroForm.get("iGrupoId");
  }
  get iTipoProductoId() {
    return this.editorRegistroForm.get("iTipoProductoId");
  }
  get iGrupoAsociadoId() {
    return this.editorRegistroForm.get("iGrupoAsociadoId");
  }
  get iTipoVisitaId() {
    return this.editorRegistroForm.get("iTipoVisitaId");
  }
  get chTextiTipoVisitaIdoDisplay() {
    return this.editorRegistroForm.get("iTipoVisitaId");
  }
  get iTipoNivelId() {
    return this.editorRegistroForm.get("iTipoNivelId");
  }
  get iCategoriaId() {
    return this.editorRegistroForm.get("iCategoriaId");
  }
  get bALaVenta() {
    return this.editorRegistroForm.get("bALaVenta");
  }
  get bImprimirPromo() {
    return this.editorRegistroForm.get("bImprimirPromo");
  }
  get bImprimirPrecio() {
    return this.editorRegistroForm.get("bImprimirPrecio");
  }
  get chTeclaRapida() {
    return this.editorRegistroForm.get("chTeclaRapida");
  }
  get iTEDatosId() {
    return this.editorRegistroForm.get("iTEDatosId");
  }

  model = new traspasomodelo();

  AnadirRegistro() {
    this.model = new traspasomodelo();
    //Aseguramos que el ArrayForm esté vacío
    //this.promocionEntradaFormGroup.controls.forEach( e =>  { if (e instanceof FormGroup) this.promocionEntradaFormGroup.removeAt(0); });
    while (this.promocionEntradaFormGroup.length !== 0) {
      this.promocionEntradaFormGroup.removeAt(0);
    }
    this.setDataSourceTarifasPromocionEntrada();

    this.editando = true;
    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.recintosasociados = [];
    this.ordenRecintosAsociados = [];
    this.actividadesasociadas = [];
    this.ordenActividadesAsociadas = [];
    this.idBonoEnEdicion = "";
    this.elementosBorrados = [];

    this.f["bALaVenta"].setValue(false);
    this.f["bImprimirPromo"].setValue(false);
    this.f["bImprimirPrecio"].setValue(false);
  }

  async editar(item) {
    this.idsBonoEntradaASobreescribir = [];
    this.elementosBorrados = [];

    this.idBonoEnEdicion = item.pkId;
    this.formchanges = false;

    this.servicioAPI
      .ObtenerCustomanTipoBonoEntradas(item.pkId)
      .subscribe((data) => {
        this.tiposBonoEntradaAPI =
          new TiposPromocionEntrada_Respuesta().deserialize(data);
        if (this.tiposBonoEntradaAPI.DatosResult != null) {
          this.tiposBonoEntrada = this.tiposBonoEntradaAPI.DatosResult.lista;
          this.tiposBonoEntrada.forEach((element) => {
            this.idsBonoEntradaASobreescribir.push(element.iTipoBonoEntradasId);
          });

          this.dataSourceTarifaPromocionEntrada = new MatTableDataSource(
            this.tiposBonoEntrada
          );
          //this.dataSourceTarifaPromocionEntrada.paginator = this.paginator;
          //this.dataSourceTarifaPromocionEntrada.sort = this.sort;

          // crear form para promocionEntrada
          //this.promocionEntradaFormGroup = [];
          for (let i = 0; data.DatosResult.lista.length > i; i++) {
            if (data.DatosResult.lista[i].rPorcentaje == "") {
              data.DatosResult.lista[i].rPorcentaje = "0";
            }
            var promocionEntradaFormControls = new FormGroup({
              iTipoBonoEntradasId: new FormControl(
                data.DatosResult.lista[i].iTipoBonoEntradasId
              ),
              iTipoEntradaId: new FormControl(
                data.DatosResult.lista[i].iTipoEntradaId
              ),
              NombreTipoEntrada: new FormControl(
                data.DatosResult.lista[i].NombreTipoEntrada
              ),
              iNumEntradas: new FormControl(
                data.DatosResult.lista[i].iNumEntradas
              ),
              dtFechaInicio: new FormControl(
                this.fechaApiToFechaFront(
                  data.DatosResult.lista[i].dtFechaInicio
                )
              ),
              dtFechaFin: new FormControl(
                this.fechaApiToFechaFront(data.DatosResult.lista[i].dtFechaFin)
              ),
              rPorcentaje: new FormControl(
                data.DatosResult.lista[i].rPorcentaje
              ),
            });
            this.promocionEntradaFormGroup.controls.push(
              promocionEntradaFormControls
            );
          }
        } else {
          this.alertaServicio.error(
            this.tiposBonoEntradaAPI.Mensajes[0].DescripcionMensaje
          );
        }
        this.editorRegistroForm.valueChanges.subscribe((fchanges) => {
          if (this.editando && this.idBonoEnEdicion != "")
            this.formchanges = true;
        });
      });

    let objtraspaso = new traspasomodelo();

    objtraspaso.pkId = item.pkId;
    objtraspaso.chCodigo = item.chCodigo;
    objtraspaso.chNombrePromo = item.chNombrePromo;
    objtraspaso.chDescripcionNombre = item.chDescripcionNombre;
    objtraspaso.chTextoPantalla = item.chTextoPantalla;
    objtraspaso.chTextoImpresion = item.chTextoImpresion;
    objtraspaso.chTextoDisplay = item.chTextoDisplay;
    objtraspaso.chTipoValidez = item.chTipoValidez;
    objtraspaso.iValidez = item.iValidez;
    objtraspaso.iGrupoId = item.iGrupoId;
    objtraspaso.iTipoProductoId = item.iTipoProductoId;
    objtraspaso.iGrupoAsociadoId = item.iGrupoAsociadoId;
    objtraspaso.iTipoVisitaId = item.iTipoVisitaId;
    objtraspaso.iTipoNivelId = item.iTipoNivelId;
    objtraspaso.iCategoriaId = item.iCategoriaId;
    objtraspaso.bALaVenta = item.bALaVenta == "1" ? true : false;
    objtraspaso.bImprimirPromo = item.bImprimirPromo == "1" ? true : false;
    objtraspaso.bImprimirPrecio = item.bImprimirPrecio == "1" ? true : false;
    objtraspaso.chTeclaRapida = item.chTeclaRapida;
    objtraspaso.iTEDatosId = item.iTEDatosId;

    this.f["bALaVenta"].setValue(objtraspaso.bALaVenta);
    this.f["bImprimirPromo"].setValue(objtraspaso.bImprimirPromo);
    this.f["bImprimirPrecio"].setValue(objtraspaso.bImprimirPrecio);

    this.model = objtraspaso;
    let tmprecintos = this.separarOrdenDeRecinto(
      item.RecintosAsociados != null && item.RecintosAsociados.trim() != ""
        ? item.RecintosAsociados.split(",")
        : []
    );
    this.recintosasociados = tmprecintos.valor;
    this.ordenRecintosAsociados = tmprecintos.orden;
    let tmpactividades = this.separarOrdenDeRecinto(
      item.ActividadesAsociadas != null &&
        item.ActividadesAsociadas.trim() != ""
        ? item.ActividadesAsociadas.split(",")
        : []
    );
    this.actividadesasociadas = tmpactividades.valor;
    this.ordenActividadesAsociadas = tmpactividades.orden;

    this.editando = true;

    this.alertaServicio.limpiar();
  }

  separaRecintos(conjunto: string): string[] {
    let respuesta: string[] =
      conjunto != null && conjunto.trim() != "" ? conjunto.split(",") : [];
    return respuesta;
  }

  separarOrdenDeRecinto(conjunto: string[]): {
    valor: string[];
    orden: string[];
  } {
    let rValor: string[] = [];
    let rOrden: string[] = [];
    conjunto.forEach((element) => {
      let elemento = element.split(":");
      if (elemento.length > 0) {
        if ((elemento.length = 2)) {
          rValor.push(elemento[0]);
          rOrden.push(elemento[1] == "0" ? null : elemento[1]);
        } else {
          rValor.push(elemento[0]);
          rOrden.push(null);
        }
      }
    });
    return { valor: rValor, orden: rOrden };
  }

  guardar() {
    let datosPromocionEntrada = [];
    let bonodatos = {
      pkId: this.idBonoEnEdicion,
      chCodigo: this.chCodigo.value,
      chNombrePromo: this.chNombrePromo.value,
      chDescripcionNombre: this.chDescripcionNombre.value,
      chTextoPantalla: this.chTextoPantalla.value,
      chTextoImpresion: this.chTextoImpresion.value,
      chTextoDisplay: this.chTextoDisplay.value,
      chTipoValidez: this.chTipoValidez.value,
      iValidez: this.iValidez.value,
      iGrupoId: this.iGrupoId.value,
      iTipoProductoId: this.iTipoProductoId.value,
      iGrupoAsociadoId: this.iGrupoAsociadoId.value,
      iTipoVisitaId: this.iTipoVisitaId.value,
      iTipoNivelId: this.iTipoNivelId.value,
      iCategoriaId: this.iCategoriaId.value,
      bALaVenta: this.bALaVenta.value == true ? 1 : 0,
      bImprimirPromo: this.bImprimirPromo.value == true ? 1 : 0,
      bImprimirPrecio: this.bImprimirPrecio.value == true ? 1 : 0,
      chTeclaRapida: this.chTeclaRapida.value,
      iTEDatosId: this.iTEDatosId.value,
    };

    if (bonodatos.pkId != "") bonodatos["Funcion"] = "U";
    else bonodatos["Funcion"] = "I";

    if (this.validateTable(this.promocionEntradaFormGroup)) {
      this.tablavalida = true;
      this._cds.detectChanges();
    } else {
      this.tablavalida = false;
      this._cds.detectChanges();
    }

    if (this.promocionEntradaFormGroup.length !== 0) {
      let porcentajeTotal = 0;
      this.promocionEntradaFormGroup.controls.forEach((item) => {
        if (item.value.rPorcentaje == "") item.value.rPorcentaje = 0;
        if (item instanceof FormGroup)
          porcentajeTotal += parseInt(item.value.rPorcentaje);
      });

      if (porcentajeTotal > 0 && porcentajeTotal === 100) {
        this.promocionEntradaFormGroup.controls.forEach((element) => {
          if (element instanceof FormGroup)
            datosPromocionEntrada.push(element.value);
        });

        //let iTipoBonoId = ""this.idBonoEnEdicion"";
        let reaplicarfiltros = false;

        //preparamos los datos para una sola llamada:
        bonodatos["Entradas"] = [];
        datosPromocionEntrada.forEach((entrada) => {
          let lineaEntrada = {
            dtFechaFin: this.fechaFrontToFechaApi(entrada.dtFechaFin),
            dtFechaInicio: this.fechaFrontToFechaApi(entrada.dtFechaInicio),
            iNumEntradas: entrada.iNumEntradas,
            iTipoBonoId: bonodatos.pkId,
            iTipoEntradaId: entrada.iTipoEntradaId,
            ipkId: entrada.iTipoBonoEntradasId,
            rPorcentaje: entrada.rPorcentaje,
          };
          bonodatos["Entradas"].push(lineaEntrada);
          //falta TipoBono y pkId
        });
        //Guardar datos Bono en una sola llamada

        this.servicioAPI
          .InsUpdTipoBonoCompleto(bonodatos)
          .subscribe((result) => {
            //Borrar tabla
            while (this.promocionEntradaFormGroup.length !== 0) {
              this.promocionEntradaFormGroup.removeAt(0);
            }
            this.setDataSourceTarifasPromocionEntrada();
            this.AplicarFiltros();
            this.elementosBorrados = [];
            this.editando = false;
          });

        /*
        //Guardar datos Bono en distintas llamadas
        //prepara los datos        
        this.servicioAPI.InsUpdTipoBono(this.idBonoEnEdicion,bonodatos).subscribe(result => {
          //Borrado
             
          
          if (this.idsBonoEntradaASobreescribir && this.idsBonoEntradaASobreescribir.length > 0) {
            this.idsBonoEntradaASobreescribir.forEach(id => {
              if (this.elementosBorrados.includes(id))
                this.servicioAPI.DelTipoBonoEntradas(id).subscribe(res => {
                  if (!res.DatosResult) {
                    this._snackBar.open("Imposible eliminar entrada...", "Aceptar", {
                      duration: 1500,
                      panelClass: ['blue-snackbar'],
                      horizontalPosition: 'center',
                      verticalPosition: 'bottom'
                    });
                  }
                  
                });

            });
          }
          
          //Guardar tabla
          datosPromocionEntrada.forEach(entrada => {
            entrada.dtFechaInicio = this.fechaFrontToFechaApi(entrada.dtFechaInicio);
            entrada.dtFechaFin = this.fechaFrontToFechaApi(entrada.dtFechaFin);
            if (!this.idBonoEnEdicion || this.idBonoEnEdicion=="") {
              this.idBonoEnEdicion = result.DatosResult;
            }

            
            this.servicioAPI.InsUpdTipoBonoEntradas(this.idBonoEnEdicion, entrada).subscribe(res => {
              //Borrar tabla
              while (this.promocionEntradaFormGroup.length !== 0) {
                this.promocionEntradaFormGroup.removeAt(0)
              }

              this.setDataSourceTarifasPromocionEntrada();
              this.AplicarFiltros();
              this.elementosBorrados = [];
              this.editando = false;
              
            })

          });
        });



      //}   
*/
      } else {
        // Porcentaje erroneo
        const dialogRef = this.dialog.open(DialogoPopUp, {
          width: "250px",
          data: {
            titulo: this.translation.PorcentajeIncorrecto,
            texto: this.translation.ElPorcentajeHaDeSerDelCienPorciento,
            ok: this.translation.Confirmar,
          },
        });
      }
    } else {
      // no hay entradas asociadas a la promocion
      const dialogRef = this.dialog.open(DialogoPopUp, {
        width: "250px",
        data: {
          titulo: this.translation.NoHayEntradas,
          texto: this.translation.UnaPromocionHaDeTenerMinimoUnaEntradaAsociada,
          ok: this.translation.Confirmar,
        },
      });
    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.pkId != null) {
      //Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo =
        this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog();
    }
  }

  async applyFilter(filterValue: string) {
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (
        this.seleccion.selected == null ||
        this.seleccion.selected.length == 0
      ) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo =
          this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
      } else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo =
          this.translation.VaAProcederAlBorradoDe +
          this.seleccion.selected.length.toString() +
          this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas();
      }
    }
  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible;
    //Cambiar el boton
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {
      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(
            this.columnsToDisplay.findIndex((x) => x === evento.option.value),
            1
          );
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex((x) => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
      }
    }
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(
            this.filtersToDisplay.findIndex((x) => x === evento.option.value),
            1
          );
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex((x) => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
      }
    }
  }

  traeNombreTipoControlxID(id) {
    let respuesta = this.tiposTiposControl.filter(
      (x) => x.CodigoTipoControl == id
    );
    return respuesta == null || respuesta.length == 0
      ? null
      : respuesta[0].Nombre;
  }

  traeNombreImpresoraxID(id) {
    let respuesta = this.globales.impresorasSeleccionables.filter(
      (x) => x.id == id
    );
    return respuesta == null || respuesta.length == 0
      ? null
      : respuesta[0].value;
  }
  traeNombreTipoButacaxID(id) {
    //WOIT
    //let respuesta = this.tiposTiposButaca.filter(x=> x.id == id)
    //return respuesta == null || respuesta.length == 0? null: respuesta[0].Nombre;
    return "";
  }

  compruebadrops(): boolean {
    if (this.model.iGrupoId == null) {
      return false;
    } else {
      return this.editorRegistroForm.valid;
    }
  }
  // compruebaPorDefecto(valor:string)
  // {
  //   if(!(valor.trim().toLowerCase() == "true" || valor.trim().toLowerCase() == "1")){
  //   return this.dataSource != null && this.dataSource.data != null && this.dataSource.data.length != 0 &&
  //   this.dataSource.data.some(x => x.PorDefecto.trim() == "1" || x.PorDefecto.toLocaleLowerCase().trim()  == "true")}
  //   else {return false;}
  // }

  cambiaNombre(evento) {
    //Si los modelos de Display, Taquilla y Imprimir estan vacios les pongo el nombre del evento.
    if (
      this.model.chTextoDisplay == null ||
      this.model.chTextoDisplay.trim() == ""
    ) {
      this.model.chTextoDisplay = evento.target.value;
    }
    if (
      this.model.chTextoPantalla == null ||
      this.model.chTextoPantalla.trim() == ""
    ) {
      this.model.chTextoPantalla = evento.target.value;
    }
    if (
      this.model.chTextoImpresion == null ||
      this.model.chTextoImpresion.trim() == ""
    ) {
      this.model.chTextoImpresion = evento.target.value;
    }
  }

  cambiaActividadTaller(evento) {
    //this.CargarDatosTiposEntradas(evento.target.checked?2:1,this.model.PkId);
    this.tallerActividad = evento.target.checked;
    if (this.tallerActividad) {
      this.tiposTipoPromocionTaller = this.tiposTipoPromocionRecinto;
      this.tiposTipoPromocionRecinto = [];
    } else {
      this.tiposTipoPromocionRecinto = this.tiposTipoPromocionTaller;
      this.tiposTipoPromocionTaller = [];
    }
  }
  cambiaButacas(evento) {
    this.butacasCheck = evento.target.checked;
  }

  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();
    /*
    if (this.dataSource != null && this.dataSource.data != null) {
      if (filtro != null) {

        if (filtro.chCodigo != null && filtro.chCodigo.trim() != "") {
          this.filtrosactivosModelo.chCodigo = filtro.chCodigo.trim();
        }
        this.fFiltro["chCodigo"].setValue(filtro.chCodigo.trim());

        if (filtro.chNombrePromo != null && filtro.chNombrePromo.trim() != "") {
          this.filtrosactivosModelo.chNombrePromo = filtro.chNombrePromo.trim();
        }
        this.fFiltro["chNombrePromo"].setValue(filtro.chNombrePromo.trim());

        if (filtro.chDescripcionNombre != null && filtro.chDescripcionNombre.trim() != "") {
          this.filtrosactivosModelo.chDescripcionNombre = filtro.chDescripcionNombre.trim();
        }
        this.fFiltro["chDescripcionNombre"].setValue(filtro.chDescripcionNombre.trim());

        if (filtro.chTextoPantalla != null && filtro.chTextoPantalla.trim() != "") {
          this.filtrosactivosModelo.chTextoPantalla = filtro.chTextoPantalla.trim();
        }
        this.fFiltro["chTextoPantalla"].setValue(filtro.chTextoPantalla.trim());

        if (filtro.chTextoImpresion != null && filtro.chTextoImpresion.trim() != "") {
          this.filtrosactivosModelo.chTextoImpresion = filtro.chTextoImpresion.trim();
        }
        this.fFiltro["chTextoImpresion"].setValue(filtro.chTextoImpresion.trim());

        if (filtro.chTextoDisplay != null && filtro.chTextoDisplay.trim() != "") {
          this.filtrosactivosModelo.chTextoDisplay = filtro.chTextoDisplay.trim();
        }
        this.fFiltro["chTextoDisplay"].setValue(filtro.chTextoDisplay.trim());

        if (filtro.chTipoValidez != null && filtro.chTipoValidez.trim() != "") {
          this.filtrosactivosModelo.chTipoValidez = filtro.chTipoValidez.trim();
        }
        this.fFiltro["chTipoValidez"].setValue(filtro.chTipoValidez.trim());

        if (filtro.iValidez != null && filtro.iValidez.trim() != "") {
          this.filtrosactivosModelo.iValidez = filtro.iValidez.trim();
        }
        this.fFiltro["iValidez"].setValue(filtro.iValidez.trim());

        if (filtro.iGrupoId != null && filtro.iGrupoId.trim() != "") {
          this.filtrosactivosModelo.iGrupoId = filtro.iGrupoId.trim();
        }
        this.fFiltro["iGrupoId"].setValue(filtro.iGrupoId.trim());

        if (filtro.iTipoProductoId != null && filtro.iTipoProductoId.trim() != "") {
          this.filtrosactivosModelo.iTipoProductoId = filtro.iTipoProductoId.trim();
        }
        this.fFiltro["iTipoProductoId"].setValue(filtro.iTipoProductoId.trim());

        if (filtro.iGrupoAsociadoId != null && filtro.iGrupoAsociadoId.trim() != "") {
          this.filtrosactivosModelo.iGrupoAsociadoId = filtro.iGrupoAsociadoId.trim();
        }
        this.fFiltro["iGrupoAsociadoId"].setValue(filtro.iGrupoAsociadoId.trim());

        if (filtro.iTipoVisitaId != null && filtro.iTipoVisitaId.trim() != "") {
          this.filtrosactivosModelo.iTipoVisitaId = filtro.iTipoVisitaId.trim();
        }
        this.fFiltro["iTipoVisitaId"].setValue(filtro.iTipoVisitaId.trim());

        if (filtro.iTipoNivelId != null && filtro.iTipoNivelId.trim() != "") {
          this.filtrosactivosModelo.iTipoNivelId = filtro.iTipoNivelId.trim();
        }
        this.fFiltro["iTipoNivelId"].setValue(filtro.iTipoNivelId.trim());

        if (filtro.iCategoriaId != null && filtro.iCategoriaId.trim() != "") {
          this.filtrosactivosModelo.iCategoriaId = filtro.iCategoriaId.trim();
        }
        this.fFiltro["iCategoriaId"].setValue(filtro.iCategoriaId.trim());

        if (filtro.bALaVenta != null && filtro.bALaVenta.trim() != "") {
          this.filtrosactivosModelo.bALaVenta = filtro.bALaVenta.trim();
        }
        this.fFiltro["bALaVenta"].setValue(filtro.bALaVenta.trim());

        if (filtro.bImprimirPromo != null && filtro.bImprimirPromo.trim() != "") {
          this.filtrosactivosModelo.bImprimirPromo = filtro.bImprimirPromo.trim();
        }
        this.fFiltro["bImprimirPromo"].setValue(filtro.bImprimirPromo.trim());

        if (filtro.bImprimirPrecio != null && filtro.bImprimirPrecio.trim() != "") {
          this.filtrosactivosModelo.bImprimirPrecio = filtro.bImprimirPrecio.trim();
        }
        this.fFiltro["bImprimirPrecio"].setValue(filtro.bImprimirPrecio.trim());

        if (filtro.chTeclaRapida != null && filtro.chTeclaRapida.trim() != "") {
          this.filtrosactivosModelo.chTeclaRapida = filtro.chTeclaRapida.trim();
        }
        this.fFiltro["chTeclaRapida"].setValue(filtro.chTeclaRapida.trim());

        if (filtro.iTEDatosId != null && filtro.iTEDatosId.trim() != "") {
          this.filtrosactivosModelo.iTEDatosId = filtro.iTEDatosId.trim();
        }
        this.fFiltro["iTEDatosId"].setValue(filtro.iTEDatosId.trim());

      }
      else {

        if (this.fFiltro["chCodigo"].value != null && this.fFiltro["chCodigo"].value != "") {
          this.filtrosactivosModelo.chCodigo = this.fFiltro["chCodigo"].value;
          this.fFiltro["chCodigo"].reset();
        }

        if (this.fFiltro["chNombrePromo"].value != null && this.fFiltro["chNombrePromo"].value != "") {
          this.filtrosactivosModelo.chNombrePromo = this.fFiltro["chNombrePromo"].value;
          this.fFiltro["chNombrePromo"].reset();
        }

        if (this.fFiltro["chDescripcionNombre"].value != null && this.fFiltro["chDescripcionNombre"].value != "") {
          this.filtrosactivosModelo.chDescripcionNombre = this.fFiltro["chDescripcionNombre"].value;
          this.fFiltro["chDescripcionNombre"].reset();
        }

        if (this.fFiltro["chTextoPantalla"].value != null && this.fFiltro["chTextoPantalla"].value != "") {
          this.filtrosactivosModelo.chTextoPantalla = this.fFiltro["chTextoPantalla"].value;
          this.fFiltro["chTextoPantalla"].reset();
        }

        if (this.fFiltro["chTextoImpresion"].value != null && this.fFiltro["chTextoImpresion"].value != "") {
          this.filtrosactivosModelo.chTextoImpresion = this.fFiltro["chTextoImpresion"].value;
          this.fFiltro["chTextoImpresion"].reset();
        }

        if (this.fFiltro["chTextoDisplay"].value != null && this.fFiltro["chTextoDisplay"].value != "") {
          this.filtrosactivosModelo.chTextoDisplay = this.fFiltro["chTextoDisplay"].value;
          this.fFiltro["chTextoDisplay"].reset();
        }

        if (this.fFiltro["chTipoValidez"].value != null && this.fFiltro["chTipoValidez"].value != "") {
          this.filtrosactivosModelo.chTipoValidez = this.fFiltro["chTipoValidez"].value;
          this.fFiltro["chTipoValidez"].reset();
        }

        if (this.fFiltro["iValidez"].value != null && this.fFiltro["iValidez"].value != "") {
          this.filtrosactivosModelo.iValidez = this.fFiltro["iValidez"].value;
          this.fFiltro["iValidez"].reset();
        }

        if (this.fFiltro["iGrupoId"].value != null && this.fFiltro["iGrupoId"].value != "") {
          this.filtrosactivosModelo.iGrupoId = this.fFiltro["iGrupoId"].value;
          this.fFiltro["iGrupoId"].reset();
        }

        if (this.fFiltro["iTipoProductoId"].value != null && this.fFiltro["iTipoProductoId"].value != "") {
          this.filtrosactivosModelo.iTipoProductoId = this.fFiltro["iTipoProductoId"].value;
          this.fFiltro["iTipoProductoId"].reset();
        }

        if (this.fFiltro["iGrupoAsociadoId"].value != null && this.fFiltro["iGrupoAsociadoId"].value != "") {
          this.filtrosactivosModelo.iGrupoAsociadoId = this.fFiltro["iGrupoAsociadoId"].value;
          this.fFiltro["iGrupoAsociadoId"].reset();
        }

        if (this.fFiltro["iTipoVisitaId"].value != null && this.fFiltro["iTipoVisitaId"].value != "") {
          this.filtrosactivosModelo.iTipoVisitaId = this.fFiltro["iTipoVisitaId"].value;
          this.fFiltro["iTipoVisitaId"].reset();
        }

        if (this.fFiltro["iTipoNivelId"].value != null && this.fFiltro["iTipoNivelId"].value != "") {
          this.filtrosactivosModelo.iTipoNivelId = this.fFiltro["iTipoNivelId"].value;
          this.fFiltro["iTipoNivelId"].reset();
        }

        if (this.fFiltro["iCategoriaId"].value != null && this.fFiltro["iCategoriaId"].value != "") {
          this.filtrosactivosModelo.iCategoriaId = this.fFiltro["iCategoriaId"].value;
          this.fFiltro["iCategoriaId"].reset();
        }

        if (this.fFiltro["bALaVenta"].value != null && this.fFiltro["bALaVenta"].value != "") {
          this.filtrosactivosModelo.bALaVenta = this.fFiltro["bALaVenta"].value;
          this.fFiltro["bALaVenta"].reset();
        }

        if (this.fFiltro["bImprimirPromo"].value != null && this.fFiltro["bImprimirPromo"].value != "") {
          this.filtrosactivosModelo.bImprimirPromo = this.fFiltro["bImprimirPromo"].value;
          this.fFiltro["bImprimirPromo"].reset();
        }

        if (this.fFiltro["bImprimirPrecio"].value != null && this.fFiltro["bImprimirPrecio"].value != "") {
          this.filtrosactivosModelo.bImprimirPrecio = this.fFiltro["bImprimirPrecio"].value;
          this.fFiltro["bImprimirPrecio"].reset();
        }

        if (this.fFiltro["chTeclaRapida"].value != null && this.fFiltro["chTeclaRapida"].value != "") {
          this.filtrosactivosModelo.chTeclaRapida = this.fFiltro["chTeclaRapida"].value;
          this.fFiltro["chTeclaRapida"].reset();
        }

        if (this.fFiltro["iTEDatosId"].value != null && this.fFiltro["iTEDatosId"].value != "") {
          this.filtrosactivosModelo.iTEDatosId = this.fFiltro["iTEDatosId"].value;
          this.fFiltro["iTEDatosId"].reset();
        }

      }
      
    }

    await this.cargarDatosAsync().then(
      respuesta => {
        let siono = new Boolean(respuesta);
        if (siono) {
          //let datostemporales = this.dataSource.data;
          //this.dataSource.data = datostemporales;

          // if (this.filtrosactivosModelo.textoGeneral != null && this.filtrosactivosModelo.textoGeneral.trim() != "") {
          //   this.dataSource.filter = this.filtrosactivosModelo.textoGeneral.trim().toLowerCase();
          // }
          // else {
          //   //Solo borramos el input text, el filtro se actualiza Ok
          //   //this.filtrogeneral.nativeElement.value = '';
          // }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

          if (this.dataSource && this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        }
        else {
          //No hemos podido cargar los datos
        }

      },
      error => {
        //No hemos podido cargar los datos
      });
    //Cargamos la Botonera Fuera del principal
    await this.cargarBotonera().then(
      respuesta => { },
      error => {
      });
      */

    this.alertaServicio.limpiar();
    //CArgamos los datos de muevo solo porsiacaso
    await this.cargarDatosAsync().then(
      (respuesta) => {
        let siono = new Boolean(respuesta);
        //
        if (siono) {
          let datostemporales = this.dataSource.data;
          //aplicamos los filtros sobre los datos temporales
          if (filtro != null) {
            if (filtro.chCodigo != null && filtro.chCodigo.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.chCodigo.indexOf(filtro.chCodigo.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chCodigo = filtro.chCodigo.trim();
            }
            this.fFiltro["chCodigo"].setValue(filtro.chCodigo.trim());

            if (
              filtro.chNombrePromo != null &&
              filtro.chNombrePromo.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) => x.chNombrePromo.indexOf(filtro.chNombrePromo.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chNombrePromo =
                filtro.chNombrePromo.trim();
            }
            this.fFiltro["chNombrePromo"].setValue(filtro.chNombrePromo.trim());

            if (
              filtro.chDescripcionNombre != null &&
              filtro.chDescripcionNombre.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chDescripcionNombre.indexOf(
                    filtro.chDescripcionNombre.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chDescripcionNombre =
                filtro.chDescripcionNombre.trim();
            }
            this.fFiltro["chDescripcionNombre"].setValue(
              filtro.chDescripcionNombre.trim()
            );

            if (
              filtro.chTextoPantalla != null &&
              filtro.chTextoPantalla.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chTextoPantalla.indexOf(filtro.chTextoPantalla.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTextoPantalla =
                filtro.chTextoPantalla.trim();
            }
            this.fFiltro["chTextoPantalla"].setValue(
              filtro.chTextoPantalla.trim()
            );

            if (
              filtro.chTextoImpresion != null &&
              filtro.chTextoImpresion.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chTextoImpresion.indexOf(filtro.chTextoImpresion.trim()) >=
                  0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTextoImpresion =
                filtro.chTextoImpresion.trim();
            }
            this.fFiltro["chTextoImpresion"].setValue(
              filtro.chTextoImpresion.trim()
            );

            if (
              filtro.chTextoDisplay != null &&
              filtro.chTextoDisplay.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chTextoDisplay.indexOf(filtro.chTextoDisplay.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTextoDisplay =
                filtro.chTextoDisplay.trim();
            }
            this.fFiltro["chTextoDisplay"].setValue(
              filtro.chTextoDisplay.trim()
            );

            if (
              filtro.chTipoValidez != null &&
              filtro.chTipoValidez.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) => x.chTipoValidez.indexOf(filtro.chTipoValidez.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTipoValidez =
                filtro.chTipoValidez.trim();
            }
            this.fFiltro["chTipoValidez"].setValue(filtro.chTipoValidez.trim());

            if (filtro.iValidez != null && filtro.iValidez.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.iValidez.indexOf(filtro.iValidez.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iValidez = filtro.iValidez.trim();
            }
            this.fFiltro["iValidez"].setValue(filtro.iValidez.trim());

            if (filtro.iGrupoId != null && filtro.iGrupoId.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.iGrupoId.indexOf(filtro.iGrupoId.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iGrupoId = filtro.iGrupoId.trim();
            }
            this.fFiltro["iGrupoId"].setValue(filtro.iGrupoId.trim());

            if (
              filtro.iTipoProductoId != null &&
              filtro.iTipoProductoId.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iTipoProductoId.indexOf(filtro.iTipoProductoId.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iTipoProductoId =
                filtro.iTipoProductoId.trim();
            }
            this.fFiltro["iTipoProductoId"].setValue(
              filtro.iTipoProductoId.trim()
            );

            if (
              filtro.iGrupoAsociadoId != null &&
              filtro.iGrupoAsociadoId.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iGrupoAsociadoId.indexOf(filtro.iGrupoAsociadoId.trim()) >=
                  0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iGrupoAsociadoId =
                filtro.iGrupoAsociadoId.trim();
            }
            this.fFiltro["iGrupoAsociadoId"].setValue(
              filtro.iGrupoAsociadoId.trim()
            );

            if (
              filtro.iTipoVisitaId != null &&
              filtro.iTipoVisitaId.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) => x.iTipoVisitaId.indexOf(filtro.iTipoVisitaId.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iTipoVisitaId =
                filtro.iTipoVisitaId.trim();
            }
            this.fFiltro["iTipoVisitaId"].setValue(filtro.iTipoVisitaId.trim());

            if (
              filtro.iTipoNivelId != null &&
              filtro.iTipoNivelId.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) => x.iTipoNivelId.indexOf(filtro.iTipoNivelId.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iTipoNivelId =
                filtro.iTipoNivelId.trim();
            }
            this.fFiltro["iTipoNivelId"].setValue(filtro.iTipoNivelId.trim());

            if (
              filtro.iCategoriaId != null &&
              filtro.iCategoriaId.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) => x.iCategoriaId.indexOf(filtro.iCategoriaId.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iCategoriaId =
                filtro.iCategoriaId.trim();
            }
            this.fFiltro["iCategoriaId"].setValue(filtro.iCategoriaId);

            if (filtro.bALaVenta != null && filtro.bALaVenta.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.bALaVenta.indexOf(filtro.bALaVenta.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.bALaVenta = filtro.bALaVenta.trim();
            }
            this.fFiltro["bALaVenta"].setValue(filtro.bALaVenta.trim());

            if (
              filtro.bImprimirPromo != null &&
              filtro.bImprimirPromo.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.bImprimirPromo.indexOf(filtro.bImprimirPromo.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.bImprimirPromo =
                filtro.bImprimirPromo.trim();
            }
            this.fFiltro["bImprimirPromo"].setValue(
              filtro.bImprimirPromo.trim()
            );

            if (
              filtro.bImprimirPrecio != null &&
              filtro.bImprimirPrecio.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.bImprimirPrecio.indexOf(filtro.bImprimirPrecio.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.bImprimirPrecio =
                filtro.bImprimirPrecio.trim();
            }
            this.fFiltro["bImprimirPrecio"].setValue(
              filtro.bImprimirPrecio.trim()
            );

            if (
              filtro.chTeclaRapida != null &&
              filtro.chTeclaRapida.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) => x.chTeclaRapida.indexOf(filtro.chTeclaRapida.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTeclaRapida =
                filtro.chTeclaRapida.trim();
            }
            this.fFiltro["chTeclaRapida"].setValue(filtro.chTeclaRapida.trim());

            if (filtro.iTEDatosId != null && filtro.iTEDatosId.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.iTEDatosId.indexOf(filtro.iTEDatosId.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iTEDatosId = filtro.iTEDatosId.trim();
            }
            this.fFiltro["iTEDatosId"].setValue(filtro.iTEDatosId.trim());
          } else {
            if (
              this.fFiltro["chCodigo"].value != null &&
              this.fFiltro["chCodigo"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chCodigo.indexOf(this.fFiltro["chCodigo"].value.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chCodigo =
                this.fFiltro["chCodigo"].value;
            }
            if (
              this.fFiltro["chNombrePromo"].value != null &&
              this.fFiltro["chNombrePromo"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chNombrePromo.indexOf(
                    this.fFiltro["chNombrePromo"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chNombrePromo =
                this.fFiltro["chNombrePromo"].value;
            }
            if (
              this.fFiltro["chDescripcionNombre"].value != null &&
              this.fFiltro["chDescripcionNombre"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chDescripcionNombre.indexOf(
                    this.fFiltro["chDescripcionNombre"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chDescripcionNombre =
                this.fFiltro["chDescripcionNombre"].value;
            }
            if (
              this.fFiltro["chTextoPantalla"].value != null &&
              this.fFiltro["chTextoPantalla"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chTextoPantalla.indexOf(
                    this.fFiltro["chTextoPantalla"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTextoPantalla =
                this.fFiltro["chTextoPantalla"].value;
            }
            if (
              this.fFiltro["chTextoImpresion"].value != null &&
              this.fFiltro["chTextoImpresion"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chTextoImpresion.indexOf(
                    this.fFiltro["chTextoImpresion"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTextoImpresion =
                this.fFiltro["chTextoImpresion"].value;
            }
            if (
              this.fFiltro["chTextoDisplay"].value != null &&
              this.fFiltro["chTextoDisplay"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chTextoDisplay.indexOf(
                    this.fFiltro["chTextoDisplay"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTextoDisplay =
                this.fFiltro["chTextoDisplay"].value;
            }
            if (
              this.fFiltro["chTipoValidez"].value != null &&
              this.fFiltro["chTipoValidez"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chTipoValidez.indexOf(
                    this.fFiltro["chTipoValidez"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTipoValidez =
                this.fFiltro["chTipoValidez"].value;
            }
            if (
              this.fFiltro["iValidez"].value != null &&
              this.fFiltro["iValidez"].value != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) => x.iValidez.indexOf(this.fFiltro["iValidez"].value) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iValidez =
                this.fFiltro["iValidez"].value;
            }
            if (
              this.fFiltro["iGrupoId"].value != null &&
              this.fFiltro["iGrupoId"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iGrupoId.indexOf(this.fFiltro["iGrupoId"].value.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iGrupoId =
                this.fFiltro["iGrupoId"].value;
            }
            if (
              this.fFiltro["iTipoProductoId"].value != null &&
              this.fFiltro["iTipoProductoId"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iTipoProductoId.indexOf(
                    this.fFiltro["iTipoProductoId"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iTipoProductoId =
                this.fFiltro["iTipoProductoId"].value;
            }
            if (
              this.fFiltro["iGrupoAsociadoId"].value != null &&
              this.fFiltro["iGrupoAsociadoId"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iGrupoAsociadoId.indexOf(
                    this.fFiltro["iGrupoAsociadoId"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iGrupoAsociadoId =
                this.fFiltro["iGrupoAsociadoId"].value;
            }
            if (
              this.fFiltro["iTipoVisitaId"].value != null &&
              this.fFiltro["iTipoVisitaId"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iTipoVisitaId.indexOf(
                    this.fFiltro["iTipoVisitaId"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iTipoVisitaId =
                this.fFiltro["iTipoVisitaId"].value;
            }
            if (
              this.fFiltro["iTipoNivelId"].value != null &&
              this.fFiltro["iTipoNivelId"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iTipoNivelId.indexOf(
                    this.fFiltro["iTipoNivelId"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iTipoNivelId =
                this.fFiltro["iTipoNivelId"].value;
            }
            if (
              this.fFiltro["iCategoriaId"].value != null &&
              this.fFiltro["iCategoriaId"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iCategoriaId.indexOf(
                    this.fFiltro["iCategoriaId"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iCategoriaId =
                this.fFiltro["iCategoriaId"].value;
            }
            if (
              this.fFiltro["bALaVenta"].value != null &&
              this.fFiltro["bALaVenta"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.bALaVenta.indexOf(this.fFiltro["bALaVenta"].value.trim()) >=
                  0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.bALaVenta =
                this.fFiltro["bALaVenta"].value;
            }
            if (
              this.fFiltro["bImprimirPromo"].value != null &&
              this.fFiltro["bImprimirPromo"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.bImprimirPromo.indexOf(
                    this.fFiltro["bImprimirPromo"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.bImprimirPromo =
                this.fFiltro["bImprimirPromo"].value;
            }
            if (
              this.fFiltro["bImprimirPrecio"].value != null &&
              this.fFiltro["bImprimirPrecio"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.bImprimirPrecio.indexOf(
                    this.fFiltro["bImprimirPrecio"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.bImprimirPrecio =
                this.fFiltro["bImprimirPrecio"].value;
            }
            if (
              this.fFiltro["chTeclaRapida"].value != null &&
              this.fFiltro["chTeclaRapida"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.chTeclaRapida.indexOf(
                    this.fFiltro["chTeclaRapida"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.chTeclaRapida =
                this.fFiltro["chTeclaRapida"].value;
            }
            if (
              this.fFiltro["iTEDatosId"].value != null &&
              this.fFiltro["iTEDatosId"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.iTEDatosId.indexOf(
                    this.fFiltro["iTEDatosId"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.iTEDatosId =
                this.fFiltro["iTEDatosId"].value;
            }
          }

          this.dataSource.data = datostemporales;

          if (
            this.filtrosactivosModelo.textoGeneral != null &&
            this.filtrosactivosModelo.textoGeneral.trim() != ""
          ) {
            this.dataSource.filter = this.filtrosactivosModelo.textoGeneral
              .trim()
              .toLowerCase();
          } else {
            //Solo borramos el input text, el filtro se actualiza Ok
            //this.filtrogeneral.nativeElement.value = '';
            //
          }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
          //No hemos podido cargar los datos
        }
      },
      (error) => {
        //No hemos podido cargar los datos
      }
    );
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.chCodigo =
      filtro == null || filtro == "chCodigo"
        ? ""
        : this.filtrosactivosModelo.chCodigo;
    this.filtrosactivosModelo.chNombrePromo =
      filtro == null || filtro == "chNombrePromo"
        ? ""
        : this.filtrosactivosModelo.chNombrePromo;
    this.filtrosactivosModelo.chDescripcionNombre =
      filtro == null || filtro == "chDescripcionNombre"
        ? ""
        : this.filtrosactivosModelo.chDescripcionNombre;
    this.filtrosactivosModelo.chTextoPantalla =
      filtro == null || filtro == "chTextoPantalla"
        ? ""
        : this.filtrosactivosModelo.chTextoPantalla;
    this.filtrosactivosModelo.chTextoImpresion =
      filtro == null || filtro == "chTextoImpresion"
        ? ""
        : this.filtrosactivosModelo.chTextoImpresion;
    this.filtrosactivosModelo.chTextoDisplay =
      filtro == null || filtro == "chTextoDisplay"
        ? ""
        : this.filtrosactivosModelo.chTextoDisplay;
    this.filtrosactivosModelo.chTipoValidez =
      filtro == null || filtro == "chTipoValidez"
        ? ""
        : this.filtrosactivosModelo.chTipoValidez;
    this.filtrosactivosModelo.iValidez =
      filtro == null || filtro == "iValidez"
        ? ""
        : this.filtrosactivosModelo.iValidez;
    this.filtrosactivosModelo.iGrupoId =
      filtro == null || filtro == "iGrupoId"
        ? ""
        : this.filtrosactivosModelo.iGrupoId;
    this.filtrosactivosModelo.iTipoProductoId =
      filtro == null || filtro == "iTipoProductoId"
        ? ""
        : this.filtrosactivosModelo.iTipoProductoId;
    this.filtrosactivosModelo.iGrupoAsociadoId =
      filtro == null || filtro == "iGrupoAsociadoId"
        ? ""
        : this.filtrosactivosModelo.iGrupoAsociadoId;
    this.filtrosactivosModelo.iTipoVisitaId =
      filtro == null || filtro == "iTipoVisitaId"
        ? ""
        : this.filtrosactivosModelo.iTipoVisitaId;
    this.filtrosactivosModelo.iTipoNivelId =
      filtro == null || filtro == "iTipoNivelId"
        ? ""
        : this.filtrosactivosModelo.iTipoNivelId;
    this.filtrosactivosModelo.iCategoriaId =
      filtro == null || filtro == "iCategoriaId"
        ? ""
        : this.filtrosactivosModelo.iCategoriaId;
    this.filtrosactivosModelo.bALaVenta =
      filtro == null || filtro == "bALaVenta"
        ? ""
        : this.filtrosactivosModelo.bALaVenta;
    this.filtrosactivosModelo.bImprimirPromo =
      filtro == null || filtro == "bImprimirPromo"
        ? ""
        : this.filtrosactivosModelo.bImprimirPromo;
    this.filtrosactivosModelo.bImprimirPrecio =
      filtro == null || filtro == "bImprimirPrecio"
        ? ""
        : this.filtrosactivosModelo.bImprimirPrecio;
    this.filtrosactivosModelo.chTeclaRapida =
      filtro == null || filtro == "chTeclaRapida"
        ? ""
        : this.filtrosactivosModelo.chTeclaRapida;
    this.filtrosactivosModelo.iTEDatosId =
      filtro == null || filtro == "iTEDatosId"
        ? ""
        : this.filtrosactivosModelo.iTEDatosId;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
    }
  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if (
      (filtros.pkId != null && filtros.pkId.trim() != "") ||
      (filtros.chCodigo != null && filtros.chCodigo.trim() != "") ||
      (filtros.chNombrePromo != null && filtros.chNombrePromo.trim() != "") ||
      (filtros.chDescripcionNombre != null &&
        filtros.chDescripcionNombre.trim() != "") ||
      (filtros.chTextoPantalla != null &&
        filtros.chTextoPantalla.trim() != "") ||
      (filtros.chTextoImpresion != null &&
        filtros.chTextoImpresion.trim() != "") ||
      (filtros.chTextoDisplay != null && filtros.chTextoDisplay.trim() != "") ||
      (filtros.chTipoValidez != null && filtros.chTipoValidez.trim() != "") ||
      (filtros.iValidez != null && filtros.iValidez.trim() != "") ||
      (filtros.iGrupoId != null && filtros.iGrupoId.trim() != "") ||
      (filtros.iTipoProductoId != null &&
        filtros.iTipoProductoId.trim() != "") ||
      (filtros.iGrupoAsociadoId != null &&
        filtros.iGrupoAsociadoId.trim() != "") ||
      (filtros.iTipoVisitaId != null && filtros.iTipoVisitaId.trim() != "") ||
      (filtros.iTipoNivelId != null && filtros.iTipoNivelId.trim() != "") ||
      (filtros.iCategoriaId != null && filtros.iCategoriaId.trim() != "") ||
      (filtros.bALaVenta != null && filtros.bALaVenta.trim() != "") ||
      (filtros.bImprimirPromo != null && filtros.bImprimirPromo.trim() != "") ||
      (filtros.bImprimirPrecio != null &&
        filtros.bImprimirPrecio.trim() != "") ||
      (filtros.chTeclaRapida != null && filtros.chTeclaRapida.trim() != "") ||
      (filtros.iTEDatosId != null && filtros.iTEDatosId.trim() != "") ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != "")
    )
      return true;
    else return false;
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2
      ? o1.id === o2.id || o1 === o2.id || o1.id === o2
      : o1 === o2;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.seleccion.clear()
      : this.dataSource.data.forEach((row) => this.seleccion.select(row));
  }
  ///Validador

  ///Funcionalidades

  cargarDatos() {
    //Cargamos Datos Asincronamente
  }

  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;

    await this.servicioAPI.getTiposBonoAsync().then(
      (data) => {
        this.tiposBonoAPI = new TiposPromocion_Respuesta().deserialize(data);
        if (this.tiposBonoAPI.DatosResult != null) {
          this.tiposBono = this.tiposBonoAPI.DatosResult.lista;
          this.dataSource = new MatTableDataSource(this.tiposBono);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.alertaServicio.error(
            this.tiposBonoAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );

    await this.servicioAPI.getTiposControlAsync().then(
      (data) => {
        this.tiposTiposControlAPI = new TiposControl_Respuesta().deserialize(
          data
        );
        if (this.tiposTiposControlAPI.DatosResult != null) {
          this.tiposTiposControl =
            this.tiposTiposControlAPI.DatosResult.customanTiposControl;
        } else {
          this.alertaServicio.error(
            this.tiposTiposControlAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.servicioAPI.getRecintosAsync().then(
      (data) => {
        this.tiposRecintosAPI = new Recintos_Respuesta().deserialize(data);
        if (this.tiposRecintosAPI.DatosResult != null) {
          this.tiposRecinto = this.tiposRecintosAPI.DatosResult.ListaRecintos;
        } else {
          this.tiposRecinto = [];
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.servicioAPI.getTiposButacaAsync().then(
      (data) => {
        this.tiposTiposButacaAPI = new TipoButaca_Respuesta().deserialize(data);
        if (this.tiposTiposButacaAPI.DatosResult != null) {
          this.tiposTiposButaca =
            this.tiposTiposButacaAPI.DatosResult.DatosListas;
        } else {
          this.tiposTiposButaca = [];
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.servicioAPI.getGruposAsociadosAsync().then(
      (data) => {
        this.gruposAsociadosAPI = new GruposAsociados_Respuesta().deserialize(
          data
        );
        if (this.gruposAsociadosAPI.DatosResult != null) {
          this.gruposAsociados =
            this.gruposAsociadosAPI.DatosResult.ListadoGruposAsociados;
        } else {
          this.alertaServicio.error(
            this.gruposAsociadosAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );

    //Lanzamos encadenado
    await this.servicioAPI.getGrupoTipoBonoAsync().then(
      (data) => {
        this.gruposTipoBonoAPI = new GruposTipoBono_Respuesta().deserialize(
          data
        );
        if (this.gruposTipoBonoAPI.DatosResult != null) {
          this.gruposTipoBono =
            this.gruposTipoBonoAPI.DatosResult.customanGrupoTipoBono;
        } else {
          this.alertaServicio.error(
            this.gruposTipoBonoAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );

    //Lanzamos encadenado
    await this.servicioAPI.getTiposProductosAsync().then(
      (data) => {
        this.tipoProductoAPI = new TipoProducto_Respuesta().deserialize(data);
        if (this.tipoProductoAPI.DatosResult != null) {
          this.tipoProducto =
            this.tipoProductoAPI.DatosResult.ListadoTiposProductos;
        } else {
          this.alertaServicio.error(
            this.tipoProductoAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );

    this.servicioAPI.ObtenerCustomanTipoVisita().subscribe((data) => {
      this.tipoVisitaAPI = new TipoVisita_Respuesta().deserialize(data);
      if (this.tipoVisitaAPI.DatosResult != null) {
        this.tipoVisita = this.tipoVisitaAPI.DatosResult.lista;
      } else {
        this.alertaServicio.error(
          this.tipoVisitaAPI.Mensajes[0].DescripcionMensaje
        );
        resultado = false;
      }
    });

    this.servicioAPI.GetTiposNiveles().subscribe((data) => {
      this.tiposNivelesAPI = new TipoNivel_Respuesta().deserialize(data);
      if (this.tiposNivelesAPI.DatosResult != null) {
        this.tiposNiveles =
          this.tiposNivelesAPI.DatosResult.ListadoTiposNiveles;
      } else {
        this.alertaServicio.error(
          this.tiposNivelesAPI.Mensajes[0].DescripcionMensaje
        );
        resultado = false;
      }
    });

    this.servicioAPI.ObtenerCustomanCategoria().subscribe((data) => {
      this.categoriaAPI = new Categoria_Respuesta().deserialize(data);
      if (this.categoriaAPI.DatosResult != null) {
        this.categoria = this.categoriaAPI.DatosResult.lista;
      } else {
        this.alertaServicio.error(
          this.categoriaAPI.Mensajes[0].DescripcionMensaje
        );
        resultado = false;
      }
    });

    this.servicioAPI.GetTiposEntradaDatos().subscribe((data) => {
      this.TiposEntradaDatosAPI = data;
      this.tiposEntradaDatos = [];
      if (this.TiposEntradaDatosAPI.DatosResult != null) {
        if (
          this.TiposEntradaDatosAPI.DatosResult.ListadoTiposEntradaDatos != null
        ) {
          this.TiposEntradaDatosAPI.DatosResult.ListadoTiposEntradaDatos.forEach(
            (element) => {
              this.tiposEntradaDatos.push(element);
            }
          );
        }
      }
    });

    this.servicioAPI.GetTipoEntrada().subscribe((data) => {
      this.TiposEntradaAPI = data;
      this.tiposEntrada = [];
      if (this.TiposEntradaAPI.DatosResult != null) {
        if (this.TiposEntradaAPI.DatosResult.Lista != null) {
          this.TiposEntradaAPI.DatosResult.Lista.forEach((element) => {
            this.tiposEntrada.push(element);
          });
        }
      }
    });

    return resultado;
  }

  async cargarBotonera(): Promise<boolean> {
    let resultado: boolean = false;
    await this.servicioAPI
      .getBotoneraAsync(
        null,
        this.globales.maxBotonesRapidosTPV.toString().trim()
      )
      .then(
        (data) => {
          this.tiposBotoneraPromocionAPI = new RespuestaBotonera().deserialize(
            data
          );
          if (this.tiposBotoneraPromocionAPI.DatosResult != null) {
            this.tiposBotoneraPromocion =
              this.tiposBotoneraPromocionAPI.DatosResult.Lista;
          } else {
            this.alertaServicio.error(
              "Botonera: " +
                this.tiposBotoneraPromocionAPI.Mensajes[0].DescripcionMensaje
            );
            resultado = false;
          }
        },
        (error) => {
          this.alertaServicio.error("Botonera: " + error);
          resultado = false;
        }
      );
    return resultado;
  }

  //True = recinto, false = Actividad
  filtrarTiposRecintos(tipo?: boolean): Recintos[] {
    let respuesta = [];

    if (tipo != null && this.tiposRecinto != null) {
      if (tipo) {
        let Recintos = this.tiposRecinto.filter((x) => x.Taller != "1");
        let tmpRecintosAsociados = this.recintosasociados;
        if (Recintos != null && Recintos.length > 0) {
          respuesta = Recintos.filter(function (item) {
            return (
              tmpRecintosAsociados.findIndex(function (itemSub) {
                return itemSub == item.PkId;
              }) === -1
            );
          });
        } else {
          respuesta = this.tiposRecinto;
        }
      } else {
        let Talleres = this.tiposRecinto.filter((x) => x.Taller == "1");
        let tmpActividadesAsociadas = this.actividadesasociadas;
        if (Talleres != null && Talleres.length > 0) {
          respuesta = Talleres.filter(function (item) {
            return (
              tmpActividadesAsociadas.findIndex(function (itemSub) {
                return itemSub == item.PkId;
              }) === -1
            );
          });
        } else {
          respuesta = this.tiposRecinto;
        }
      }
    }
    return respuesta;
  }

  //True = recinto, false = Actividad
  daTiposRecintos(tipo?: boolean): Recintos[] {
    let respuesta = [];

    if (tipo != null && this.tiposRecinto != null) {
      if (tipo) {
        respuesta = this.tiposRecinto.filter(
          (x) =>
            x.Taller != "1" &&
            this.recintosasociados.findIndex((y) => y == x.PkId) >= 0
        );
      } else {
        respuesta = this.tiposRecinto.filter(
          (x) =>
            x.Taller == "1" &&
            this.actividadesasociadas.findIndex((y) => y == x.PkId) >= 0
        );
      }
    }
    return respuesta;
  }
  //---Asignacion Recintos
  asignarTipoRecinto(item, evento) {
    this.recintosasociados.push(item.PkId);
    this.ordenRecintosAsociados.push(null);
  }

  desasignarTipoRecinto(item, evento) {
    let arrtemp = [];
    let index = this.recintosasociados.indexOf(item.PkId);
    if (index >= 0) {
      this.ordenRecintosAsociados.splice(index, 1);
    }
    arrtemp = this.recintosasociados.filter(function (x) {
      return x != item.PkId;
    });
    this.recintosasociados = arrtemp;
  }

  deasignarTodosRecintos() {
    this.recintosasociados = [];
    this.ordenRecintosAsociados = [];
  }
  asignarTodosRecintos() {
    // Coger los Ordenes Actuales y ponerlos en el nuevo array
    let numItem: number = 0;
    let tmpElementos: { idRecinto: string; orden: string }[] = [];
    this.ordenRecintosAsociados.forEach((element) => {
      if (element != null && element.trim() != "") {
        tmpElementos.push({
          idRecinto: this.recintosasociados[numItem],
          orden: element,
        });
      }
      numItem++;
    });
    //cargamos todos
    this.recintosasociados = this.tiposRecinto
      .filter((x) => x.Taller != "1")
      .map(function (x) {
        return x.PkId;
      });
    this.ordenRecintosAsociados = [];
    //Repasamos para poner los ordenes correctos y lo demas a null
    this.recintosasociados.forEach((element) => {
      let tmpIndex = tmpElementos.findIndex((y) => y.idRecinto == element);
      if (tmpIndex >= 0) {
        this.ordenRecintosAsociados.push(tmpElementos[tmpIndex].orden);
      } else {
        this.ordenRecintosAsociados.push(null);
      }
    });
  }

  obtenerRecintosAsociadosconOrden(): string {
    let respuesta: string = "";
    let elemento: string;
    let count = 0;
    this.recintosasociados.forEach((element) => {
      elemento =
        element +
        ":" +
        (this.ordenRecintosAsociados[count] == null
          ? "0"
          : this.ordenRecintosAsociados[count]) +
        ",";
      respuesta = respuesta + elemento;
      count++;
    });
    return respuesta.substring(0, respuesta.length - 1);
  }

  onCambiodeOrden(orden, indice): void {
    //alert(''+orden+' -> '+indice);
    this.ordenRecintosAsociados[indice] = orden;
  }

  //---Asignacion Actividades
  asignarTipoActividad(item, evento) {
    this.actividadesasociadas.push(item.PkId);
  }

  desasignarTipoActividad(item, evento) {
    let arrtemp = [];
    arrtemp = this.actividadesasociadas.filter(function (x) {
      return x != item.PkId;
    });
    this.actividadesasociadas = arrtemp;
  }

  deasignarTodasActividades() {
    this.actividadesasociadas = [];
  }
  asignarTodasActividades() {
    this.actividadesasociadas = this.tiposRecinto
      .filter((x) => x.Taller == "1")
      .map(function (x) {
        return x.PkId;
      });
  }

  recortav35(texto: string): string {
    if (texto.length > 36) {
      return texto.substring(0, 36) + "...";
    } else return texto;
  }
  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): TipoPromocion {
    let respuesta = new TipoPromocion();
    // respuesta.Activo = item.Activo?"True":"False";
    // respuesta.Nombre = item.Nombre;
    // respuesta.subGrupoRecinto = item.subGrupoRecinto?"True":"False";
    // respuesta.PkId = item.PkId;
    // respuesta.GrupoRecintosId = item.GrupoRecintosId;
    // respuesta.ControlarAforo = item.ControlarAforo?"True":"False";
    // respuesta.ControlarAforoReserva = item.ControlarAforoReserva?"True":"False";
    // respuesta.TipoAforo = item.TipoAforo;
    // respuesta.AforoMaximo = item.AforoMaximo;
    // respuesta.LimiteAforo = item.LimiteAforo;
    // respuesta.HoraInicio = item.HoraInicio;
    // respuesta.HoraFin = item.HoraFin;
    // respuesta.PorDefecto = item.PorDefecto?"True":"False";
    // respuesta.MinimoAforo = item.MinimoAforo;
    // respuesta.Taller = item.Taller?"True":"False";
    // respuesta.Butacas = item.Butacas?"True":"False";
    // respuesta.MostrarInternet = item.MostrarInternet?"True":"False";
    // respuesta.MaximoInternet = item.MaximoInternet;
    // respuesta.GrupoActividadId = item.GrupoActividadId;
    // respuesta.CodigoTipoControl = item.CodigoTipoControl;
    // respuesta.Secciones = item.Secciones;
    // respuesta.Shape  = item.Shape;
    // respuesta.Coords = item.Coords;
    // respuesta.OrdenFilas = item.OrdenFilas;
    // respuesta.OrdenButacas = item.OrdenButacas;
    // respuesta.Numeracion = item.Numeracion;
    // respuesta.NumSesion = item.NumSesion?"True":"False";
    // respuesta.TiempoExtra = item.TiempoExtra;

    // respuesta.Idiomas = this.editorListaIdiomas;
    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        //Salvar a API
        this.servicioAPI
          .delTiposEntradas(this.model.pkId)
          .pipe(first())
          .subscribe(
            (data) => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (
                data.DatosResult != null &&
                (data.Mensajes == null || data.Mensajes.length == 0)
              ) {
                this.alertaServicio.success("Datos Borrados Correctamente");
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.pkId !== this.model.pkId
                );
              } else {
                this.alertaServicio.error(data.Mensajes[0].DescripcionMensaje);
              }
            },
            (error) => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        var resultado: string = "";
        this.seleccion.selected.forEach((element) => {
          //Recorremos los seleccionados y vamos borrando
          this.servicioAPI.delTiposEntradasAsync(element.pkId).then(
            (data) => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (
                data.DatosResult != null &&
                (data.Mensajes == null || data.Mensajes.length == 0)
              ) {
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.pkId !== element.pkId
                );
              } else {
                resultado += data.Mensajes[0].DescripcionMensaje + " <BR />";
              }
            },
            (error) => {
              resultado += error + " <BR />";
            }
          );
        });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        } else {
          this.alertaServicio.success("Datos Borrados Correctamente");
        }

        //End For
        this.cargarDatosAsync();
        this.cargarBotonera();
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  duplicar(row: traspasomodelo) {
    this.model = row; // cambiar por los datos de la fila
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.elementosBorrados = [];
    this.alertaServicio.limpiar();
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.translation.BorrarCliente,
        texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas,
        ok: this.translation.Confirmar,
        ko: this.translation.Cancelar,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "SI") {
        this.servicioAPI.DelTipoBono(pkId).subscribe((res) => {
          var bonoToDelete = this.dataSource.data.find(
            (item) => item.pkId === pkId
          );
          this.dataSource.data = this.dataSource.data.filter(
            (item) => item !== bonoToDelete
          );
          this.dataSource.filteredData = this.dataSource.filteredData.filter(
            (item) => item !== bonoToDelete
          );
        });
      }
    });
  }

  validacion() {
    const separador = document.getElementsByClassName("separadorLateral");
    const campos = document.getElementsByClassName("editor-campo-registro");
    for (let i = 0; i < campos.length; i++) {
      if (campos[i].classList.contains("ng-valid")) {
        if (campos[i].classList.contains("editor-campo-registro-check")) {
          //separador[i].setAttribute('style', 'border-color:#002c76');
        } else {
          //separador[i].setAttribute('style', 'border-color:green');
        }
      } else {
        //separador[i].setAttribute('style', 'border-color:#002c76');
      }
    }
  }

  mostrar() {
    const recintos = document.getElementById("listaRecintos");
    const boton = document.getElementById("botonMostrarRecinto");
    if (recintos.style.display === "none") {
      recintos.style.display = "block";
      boton.style.backgroundImage = "url(/assets/icon/icono-mas-activo.svg)";
    } else {
      recintos.style.display = "none";
      boton.style.backgroundImage = "url(/assets/icon/icono-mas.svg)";
    }
  }

  mostrar2() {
    const recintos = document.getElementById("listaActividades");
    const boton = document.getElementById("botonMostrarActividad");
    if (recintos.style.display === "none") {
      recintos.style.display = "block";
      boton.style.backgroundImage = "url(/assets/icon/icono-mas-activo.svg)";
    } else {
      recintos.style.display = "none";
      boton.style.backgroundImage = "url(/assets/icon/icono-mas.svg)";
    }
  }

  subirBajar(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  aLaVentaIntToBool(int: string) {
    if (int === "0") {
      return "No";
    } else if (int === "1") {
      return "Si";
    } else {
      return "";
    }
  }

  getNombreGrupoById(id: string) {
    // TODO: falta acceso a la tabla tbGrupoTipoBono.PkId
  }

  // formato front: 2020-10-14 (yyyy-mm-dd)
  // formato back:  31/12/2900 0:00:00 (dd/mm/yyyy 0:00:00)
  fechaApiToFechaFront(fecha: string) {
    let split1 = fecha.split(" ");
    let split2 = split1[0].split("/");
    let ans = split2[2] + "-" + split2[1] + "-" + split2[0];
    return ans;
  }

  fechaFrontToFechaApi(fecha: string) {
    let split1 = fecha.split("-");
    let ans = split1[2] + "/" + split1[1] + "/" + split1[0] + " 0:00:00";
    return ans;
  }

  borrarPromocionEntrada(index: number, row?) {
    //
    //if (this.permiteBorrar || this.promocionEntradaFormGroup.controls[index].value.iTipoBonoEntradasId=='') {
    //this.tiposBonoEntrada.forEach((obj,i) => {if (obj==row) index = i});
    let elementoborrado = this.tiposBonoEntrada[index].iTipoBonoEntradasId;
    this.elementosBorrados.push(elementoborrado);
    this.tiposBonoEntrada = this.tiposBonoEntrada.filter(
      (obj, i) => i != index
    );

    this.promocionEntradaFormGroup.removeAt(index);
    //this.actualizarPromocionEntradaFormGroup()

    this.dataSourceTarifaPromocionEntrada = new MatTableDataSource(
      this.tiposBonoEntrada
    );
    //} else {
    //this._snackBar.open("Imposible eliminar entrada...", "Aceptar", {
    //  duration: 2500,
    //  panelClass: ['blue-snackbar'],
    //  horizontalPosition: 'center',
    //   verticalPosition: 'bottom'
    // });
    //}
  }

  anadirEntrada() {
    if (this.tiposBonoEntrada === undefined) {
      this.tiposBonoEntrada = [];
    }
    let porcentajeinicial = 0;
    if (this.promocionEntradaFormGroup.controls.length == 0)
      porcentajeinicial = 100;
    if (
      this.promocionEntradaFormGroup.controls.length == 0 ||
      this.validateEntradas(this.promocionEntradaFormGroup)
    ) {
      this.promocionEntradaFormGroup.push(
        new FormGroup({
          iTipoBonoEntradasId: new FormControl(""),
          iTipoEntradaId: new FormControl(""),
          NombreTipoEntrada: new FormControl(""),
          iNumEntradas: new FormControl(""),
          dtFechaInicio: new FormControl(""),
          dtFechaFin: new FormControl(""),
          rPorcentaje: new FormControl(porcentajeinicial),
        })
      );

      this.tiposBonoEntrada.push(new tipoBonoEntradaPlantilla()); //tipoBonoEntradaPlantilla formulario de entrada
      //this.actualizarPromocionEntradaFormGroup()
      this.setDataSourceTarifasPromocionEntrada();
    }
  }

  setDataSourceTarifasPromocionEntrada() {
    this.tiposBonoEntrada = [];

    this.promocionEntradaFormGroup.controls.forEach((element, i) => {
      if (element instanceof FormGroup) {
        let tipoBono = new tipoBonoEntradaPlantilla();
        tipoBono.iTipoEntradaId = element.value.iTipoEntradaId;
        tipoBono.NombreTipoEntrada = element.value.NombreTipoEntrada;
        tipoBono.iNumEntradas = element.value.iNumEntradas;
        tipoBono.dtFechaInicio = element.value.dtFechaInicio;
        tipoBono.dtFechaFin = element.value.dtFechaFin;
        if (element.value.rPorcentaje == "") tipoBono.rPorcentaje = "0";
        else tipoBono.rPorcentaje = element.value.rPorcentaje;
        tipoBono.iTipoBonoEntradasId = "";
        tipoBono.iTipoBonoId = "";
        this.tiposBonoEntrada.push(tipoBono);
      }
    });
    this.dataSourceTarifaPromocionEntrada = new MatTableDataSource(
      this.tiposBonoEntrada
    );
  }

  validateEntradas(promocionEntradaFormGroup) {
    let ret = true;

    if (promocionEntradaFormGroup)
      promocionEntradaFormGroup.controls.forEach((element) => {
        if (
          element instanceof FormGroup &&
          element.value["iTipoEntradaId"] == ""
        )
          ret = false;
        if (element instanceof FormGroup && element.value["iNumEntradas"] == "")
          ret = false;
        if (
          element instanceof FormGroup &&
          element.value["dtFechaInicio"] == ""
        )
          ret = false;
        if (element instanceof FormGroup && element.value["dtFechaFin"] == "")
          ret = false;
      });
    return ret;
  }

  hasTableRows() {
    let ret = false;
    if (
      !this.promocionEntradaFormGroup ||
      this.promocionEntradaFormGroup.length < 1
    )
      ret = false;
    else ret = true;

    return ret;
  }

  validateTable(promocionEntradaFormGroup) {
    let ret = true;
    if (this.hasTableRows()) {
      if (promocionEntradaFormGroup)
        promocionEntradaFormGroup.controls.forEach((element) => {
          if (
            element instanceof FormGroup &&
            element.value["iTipoEntradaId"] == ""
          )
            ret = false;
          if (
            element instanceof FormGroup &&
            element.value["iNumEntradas"] == ""
          )
            ret = false;
          if (
            element instanceof FormGroup &&
            element.value["dtFechaInicio"] == ""
          )
            ret = false;
          if (element instanceof FormGroup && element.value["dtFechaFin"] == "")
            ret = false;
        });
    } else {
      ret = false;
    }

    return ret;
  }

  iValidezaCero(ev, form) {
    if (ev.value) form.patchValue({ iValidez: 0 });
  }

  isnovalid() {
    let ret = true;
    if (
      this.promocionEntradaFormGroup &&
      this.promocionEntradaFormGroup.length > 0 &&
      this.validateTable(this.promocionEntradaFormGroup) &&
      !this.f.chCodigo.invalid &&
      !this.chTipoValidez.invalid &&
      !(this.chTipoValidez.value != "S" && this.iValidez.invalid) &&
      !this.iGrupoId.invalid
    ) {
      ret = false;
      this.tablavalida = true;
      //this._cds.detectChanges();
    } else {
      if (
        !this.validateTable(this.promocionEntradaFormGroup) &&
        this.hasTableRows()
      ) {
        this.tablavalida = false;
        //this._cds.detectChanges();
      }
    }

    return ret;
  }

  atras() {
    this.formchanges = false;
    if (this.formchanges) {
      let snack = this._snackBar.open(
        "Aceptas perder los cambios?",
        "Aceptar",
        {
          duration: 0,
          panelClass: ["blue-snackbar"],
          horizontalPosition: "center",
          verticalPosition: "top",
        }
      );
      snack.afterDismissed().subscribe(() => {});
      snack.onAction().subscribe(() => {
        this.editando = false;
        this.idBonoEnEdicion = "";
        while (this.promocionEntradaFormGroup.length !== 0) {
          this.promocionEntradaFormGroup.removeAt(0);
        }
        this.setDataSourceTarifasPromocionEntrada();
      });
    } else {
      this.editando = false;
      this.idBonoEnEdicion = "";
      while (this.promocionEntradaFormGroup.length !== 0) {
        this.promocionEntradaFormGroup.removeAt(0);
      }
      this.setDataSourceTarifasPromocionEntrada();
    }
  }

  ngAfterViewChecked() {
    if (this.validateTable(this.promocionEntradaFormGroup)) {
      this.tablavalida = true;
      this._cds.detectChanges();
    } else {
      this.tablavalida = false;
      this._cds.detectChanges();
    }
  }

  deshabilitarBorrado(i) {
    if (
      !this.permiteBorrar &&
      this.tiposBonoEntrada[i].iTipoBonoEntradasId != ""
    )
      return true;
    else return false;
  }
}
