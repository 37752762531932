<!-- <app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'"></app-buttons> -->
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    *ngIf="estadebaja"
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    (desactivar)="deBaja()"
    [debaja]="true"
    [buttonsWrap]="'clientEdit'"
    [modificar]="modificar"
  ></app-buttons>
  <app-buttons
    *ngIf="!estadebaja"
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    (desactivar)="deBaja()"
    [debaja]="false"
    [buttonsWrap]="'clientEdit'"
    [modificar]="modificar"
  ></app-buttons>
  <div class="">
    <div class="editor-registro">
      <form *ngIf="editorRegistroForm" [formGroup]="editorRegistroForm">
        <!-- Nueva Maquetacion -->
        <div class="main-container">
          <div class="pd-ltr-20 xs-pd-20-10">
            <div class="min-height-200px">
              <!-- horizontal Basic Forms Start -->

              <div class="clearfix">
                <div class="pull-left">
                  <h4 class="text-blue h4">{{ translation.EditarCliente }}</h4>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.NombreCliente }}*</mat-label>
                    <input
                      matInput
                      formControlName="NombreCliente"
                      class="editor-campo-registro"
                      [ngClass]="{ 'is-invalid': f.NombreCliente.errors }"
                    />
                    <mat-error *ngIf="f.NombreCliente.errors?.required">{{
                      translation.CampoRequerido
                    }}</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Codigo }}</mat-label>
                    <input
                      matInput
                      type="number"
                      formControlName="Codigo"
                      id="codigoCliente"
                      class="editor-campo-registro"
                      color="disabled"
                      readonly="readonly"
                    />
                  </mat-form-field>
                </div>

                <!--div class="col-md-4 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.PrimerApellido}}</mat-label>
                    <input matInput formControlName="PrimerApellido" class="editor-campo-registro">
                  </mat-form-field>
                </div>
  
                <div class="col-md-4 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.SegundoApellido}}</mat-label>
                    <input matInput formControlName="SegundoApellido" class="editor-campo-registro">
                  </mat-form-field>
                </div-->
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="weight-600">{{
                      translation.InformacionPersonal
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.TipoDocumento }}</mat-label>
                    <mat-select
                      placeholder="{{ translation.TipoDocumento }}"
                      formControlName="TipoDocumento"
                      name="TipoDocumento"
                    >
                      <mat-option selected="">--- ---</mat-option>
                      <mat-option value="DNI/NIF">DNI/NIF</mat-option>
                      <mat-option value="CIF">CIF</mat-option>
                      <mat-option value="NIE">NIE</mat-option>
                      <mat-option value="PASAPORTE">PASAPORTE</mat-option>
                      <mat-option value="OTRO">OTRO</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.NumeroDeDocumento }}</mat-label>
                    <input
                      matInput
                      formControlName="NumDocumento"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.NombreComercial }}</mat-label>
                    <input
                      matInput
                      formControlName="NombreComercial"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                    <div class="grupo-editor-campo-registro">
                      <mat-slide-toggle
                        color="primary"
                        formControlName="ImprimirEnTicket"
                      >
                        {{ translation.ImprimirEnTicket }}
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="weight-600">{{
                      translation.InformacionDeContacto
                    }}</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.CorreoElectronico }}</mat-label>
                    <input
                      matInput
                      formControlName="CorreoElectronico"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.CorreoElectronico }} 2</mat-label>
                    <input
                      matInput
                      formControlName="CorreoElectronico2"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      translation.PrefijoInternacional
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="PrefijoInternacional"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Telefono }} 1</mat-label>
                    <input
                      matInput
                      formControlName="Telefono1"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Telefono }} 2</mat-label>
                    <input
                      matInput
                      formControlName="Telefono2"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Movil }}</mat-label>
                    <input
                      matInput
                      formControlName="Movil"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Domicilio }}</mat-label>
                    <input
                      matInput
                      formControlName="Domicilio"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Localidad }}</mat-label>
                    <input
                      matInput
                      formControlName="Localidad"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Provincia }}</mat-label>
                    <input
                      matInput
                      formControlName="Provincia"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-4 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.CodigoPostal }}</mat-label>
                    <input
                      matInput
                      formControlName="CodigoPostal"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <!--div class="col-md-4 col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.Pais}}</mat-label>
                    <mat-option> --- --- </mat-option>
                    <mat-select placeholder="{{translation.Pais}}" formControlName="Pais" name="Pais">
                      <mat-option *ngFor="let element of listaPaises" [value]="element.PaisCodigo">
                        {{element.NombrePais}} </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div-->

                <div class="col-md-4 col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.Pais }}</mat-label>
                    <mat-select
                      placeholder="{{ translation.Pais }}"
                      formControlName="PaisCodigo"
                      name="PaisCodigo"
                    >
                      <mat-option> --- --- </mat-option>
                      <mat-option
                        *ngFor="let element of listaPaises"
                        [value]="element.CodPais"
                      >
                        {{ element.NombrePais }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Nacionalidad }}</mat-label>
                    <input
                      matInput
                      formControlName="Nacionalidad"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="weight-600">{{
                      translation.DetallesDeCuenta
                    }}</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <app-input-filter
                    *ngIf="client && listaFormasPago"
                    formControlText="FormaDePago"
                    [disabled]="listaFormasPago.length < 1"
                    [label]="translation.FormaDePago"
                    [placeHolderTranslation]="translation.FormaDePago"
                    [list]="listaFormasPago"
                    textShow="Denominacion"
                    dataInput="pkId"
                    required="false"
                    [data]="client['FormaDePago']"
                    (close)="closeInputFilter($event)"
                  ></app-input-filter>
                  <!-- <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.FormaDePago}}</mat-label>
                  <mat-select placeholder="{{translation.FormaDePago}}" formControlName="FormaDePago"
                    name="FormaDePago">
                    <mat-option> --- --- </mat-option>
                    <mat-option *ngFor="let element of listaFormasPago" [value]="element.pkId">
                      {{element.Denominacion}} </mat-option>
                  </mat-select>
                </mat-form-field> -->
                </div>
                <div class="col-sm-12 col-md-6">
                  <app-input-filter
                    *ngIf="client && listaGruposClientes"
                    formControlText="GrupoCliente"
                    [disabled]="listaGruposClientes.length < 1"
                    [label]="translation.GrupoCliente"
                    [placeHolderTranslation]="translation.GrupoCliente"
                    [list]="listaGruposClientes"
                    textShow="NombreGruposClientes"
                    dataInput="pkId"
                    required="false"
                    [data]="client['GrupoCliente']"
                    (close)="closeInputFilter($event)"
                  ></app-input-filter>
                  <!-- <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.GrupoCliente}}</mat-label>
                  <mat-select placeholder="{{translation.GrupoCliente}}" formControlName="GrupoCliente"
                    name="GrupoCliente">
                    <mat-option> --- --- </mat-option>
                    <mat-option *ngFor="let element of listaGruposClientes" [value]="element.pkId">
                      {{element.NombreGruposClientes}} </mat-option>
                  </mat-select>
                </mat-form-field> -->
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <app-input-filter
                    *ngIf="client && listaZonaComercial"
                    formControlText="ZonaComercial"
                    [disabled]="listaZonaComercial.length < 1"
                    [label]="translation.ZonaComercial"
                    [placeHolderTranslation]="translation.ZonaComercial"
                    [list]="listaZonaComercial"
                    textShow="Nombre"
                    dataInput="Id"
                    required="false"
                    [data]="client['ZonaComercial']"
                    (close)="closeInputFilter($event)"
                  ></app-input-filter>
                  <!-- <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.ZonaComercial}}</mat-label>
                  <mat-select placeholder="{{translation.ZonaComercial}}" formControlName="ZonaComercial"
                    name="ZonaComercial">
                    <mat-option> --- --- </mat-option>
                    <mat-option *ngFor="let element of listaZonaComercial" [value]="element.Id">
                      {{element.Nombre}} </mat-option>
                  </mat-select>
                </mat-form-field> -->
                </div>
                <div class="col-sm-12 col-md-6">
                  <app-input-filter
                    *ngIf="client && listaTiposClientes"
                    formControlText="TipoCliente"
                    [disabled]="listaTiposClientes.length < 1"
                    [label]="translation.TipoCliente"
                    [placeHolderTranslation]="translation.TipoCliente"
                    [list]="listaTiposClientes"
                    textShow="Nombre"
                    dataInput="pkId"
                    required="false"
                    [data]="client['TipoCliente']"
                    (close)="closeInputFilter($event)"
                  ></app-input-filter>
                  <!--  <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.TipoCliente}}</mat-label>
                  <mat-select placeholder="{{translation.TipoCliente}}" formControlName="TipoCliente"
                    name="TipoCliente">
                    <mat-option> --- --- </mat-option>
                    <mat-option *ngFor="let element of listaTiposClientes" [value]="element.pkId">
                      {{element.Nombre}} </mat-option>
                  </mat-select>
                </mat-form-field> -->
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.IdiomaVisita }}</mat-label>
                    <mat-select
                      placeholder="{{ translation.IdiomaVisita }}"
                      formControlName="CodIdiomaVisita"
                      name="CodIdiomaVisita"
                    >
                      <mat-option> --- --- </mat-option>
                      <mat-option
                        *ngFor="let element of listaIdiomas"
                        [value]="element.CodIdioma"
                      >
                        {{ element.Nombre }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.IdiomaDocumento }}</mat-label>
                    <mat-select
                      placeholder="{{ translation.IdiomaDocumento }}"
                      formControlName="CodIdiomaDocumento"
                      name="CodIdiomaDocumento"
                    >
                      <mat-option> --- --- </mat-option>
                      <mat-option
                        *ngFor="let element of listaIdiomas"
                        [value]="element.CodIdioma"
                      >
                        {{ element.Nombre }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <div class="grupo-editor-campo-registro">
                    <mat-slide-toggle
                      (change)="onChangeBajaGeneral($event)"
                      color="primary"
                      formControlName="bDeBaja"
                      >{{ translation.DarDeBajaGeneral }}
                    </mat-slide-toggle>
                  </div>
                </div>
                <div
                  class="col-md-6 col-sm-12"
                  *ngIf="editorRegistroForm.controls['bDeBaja'].value"
                >
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.FechaDeBaja }}</mat-label>
                    <input
                      matInput
                      [matDatepicker]="FechaDeBajaCtrl"
                      placeholder="{{ translation.FechaDeBaja }}"
                      formControlname="FechaDeBaja"
                      (dateChange)="setDate($event, 'FechaDeBaja')"
                      [value]="getFechaBaja()"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="FechaDeBajaCtrl"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #FechaDeBajaCtrl></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="weight-600">{{
                      translation.VentaEnTaquilla
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.FechaDeAlta }}</mat-label>
                    <input
                      matInput
                      [matDatepicker]="FechaDeAlta"
                      placeholder="{{ translation.FechaDeAlta }}"
                      formControlname="FechaDeAltaTaquilla"
                      (dateChange)="setDate($event, 'FechaDeAltaTaquilla')"
                      [value]="getFechaAlta()"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="FechaDeAlta"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #FechaDeAlta></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-4">
                  <div class="grupo-editor-campo-registro">
                    <mat-slide-toggle
                      color="primary"
                      formControlName="DescuentoTaquilla"
                      >{{ translation.Descuento }}
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div
                class="form-group row"
                *ngIf="editorRegistroForm.controls['DescuentoTaquilla'].value"
              >
                <div class="col-sm-12 col-md-4">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.TipoDescuento }}</mat-label>
                    <mat-select
                      placeholder="{{ translation.TipoDescuentoTaquilla }}"
                      formControlName="TipoDescuentoTaquilla"
                      name="TipoDescuentoTaquilla"
                    >
                      <mat-option> --- --- </mat-option>
                      <mat-option value="DT">{{
                        translation.Porcentaje
                      }}</mat-option>
                      <mat-option value="PR">{{
                        translation.Importe
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  class="col-md-6 col-sm-12"
                  *ngIf="editorRegistroForm.controls['DescuentoTaquilla'].value"
                >
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.ImporteDescuento }}</mat-label>
                    <input
                      matInput
                      formControlName="ImporteDescuentoTaquilla"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <div class="grupo-editor-campo-registro">
                    <mat-slide-toggle
                      color="primary"
                      formControlName="RedondearPrecioTaquilla"
                    >
                      {{ translation.RedondearPrecio }}
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="grupo-editor-campo-registro">
                    <mat-slide-toggle
                      color="primary"
                      formControlName="TicketSinDescuentoTaquilla"
                    >
                      {{ translation.TicketSinDescuento }}
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="weight-600">{{
                      translation.VentaEnInternetYAdminastracion
                    }}</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <div class="grupo-editor-campo-registro">
                    <mat-slide-toggle
                      (change)="VentaInternetCheck($event)"
                      color="primary"
                      formControlName="VentaEnInternet"
                      >{{ translation.VentaEnInternetYAdminastracion }}
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  editorRegistroForm.controls['VentaEnInternet'].value === true
                "
              >
                <div class="form-group row">
                  <!--   <div class="col-sm-12 col-md-6">

                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.FechaDeAltaInternet}}</mat-label>
                    <input matInput [matDatepicker]="FechaDeAltaInternetpkr"
                      placeholder="{{translation.FechaDeAltaInternet}}" formControlname="FechaDeAltaInternet"
                      (dateChange)="setDate($event,'FechaDeAltaInternet')" [value]="getFechaDeAltaInternet()">
                    <mat-datepicker-toggle matSuffix [for]="FechaDeAltaInternetpkr"></mat-datepicker-toggle>
                    <mat-datepicker #FechaDeAltaInternetpkr></mat-datepicker>
                  </mat-form-field>

                </div> -->
                </div>
                <div class="form-group row">
                  <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ translation.Usuario }}</mat-label>
                      <input
                        matInput
                        formControlName="Usuario"
                        class="editor-campo-registro"
                      />
                    </mat-form-field>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <mat-form-field class="input-select" appearance="outline">
                      <mat-label>{{
                        translation.FechaDeAltaInternet
                      }}</mat-label>
                      <input
                        matInput
                        [matDatepicker]="FechaDeAltaInternetpkr"
                        placeholder="{{ translation.FechaDeAltaInternet }}"
                        formControlname="FechaDeAltaInternet"
                        (dateChange)="setDate($event, 'FechaDeAltaInternet')"
                        [value]="getFechaDeAltaInternet()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="FechaDeAltaInternetpkr"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #FechaDeAltaInternetpkr></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <!-- <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Contrasena}}</mat-label>
                    <input matInput formControlName="Contrasena" class="editor-campo-registro">
                  </mat-form-field>
                </div> -->
                </div>

                <div class="form-group row">
                  <div class="col-sm-12 col-md-6">
                    <textarea
                      formControlName="Observaciones"
                      class="form-control"
                      style="width: 100%"
                      placeholder="{{ translation.Observaciones }}"
                    ></textarea>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <textarea
                      formControlName="ObsAdvertencia"
                      class="form-control"
                      style="width: 100%"
                      placeholder="{{ translation.ObsAdvertencia }}"
                    ></textarea>
                  </div>
                </div>

                <!-- <div class="form-group row">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.IdiomaVisita}}</mat-label>
                  <mat-select placeholder="{{translation.IdiomaVisita}}" formControlName="CodIdiomaVisita"
                    name="TipoCliente">
                    <mat-option> --- --- </mat-option>
                    <mat-option *ngFor="let element of listaIdiomas" [value]="element.pkId">
                      {{element.Nombre}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->

                <!-- <div class="form-group row">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.IdiomaDocumento}}</mat-label>
                  <mat-select placeholder="{{translation.IdiomaDocumento}}" formControlName="CodIdiomaDocumento"
                    name="TipoCliente">
                    <mat-option> --- --- </mat-option>
                    <mat-option *ngFor="let element of listaIdiomas" [value]="element.pkId">
                      {{element.Nombre}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->

                <div class="form-group">
                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                      <div class="grupo-editor-campo-registro">
                        <mat-slide-toggle
                          color="primary"
                          formControlName="DescuentoInternet"
                        >
                          {{ translation.DescuentoInternet }}
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-group row"
                  *ngIf="
                    editorRegistroForm.controls['DescuentoInternet'].value ===
                    true
                  "
                >
                  <div class="col-sm-12 col-md-6">
                    <mat-form-field class="input-select" appearance="outline">
                      <mat-label>{{
                        translation.TipoDescuentoInternet
                      }}</mat-label>
                      <mat-select
                        placeholder="{{ translation.TipoDescuentoInternet }}"
                        formControlName="TipoDescuentoInternet"
                        name="TipoDescuentoInternet"
                      >
                        <mat-option> --- --- </mat-option>
                        <mat-option value="DT">{{
                          translation.Porcentaje
                        }}</mat-option>
                        <mat-option value="PR">{{
                          translation.Importe
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        translation.ImporteDescuentoInternet
                      }}</mat-label>
                      <input
                        matInput
                        formControlName="ImporteDescuentoInternet"
                        class="editor-campo-registro"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="grupo-editor-campo-registro">
                        <mat-slide-toggle
                          color="primary"
                          formControlName="RedondearPrecioInternet"
                        >
                          {{ translation.RedondearPrecioInternet }}
                        </mat-slide-toggle>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="grupo-editor-campo-registro">
                        <mat-slide-toggle
                          color="primary"
                          formControlName="TicketSinDescuentoInternet"
                        >
                          {{ translation.TicketSinDescuentoInternet }}
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12 col-md-6">
                    <div class="grupo-editor-campo-registro">
                      <mat-slide-toggle
                        (change)="onChangeBajaInternet($event)"
                        color="primary"
                        formControlName="bDeBajaInternet"
                      >
                        {{ translation.DarDeBajaInternet }}
                      </mat-slide-toggle>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <mat-form-field
                      class="input-select"
                      appearance="outline"
                      *ngIf="
                        editorRegistroForm.controls['bDeBajaInternet'].value
                      "
                    >
                      <mat-label>{{
                        translation.FechaDeBajaInternet
                      }}</mat-label>
                      <input
                        matInput
                        [matDatepicker]="FechaDeBajaInternetpkr"
                        placeholder="{{ translation.FechaDeBajaInternet }}"
                        formControlname="FechaDeBajaInternet"
                        (dateChange)="setDate($event, 'FechaDeBajaInternet')"
                        [value]="getFechaDeBajaInternet()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="FechaDeBajaInternetpkr"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #FechaDeBajaInternetpkr></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="weight-600">{{
                      translation.DatosDeFacturacion
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.TipoDocumento }}</mat-label>
                    <mat-select
                      placeholder="{{ translation.TipoDocumentoFacturacion }}"
                      formControlName="TipoDocumentoFacturacion"
                      name="TipoDocumentoFacturacion"
                    >
                      <mat-option selected="">--- ---</mat-option>
                      <mat-option value="DNI/NIF">DNI/NIF</mat-option>
                      <mat-option value="CIF">CIF</mat-option>
                      <mat-option value="NIE">NIE</mat-option>
                      <mat-option value="PASAPORTE">PASAPORTE</mat-option>
                      <mat-option value="OTRO">OTRO</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.NumDocumento }}</mat-label>
                    <input
                      matInput
                      formControlName="NumDocumentoFacturacion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.RazonSocial }}</mat-label>
                    <input
                      matInput
                      formControlName="RazonSocial"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Nombre }}</mat-label>
                    <input
                      matInput
                      formControlName="NombreFacturacion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.PrimerApellido }}</mat-label>
                    <input
                      matInput
                      formControlName="PrimerApellidoFacturacion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.SegundoApellido }}</mat-label>
                    <input
                      matInput
                      formControlName="SegundoApellidoFacturacion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Domicilio }}</mat-label>
                    <input
                      matInput
                      formControlName="DomicilioFacturacion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Localidad }}</mat-label>
                    <input
                      matInput
                      formControlName="LocalidadFacturacion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Provincia }}</mat-label>
                    <input
                      matInput
                      formControlName="ProvinciaFacturacion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.CodigoPostal }}</mat-label>
                    <input
                      matInput
                      formControlName="CodigoPostalFacturacion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.Pais }}</mat-label>
                    <mat-select
                      placeholder="{{ translation.PaisFacturacion }}"
                      formControlName="PaisFacturacion"
                      name="PaisFacturacion"
                    >
                      <mat-option> --- --- </mat-option>
                      <mat-option
                        *ngFor="let element of listaPaises"
                        [value]="element.CodPais"
                      >
                        {{ element.NombrePais }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="weight-600">{{
                      translation.DatosDePago
                    }}</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Persona }}</mat-label>
                    <input
                      matInput
                      formControlName="Persona"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Direccion }}</mat-label>
                    <input
                      matInput
                      formControlName="Direccion"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ translation.Lugar }}</mat-label>
                    <input
                      matInput
                      formControlName="Lugar"
                      class="editor-campo-registro"
                    />
                  </mat-form-field>
                </div>

                <!--div class="col-md-6 col-sm-12">
  
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.DiasDePago}}</mat-label>
                    <input matInput formControlName="Dias" class="editor-campo-registro">
                  </mat-form-field>
  
                </div-->

                <div class="col-sm-12 col-md-6">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.DiasDePago }}</mat-label>
                    <input
                      matInput
                      [matDatepicker]="DiasDePagopkr"
                      placeholder="{{ translation.DiasDePago }}"
                      formControlname="Dias"
                      (dateChange)="setDate($event, 'Dias')"
                      [value]="getFechaDiasDePago()"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="DiasDePagopkr"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #DiasDePagopkr></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="weight-600">{{
                      translation.Contactos
                    }}</label>
                  </div>
                </div>
              </div>

              <!-- Tabla Nueva -->
              <div
                class="mat-elevation-z8 overflow-table"
                [ngClass]="{ 'is-invalid': !tablavalida }"
              >
                <div class="tablebutton">
                  <button
                    mat-fab
                    class="buttonadd"
                    [matTooltip]="translation.AnadirCliente"
                    (click)="anadirCliente()"
                  >
                    <mat-icon class="tableadd">add</mat-icon>
                  </button>
                </div>
                <table
                  mat-table
                  [dataSource]="dataSourceCliente"
                  matSort
                  #entradastable
                  formArrayName="clienteFormGroupArray"
                >
                  <!-- Acciones Column -->
                  <ng-container matColumnDef="Acciones">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ translation.Acciones }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row; let i = index"
                      (click)="$event.stopPropagation()"
                      style="cursor: default"
                    >
                      <div class="action-container">
                        <button
                          matToolTip="Borrar Cliente"
                          class="button-icon"
                          (click)="borrarCliente(i)"
                          style="width: 34px; height: 34px"
                        >
                          <mat-icon>person_off</mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Nombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ translation.Nombre }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row; let i = index"
                      [formGroupName]="i"
                    >
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          type="text"
                          style="width: 100px"
                          formControlName="Nombre"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="PrimerApellido">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ translation.PrimerApellido }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row; let i = index"
                      [formGroupName]="i"
                    >
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          type="text"
                          style="width: 100px"
                          formControlName="PrimerApellido"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="SegundoApellido">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ translation.SegundoApellido }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row; let i = index"
                      [formGroupName]="i"
                    >
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          type="text"
                          style="width: 100px"
                          formControlName="SegundoApellido"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ translation.Email }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row; let i = index"
                      [formGroupName]="i"
                    >
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          type="text"
                          style="width: 100px"
                          formControlName="Email"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Localidad">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ translation.Localidad }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row; let i = index"
                      [formGroupName]="i"
                    >
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          type="text"
                          style="width: 100px"
                          formControlName="Localidad"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Provincia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ translation.Provincia }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let row; let i = index"
                      [formGroupName]="i"
                    >
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          type="text"
                          style="width: 100px"
                          formControlName="Provincia"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="columnsToDisplayContacto"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: columnsToDisplayContacto"
                  ></tr>
                </table>

                <!---->
              </div>
              <!-- fin nueva tabla -->

              <!-- Campos nuevos -->
              <div style="margin: 20px 5px 0 5px; text-align: center">
                <mat-label>{{ translation.Entradas }}</mat-label>

                <customan-dual-list
                  [source]="tiposTipoEntradaTallerSinAsignar"
                  [(destination)]="tiposTipoEntradaTallerAsignadas"
                  key="pkId"
                  display="NombreGrupo"
                  [format]="format"
                  [filter]="true"
                >
                </customan-dual-list>
              </div>
              <!-- <div class="form-group row" style="margin: 20px;">
                <label class="col-sm-12 col-md-2 col-form-label">{{translation.Entradas}}</label>
                <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
                  <mat-list class="w33 izquierda" dense>
                    <p mat-subheader>{{translation.EntradasAsignadas}}</p>
                    <mat-list-item *ngFor="let entradai of daTiposEntrada()"
                      (click)="desasignarTipoentrada(entradai, $event)">
                      <span mat-line>{{entradai.NombreGrupo}} <mat-icon>arrow_forward_ios</mat-icon> </span>
                    </mat-list-item>
                  </mat-list>
                  <div class="w33 alinear-contenido-centrado">
                    <mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
                      (click)="deasignarTodas()">arrow_forward_ios</mat-icon>
                  </div>
                  <mat-list class="w33 derecha" dense>
                    <p mat-subheader>{{translation.EntradasSinAsignar}}</p>
                    <mat-list-item *ngFor="let entradad of filtrarTiposEntrada()"
                      (click)="asignarTipoentrada(entradad, $event)">
                      <span mat-line>
                        <mat-icon>arrow_back_ios</mat-icon> {{entradad.NombreGrupo}}
                      </span>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div> -->
              <div style="margin: 20px 5px 0 5px; text-align: center">
                <mat-label>{{ translation.Promociones }}</mat-label>
                <customan-dual-list
                  [source]="tiposTipoPromocionRecintoSinAsignar"
                  [(destination)]="tiposTipoPromocionTallerAsignadas"
                  key="pkId"
                  display="NombreGrupo"
                  [format]="format"
                  [filter]="true"
                >
                </customan-dual-list>
              </div>
              <!-- <div class="form-group row" style="margin: 20px;">
                <label class="col-sm-12 col-md-2 col-form-label">{{translation.Promociones}}</label>
                <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
                  <mat-list class="w33 izquierda" dense>
                    <p mat-subheader>{{translation.PromocionesAsignadas}}</p>
                    <mat-list-item *ngFor="let promocioni of daTiposPromocion()"
                      (click)="desasignarTipopromocion(promocioni, $event)">
                      <span mat-line>{{promocioni.NombreGrupo}} <mat-icon>arrow_forward_ios</mat-icon> </span>
                    </mat-list-item>
                  </mat-list>
                  <div class="w33 alinear-contenido-centrado">
                    <mat-icon (click)="asignarTodasPromocion()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
                      (click)="deasignarTodasPromocion()">arrow_forward_ios</mat-icon>
                  </div>
                  <mat-list class="w33 derecha" dense>
                    <p mat-subheader>{{translation.PromocionesSinAsignar}}</p>
                    <mat-list-item *ngFor="let promociond of filtrarTiposPromocion()"
                      (click)="asignarTipopromocion(promociond, $event)">
                      <span mat-line>
                        <mat-icon>arrow_back_ios</mat-icon> {{promociond.NombreGrupo}}
                      </span>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div> -->

              <div style="margin: 20px 5px 0 5px; text-align: center">
                <mat-label>{{ translation.Abonados }}</mat-label>
                <customan-dual-list
                  [source]="tiposTipoAbonadoRecintoSinAsignar"
                  [(destination)]="tiposTipoAbonadoTallerAsignados"
                  key="pkId"
                  display="NombreGrupo"
                  [format]="format"
                >
                </customan-dual-list>
              </div>

              <!-- <div class="form-group row" style="margin: 20px;">
                <label class="col-sm-12 col-md-2 col-form-label">{{translation.Abonados}}</label>
                <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
                  <mat-list class="w33 izquierda" dense>
                    <p mat-subheader>{{translation.AbonadosAsignados}}</p>
                    <mat-list-item *ngFor="let abonadoi of daTiposAbonado()"
                      (click)="desasignarTipoabonado(abonadoi, $event)">
                      <span mat-line>{{abonadoi.Nombre}} <mat-icon>arrow_forward_ios</mat-icon> </span>
                    </mat-list-item>
                  </mat-list>
                  <div class="w33 alinear-contenido-centrado">
                    <mat-icon (click)="asignarTodasAbonado()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
                      (click)="deasignarTodasAbonado()">arrow_forward_ios</mat-icon>
                  </div>
                  <mat-list class="w33 derecha" dense>
                    <p mat-subheader>{{translation.AbonadosSinAsignar}}</p>
                    <mat-list-item *ngFor="let abonadod of filtrarTiposAbonado()"
                      (click)="asignarTipoabonado(abonadod, $event)">
                      <span mat-line>
                        <mat-icon>arrow_back_ios</mat-icon> {{abonadod.Nombre}}
                      </span>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div> -->

              <div style="margin: 20px 5px 0 5px; text-align: center">
                <mat-label>{{ translation.Centros }}</mat-label>

                <customan-dual-list
                  [source]="centrosSinAsignar"
                  [(destination)]="centrosAsignados"
                  key="pkId"
                  display="Nombre"
                  [format]="format"
                  [filter]="true"
                >
                </customan-dual-list>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{ translation.SubCanales }}</mat-label>
                    <mat-select
                      placeholder="{{ translation.SubCanales }}"
                      formControlName="Canales"
                      name="Canales"
                    >
                      <mat-option> --- --- </mat-option>
                      <mat-option
                        *ngFor="let element of listaCanales"
                        [value]="element.pkId.toString()"
                      >
                        {{ element.Canal }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <!-- Fin Campos Nuevos -->

              <!--a class="btn btn-primary" style="margin: 5px;" type="button" (click)="newGuardar()">
                {{translation.Guardar}}
              </a-->

              <!-- <div class="menu-acciones-metodo" id="top" #top *ngIf="translation">
              <div class="row flex justify-content-between">
                <div>
                  <button class="btn btn-success btn-magento-naranja" (click)="newGuardar()" [disabled]="isnovalid()"
                    [hidden]="!permisobtnGuardar">{{translation.Guardar}}
                  </button>
                  <button class="btn btn-warning btn-magento-gris-claro" (click)="goToShowClientes()">
                    {{translation.Descartar}}
                  </button>
                </div>

              </div>

            </div> -->

              <!-- <div class="collapse collapse-box" id="horizontal-basic-form1">
              <div class="code-box">
                <div class="clearfix">
                  <a href="javascript:;" class="btn btn-primary btn-sm code-copy pull-left"
                    data-clipboard-target="#horizontal-basic"><i class="fa fa-clipboard"></i> Copy Code</a>
                  <a href="#horizontal-basic-form1" class="btn btn-primary btn-sm pull-right" rel="content-y"
                    data-toggle="collapse" role="button"><i class="fa fa-eye-slash"></i> Hide Code</a>
                </div>
              </div>
            </div> -->

              <!-- horizontal Basic Forms End -->
            </div>
          </div>
        </div>
        <!-- fin nueva maquetacion -->
      </form>
    </div>
  </div>
</div>
