import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserProfile } from "@sharedV11/classes/users/userProfile/userProfile";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { EditUserProfileService } from "@sharedV11/services/components/editUserProfile/edit-user-profile.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SnackbarInfoService } from "../../../../shared/services/alerts/snackbar-info/snackbar-info.service";

@Component({
  selector: "app-profile-save",
  templateUrl: "./profile-save.component.html",
  styleUrls: ["./profile-save.component.scss"],
})
export class ProfileSaveComponent implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();
  private isValidPrivilegiosVar = /^-?\d{1,9}(\.\d{1,2})?$/;

  @Input() pkId: any;
  @Input() mode: any;
  @Input() modificar: boolean;

  editUserProfileForm: FormGroup;
  userProfile: UserProfile;

  translation: any;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };
  centros;

  //centros
  constructor(
    private translator: Translator,
    private editUserProfileService: EditUserProfileService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    //this.initializeForms()
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode);
    this.initializeFormValidations();
  }

  loadCentros() {
    this.gsCentros.getCentros();
  }

  get formControls() {
    return this.editUserProfileForm.controls;
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.userProfile = this.editUserProfileForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != undefined) {
      this.serviceCall(pkId);
    }
  }

  initializeFormValidations() {
    this.formControls.Privilegios.setValidators([
      Validators.required,
      Validators.pattern(this.isValidPrivilegiosVar),
    ]);
    this.formControls.Nombre.setValidators([Validators.required]);
    //this.formControls.Descripcion.setValidators([Validators.required]);
  }

  /*  initializeFormWithData(userProfile:UserProfile){
     this.editUserProfileForm= this.fb.group({
       pkId:[userProfile.pkId],
       Nombre:[userProfile.Nombre],
       Descripcion:[userProfile.Descripcion],
       Privilegios:[userProfile.Privilegios]
     });
   } */
  initializeFormWithoutData() {
    this.editUserProfileForm = this.fb.group({
      pkId: [""],
      Nombre: [""],
      Descripcion: [""],
      Privilegios: [""],
    });
  }

  serviceCall(pkId) {
    this.editUserProfileService
      .findUserByPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (userprofileApi) => {
          let userProfile = userprofileApi["DatosResult"]["tipoUsuarios"][0];
          if (userProfile != null) {
            this.changeValueForm(userProfile);
            this.userProfile = userProfile;
            this.valuedChanged();
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados = this.gsCentros.daTiposCentros(
              this.userProfile
            );
            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  changeValueForm(userProfile: UserProfile) {
    this.formControls["pkId"].setValue(userProfile.pkId);
    this.formControls["Nombre"].setValue(userProfile.Nombre);
    this.formControls["Descripcion"].setValue(userProfile.Descripcion);
    this.formControls["Privilegios"].setValue(userProfile.Privilegios);
  }

  submit() {
    let user: UserProfile = this.editUserProfileForm.value;

    this.editUserProfileForm.markAllAsTouched();
    if (this.editUserProfileForm.valid) {
      user.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
        this.centrosAsignados
      );

      this.manageApi(user, this.mode);
    }
  }
  destroy() {
    this.panelService.setDiscardChanges(true);
    this.loading = false;
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.userProfile);
  }

  manageApi(userProfile: UserProfile, mode) {
    this.loading = true;
    switch (mode) {
      case "I":
        //insert
        userProfile.pkId = "0";
        this.insTiposUsuario(userProfile);
        break;
      case "U":
        //update
        this.updTiposUsuario(userProfile);

        break;
      case "D":
        //duplicate
        userProfile.pkId = "0";
        this.insTiposUsuario(userProfile);
        break;

      default:
        break;
    }
  }

  updTiposUsuario(userProfile: UserProfile) {
    //  if (this.notEmptyFields(userProfile)) {
    this.editUserProfileService
      .updTiposUsuario(userProfile)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          if (response.DatosResult != null) this.nextWindow(response);
          else
            this.snackvar_service.openSnackBar(
              response.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar",
              response.Mensajes[0].CodigoMensaje
            );
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    //  }
  }

  insTiposUsuario(userProfile: UserProfile) {
    //  if (this.notEmptyFields(userProfile)) {
    this.editUserProfileService
      .insTiposUsuario(userProfile)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          if (response.Mensajes.length > 0)
            this.snackvar_service.openSnackBar(
              response.Mensajes[0].DescripcionMensaje,
              "",
              "red-snackbar",
              response.Mensajes[0].CodigoMensaje
            );
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
    //  }
  }

  notEmptyFields(user: UserProfile): boolean {
    let res = true;
    if (user.Nombre.length === 0) {
      res = false;
    }
    return res;
  }

  nextWindow(response: any) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save();
      this.destroy();
      if (response.Mensajes.length > 0) {
        console.table("response.Mensajes::", response.Mensajes);
        response.Mensajes.forEach((element) => {
          this.snackvar_service.openSnackBar(
            element.DescripcionMensaje,
            "",
            "red-snackbar",
            element.CodigoMensaje
          );
        });
      } else {
        this.snackvar_service.openSnackBar(
          this.translation["MensajeSuccessSnackBar"],
          "",
          "green-snackbar"
        );
      }
    }
  }

  valuedChanged() {
    this.editUserProfileForm.valueChanges.subscribe((value) => {
      if (this.userProfile != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.userProfile)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  existErrors() {
    return this.formControls.Nombre.errors ||
      this.formControls.Privilegios.errors ||
      this.formControls.Descripcion.errors
      ? true
      : false;
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }

  getErrorMessage(field: string) {
    let mensaje: string;
    let fc_pv = this.editUserProfileForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        mensaje = this.translation["CampoRequerido"];
      } else if (fc_pv.hasError("pattern")) {
        mensaje = this.translation["SoloNumeros"];
      }
    }
    return mensaje;
  }
}
