import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Tiporeserva } from '@sharedV11/classes/tarifas/tiposreservas';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { EditTiposReservasService } from '@sharedV11/services/components/tiposreservas/edit-tiposreservas';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';


@Component({
  selector: 'app-edit-tiposreserva',
  templateUrl: './edit-tiposreserva.component.html',
  styleUrls: ['./edit-tiposreserva.component.scss']
})
export class EditTiposreservaComponent implements OnInit {

  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;

  editRegistroForm: FormGroup;

  Tiporeserva:Tiporeserva;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };

  constructor(private panelService: PanelService,
    private fb: FormBuilder,
    private translator: Translator,
    private httpErrorService: HttpErrorsService,
    private snackvar_service: SnackbarInfoService,
    private editTiposReservasService:EditTiposReservasService,
    private gsCentros: GlobalServicCentros
    ) { }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode)
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeFormWithoutData() {

    this.editRegistroForm = this.fb.group({
      pkId: [""],
      Nombre: ["", Validators.required],
      Descripcion: [""],
      MaximoInternet: [""],
      MinimoInternet:[""],
      IntervaloControl:[""],
      LimiteIntervalo:[""],
      ControlHorario:[""],
      TiempoLimite:[""],
      MostrarHorario:[""],
      ControlarAforo:[""],
      IncluirLLegada:[""],
      Internet:[""],
      deBaja:[""],
      dePago:[""]
    
    });

  }

  manageForm(pkId, mode) {

    if (mode == "I") {
      this.Tiporeserva = this.editRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(this.Tiporeserva);
      this.loading = false;
      this.valuedChanged();

    }
    if (pkId != null) {
      this.findTiporeserva(pkId)
    }
  }

  findTiporeserva(pkId) {
    this.editTiposReservasService.findTpvByPkId(pkId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(TiporeservaApi => {
      // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
      let Tiporeserva: Tiporeserva = TiporeservaApi["DatosResult"]["listaTipoReserva"][0]

      if (Tiporeserva != null) {
        this.changeValueForm(Tiporeserva)
        this.Tiporeserva = this.editRegistroForm.value;
        this.Tiporeserva.CentrosIds = Tiporeserva.CentrosIds;
        this.valuedChanged();
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.Tiporeserva);
        this.loading = false;
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }

  changeValueForm(Tiporeserva:Tiporeserva) {
    this.editRegistroForm.get("pkId").setValue(Tiporeserva.pkId);
   
    this.editRegistroForm.get("Nombre").setValue(Tiporeserva.Nombre);
    this.editRegistroForm.get("Descripcion").setValue(Tiporeserva.Descripcion);
    this.editRegistroForm.get("IntervaloControl").setValue(Tiporeserva.IntervaloControl);
    this.editRegistroForm.get("LimiteIntervalo").setValue(Tiporeserva.LimiteIntervalo);
    this.editRegistroForm.get("ControlHorario").setValue(Tiporeserva.ControlHorario == "1" ? true : false);
    this.editRegistroForm.get("TiempoLimite").setValue(Tiporeserva.TiempoLimite == "1" ? true : false);
    this.editRegistroForm.get("MostrarHorario").setValue(Tiporeserva.MostrarHorario == "1" ? true : false);
    this.editRegistroForm.get("ControlarAforo").setValue(Tiporeserva.ControlarAforo == "1" ? true : false);
    this.editRegistroForm.get("IncluirLLegada").setValue(Tiporeserva.IncluirLLegada == "1" ? true : false);
    this.editRegistroForm.get("Internet").setValue(Tiporeserva.Internet == "1" ? true : false);
    this.editRegistroForm.get("MaximoInternet").setValue(Tiporeserva.MaximoInternet);
    this.editRegistroForm.get("MinimoInternet").setValue(Tiporeserva.MinimoInternet);

    this.editRegistroForm.get("deBaja").setValue(Tiporeserva.deBaja == "1" ? true : false);
    this.editRegistroForm.get("dePago").setValue(Tiporeserva.dePago == "1" ? true : false);
   
 
 
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe(value => {
      if (this.Tiporeserva != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.Tiporeserva)) {
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }


  discard() {
    this.changeValueForm(this.Tiporeserva)
  }

  manageApi(Tiporeserva: Tiporeserva, mode) {

    if (this.editRegistroForm.controls["deBaja"].value == true)
      Tiporeserva.deBaja = "1"
    else
      Tiporeserva.deBaja = "0";
    
    if (this.editRegistroForm.controls["dePago"].value == true)
      Tiporeserva.dePago = "1"
    else
      Tiporeserva.dePago = "0";

    if (this.editRegistroForm.controls["ControlHorario"].value == true)
      Tiporeserva.ControlHorario = "1"
    else
      Tiporeserva.ControlHorario = "0";

    if (this.editRegistroForm.controls["TiempoLimite"].value == true)
      Tiporeserva.TiempoLimite = "1"
    else
      Tiporeserva.TiempoLimite = "0";      

    if (this.editRegistroForm.controls["MostrarHorario"].value == true)
      Tiporeserva.MostrarHorario = "1"
    else
      Tiporeserva.MostrarHorario = "0"; 

    if (this.editRegistroForm.controls["ControlarAforo"].value == true)
      Tiporeserva.ControlarAforo = "1"
    else
      Tiporeserva.ControlarAforo = "0"; 

    if (this.editRegistroForm.controls["IncluirLLegada"].value == true)
      Tiporeserva.IncluirLLegada = "1"
    else
      Tiporeserva.IncluirLLegada = "0"; 

    if (this.editRegistroForm.controls["Internet"].value == true)
      Tiporeserva.Internet = "1"
    else
      Tiporeserva.Internet = "0"; 

    this.loading = true;
    Tiporeserva.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);

    switch (mode) {
      case "I":
        //insert
        Tiporeserva.pkId = "0"
        this.InsUpTiporeserva(Tiporeserva,"I")
        break;
      case "U":
        //update
        this.InsUpTiporeserva(Tiporeserva,"U")

        break;
      case "D":
        //duplicate
        Tiporeserva.pkId = "0"
        this.InsUpTiporeserva(Tiporeserva,"I")

        break;

      default:
        break;
    }
  }


  DelTiporeserva(TPV){
/*   this.editTiposReservasService.updCategoria(TPV, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     }); */
   }

   InsUpTiporeserva(tiporeserva,mode){
     this.editTiposReservasService.insUpTipoReserva(tiporeserva, mode).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });    
   }

  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
  }

  submit() {

    this.editRegistroForm.markAllAsTouched()
    let Tiporeserva: Tiporeserva = this.editRegistroForm.value;
    if (this.editRegistroForm.valid) {
      this.manageApi(Tiporeserva, this.mode)
    } 

  }

  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if(fc_pv){
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }
    
    return "Error";
  }

  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      console.table(response.Mensajes);
      response.Mensajes.forEach(element => {
        this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
      });
    } else {
      this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
    }
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }
  
  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return (fc_pv[field].errors != null || fc_pv[field].invalid) ? true : false;
  }

}
