import { Injectable } from '@angular/core';
import { Permiso } from '@sharedV11/classes/userResponse/permission';
import { CLienteAPi } from '../../classes/userRequest/client_api';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  clienteapi: CLienteAPi = { Id: '1', Token: 'aaa', EnlaceMenu: 'a' };
  idAplicacion: number = 19; //19 = CUSTOMMAN
  iniciarLogPass: string = 'CUSTO$A$SZ45$A$MMAN$A$23g!.';
  divisapordefectoSistema = "2";
  maxBotonesRapidosTPV = 50; //72
  //Constantes Identificadas

  Permisos: Array<Permiso> = [];
  
  arr_nivlelesEntrada: Array<any> = [
    { id: '1', value: 'NORMAL' },
    { id: '2', value: 'REDUCIDA' },
    { id: '3', value: 'GRATUITA' },
    { id: '4', value: 'NORMAL IDA Y VUELTA' },
    { id: '5', value: 'REDUCIDA IDA Y VUELTA' },
    { id: '6', value: 'GRATUITA IDA Y VUELTA' }
  ];

  diasSemana: Array<any> = [
    { id: '1', value: 'Lunes' },
    { id: '2', value: 'Martes' },
    { id: '3', value: 'Miércoles' },
    { id: '4', value: 'Jueves' },
    { id: '5', value: 'Viernes' },
    { id: '6', value: 'Sábado' },
    { id: '7', value: 'Domingo' }
  ];

  tiposAforo: Array<any> = [
    { id: 'SF', value: 'Sesiones Fijas' },
    { id: 'SI', value: 'Sesiones Irregulares' },
    { id: 'SD', value: 'Sesiones Diarias' }/*,
    { id: 'PI', value: 'Sesiones por Intervalo' },
    { id: 'SP', value: 'Sesiones Privadas' }
    */
  ];

  plataformasVenta: Array<any> = [
    { id: '1', value: 'Internet' },
    { id: '2', value: 'Punto Venta KORE' }
  ]; //, { id: '3', value: "Taquilla Automática" }

  tipoProductoAsociadoTarifa: Array<any> = [{ id: '1', value: 'Entrada' }]; // , { id: '2', value: "Promoción" }, { id: '3', value: "Abonado" }
  impresorasSeleccionables: Array<any> = [
    { id: '0', value: 'Primaria' },
    { id: '1', value: 'Secundaria' },
    { id: '2', value: 'Ambas' }
  ];

  tiposVenta: Array<any> = [
    { id: '0', value: 'Todos' },
    { id: '1', value: 'Online' },
    { id: '2', value: 'Taquilla' }
  ];

  clasesCodigo: Array<any> = [
    { id: 'U', value: 'unico' },
    { id: 'M', value: 'multiple' },
    { id: 'P', value: 'porproducto' },
    { id: 'E', value: 'porespectaculo' }
  ];

  
  //Pasar un Valor string a boolean
  aBooleano(valor) {
    if (typeof valor === 'string') {
      if (
        valor.toLowerCase().trim() === 'false' ||
        valor.toLowerCase().trim() === '0' ||
        valor.toLowerCase().trim() === ''
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (typeof valor === 'boolean') {
        return valor;
      } else {
        if (typeof valor === 'number') {
          if (valor > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  }
  anadirCeroHoraLeft(hora: string, largo?: number): string {
    let respuesta: string = '';
    if (largo == null) largo = 8;
    while (hora.length + respuesta.length < largo) {
      respuesta = respuesta + '0';
    }
    return respuesta + hora;
  }

  aFecha(fecha: string): Date {
    // 
    // 
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      return new Date(parseInt(parts[2].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[0]));
    }
  }


  aHora(fecha: string): string {
    // 
    // 
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var horapart = fecha.split(" ")[1];
      var parts = horapart.split(":");
      let h = this.zfill(parseInt(parts[0]),2);
      let m = this.zfill(parseInt(parts[1]),2);
      let s = this.zfill(parseInt(parts[2]),2);
      return h+":"+m+":"+s;
    }
  }

  zfill(number, width) {
      var numberOutput = Math.abs(number); /* Valor absoluto del número */
      var length = number.toString().length; /* Largo del número */ 
      var zero = "0"; /* String de cero */  
      
      if (width <= length) {
          if (number < 0) {
              return ("-" + numberOutput.toString()); 
          } else {
              return numberOutput.toString(); 
          }
      } else {
          if (number < 0) {
              return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
          } else {
              return ((zero.repeat(width - length)) + numberOutput.toString()); 
          }
      }
  }

  //Pasar un Valor string a Bit (0/1)
  aBit(valor) {
    if (typeof valor === 'string') {
      if (
        valor.toLowerCase().trim() === 'false' ||
        valor.toLowerCase().trim() === '0' ||
        valor.toLowerCase().trim() === ''
      ) {
        return 0;
      } else {
        return 1;
      }
    } else {
      if (typeof valor === 'boolean') {
        if (valor) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (typeof valor === 'number') {
          if (valor > 0) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    }
  }

}
