<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>

  <form [formGroup]="editorRegistroForm" id="formulario">
    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ translation.Nombre }}</mat-label>
          <input
            matInput
            formControlName="Nombre"
            (change)="cambiaNombre($event)"
            [ngClass]="{ 'is-invalid': f.Nombre.errors }"
          />
          <mat-error *ngIf="f.Nombre.errors">
            <span *ngIf="f.Nombre.errors.required">{{
              translation.SeRequiereNombre
            }}</span>
            <span *ngIf="f.Nombre.errors?.maxlength">
              {{ translation.superadamaximalongitud }}</span
            >
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ translation.Descripcion }}</mat-label>
          <input
            matInput
            formControlName="Descripcion"
            [ngClass]="{ 'is-invalid': f.Descripcion.errors }"
          />
          <mat-error *ngIf="f.Descripcion.errors">
            <span *ngIf="f.Descripcion.errors?.maxlength">
              {{ translation.superadamaximalongitud }}</span
            >
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ translation.TextoEnPantalla }}</mat-label>
          <input matInput formControlName="TextoPantalla" />
          <mat-error *ngIf="f.TextoPantalla.errors">
            <span *ngIf="f.TextoPantalla.errors?.maxlength">
              {{ translation.superadamaximalongitud }}</span
            >
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ translation.TextoEnDisplay }}</mat-label>
          <input matInput formControlName="TextoDisplay" />
          <mat-error *ngIf="f.TextoDisplay.errors">
            <span *ngIf="f.TextoDisplay.errors?.maxlength">
              {{ translation.superadamaximalongitud }}</span
            >
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ translation.TextoImpresion }}</mat-label>
          <input matInput formControlName="TextoImpresion" />
          <mat-error *ngIf="f.TextoImpresion.errors">
            <span *ngIf="f.TextoImpresion.errors?.maxlength">
              {{ translation.superadamaximalongitud }}</span
            >
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ translation.NombreAbreviado }}</mat-label>
          <input matInput formControlName="TipoAbreviado" />
          <mat-error *ngIf="f.TipoAbreviado.errors">
            <span *ngIf="f.TipoAbreviado.errors?.maxlength">
              {{ translation.superadamaximalongitud }}</span
            >
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!--<div class='grupo-editor-campo-registro separadorLateral'>
      <label>{{translation.NombreAbreviado}}</label><input formControlName="nombreabreviado"
        class="editor-campo-registro form-control" >
    </div>-->

    <!--    <input list="suggestionList" id="answerInput">
<datalist id="suggestionList">
    <option data-value="42">The answer</option>
</datalist>
<input type="hidden" name="answer" id="answerInput-hidden"> -->
    <!----------->
    <!--SELECTS-->
    <!----------->
    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType && tiposGrupoTipoEntrada"
          formControlText="GrupoId"
          [label]="translation.GrupoEntrada"
          [placeHolderTranslation]="translation.TipoGrupoDeEntradas"
          [list]="tiposGrupoTipoEntrada"
          textShow="NombreGrupo"
          dataInput="pkId"
          [disabled]="tiposGrupoTipoEntrada.length < 1"
          [data]="entryType.GrupoId"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.GrupoEntrada}}</mat-label>
          <mat-select placeholder="{{translation.TipoGrupoDeEntradas}}" formControlName="GrupoId" name="GrupoId">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let tipoGrupos1 of tiposGrupoTipoEntrada" [value]="tipoGrupos1.pkId">
              {{tipoGrupos1.NombreGrupo}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="GrupoMotivoId"
          [label]="translation.GrupoMotivo"
          [disabled]="tiposGrupoTipoEntradaMotivo.length < 1"
          [placeHolderTranslation]="translation.TipoGrupoDeMotivosDeEntradas"
          [list]="tiposGrupoTipoEntradaMotivo"
          textShow="NombreGrupo"
          dataInput="pkId"
          [data]="entryType.GrupoMotivoId"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.GrupoMotivo}}</mat-label>
          <mat-select placeholder="{{translation.TipoGrupoDeMotivosDeEntradas}}" formControlName="GrupoMotivoId"
             >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let tipoGrupos2 of tiposGrupoTipoEntradaMotivo" [value]="tipoGrupos2.pkId">
              {{tipoGrupos2.NombreGrupo}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>

    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="TipoProducto"
          [label]="translation.TipoProducto"
          [disabled]="tiposProductosDatos.length < 1"
          [placeHolderTranslation]="translation.TipoProducto"
          [list]="tiposProductosDatos"
          textShow="Descripcion"
          dataInput="pkId"
          [data]="entryType.TipoProducto"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoProducto}}</mat-label>
          <mat-select placeholder="{{translation.TipoProducto}}" formControlName="TipoProducto"
             >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let element of tiposProductosDatos" [value]="element.pkId">
              {{element.Descripcion}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="GrupoAsociado"
          [label]="translation.GrupoAsociado"
          [disabled]="gruposAsociadosDatos.length < 1"
          [placeHolderTranslation]="translation.GrupoAsociado"
          [list]="gruposAsociadosDatos"
          textShow="Descripcion"
          dataInput="pkId"
          [data]="entryType.GrupoAsociado"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>

      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.GrupoAsociado}}</mat-label>
          <mat-select placeholder="{{translation.GrupoAsociado}}" formControlName="GrupoAsociado"
             >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let element of gruposAsociadosDatos" [value]="element.pkId">
              {{element.Descripcion}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>

    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="TipoVisita"
          [label]="translation.TipoVisita"
          [disabled]="tipoVisitaDatos.length < 1"
          [placeHolderTranslation]="translation.TipoVisita"
          [list]="tipoVisitaDatos"
          textShow="Descripcion"
          dataInput="pkId"
          [data]="entryType.TipoVisita"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoVisita}}</mat-label>
          <mat-select placeholder="{{translation.TipoVisita}}" formControlName="TipoVisita"
             >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let element of tipoVisitaDatos" [value]="element.pkId">
              {{element.Descripcion}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="TipoNivel"
          [label]="translation.TipoNivel"
          [disabled]="tiposNivelesDatos.length < 1"
          [placeHolderTranslation]="translation.TipoNivel"
          [list]="tiposNivelesDatos"
          textShow="Codigo"
          dataInput="pkId"
          [data]="entryType.TipoNivel"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoNivel}}</mat-label>
          <mat-select placeholder="{{translation.TipoNivel}}" formControlName="TipoNivel"
             >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let element of tiposNivelesDatos" [value]="element.pkId">
              {{element.Codigo}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>

    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="TipoPublicoId"
          [label]="translation.TiposPublico"
          [disabled]="tipoPublicoDatos.length < 1"
          [placeHolderTranslation]="translation.TiposPublico"
          [list]="tipoPublicoDatos"
          textShow="Descripcion"
          dataInput="pkId"
          [data]="entryType.TipoPublicoId"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="TipoVisitanteId"
          [label]="translation.TiposVisitante"
          [disabled]="tipoVisitanteDatos.length < 1"
          [placeHolderTranslation]="translation.TiposVisitante"
          [list]="tipoVisitanteDatos"
          textShow="Descripcion"
          dataInput="pkId"
          [data]="entryType.TipoVisitanteId"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="Categoria"
          [label]="translation.Categoria"
          [disabled]="categoriaDatos.length < 1"
          [placeHolderTranslation]="translation.Categoria"
          [list]="categoriaDatos"
          textShow="chNombre"
          dataInput="pkId"
          [data]="entryType.Categoria"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.Categoria}}</mat-label>
          <mat-select placeholder="{{translation.Categoria}}" formControlName="Categoria"
             >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let element of categoriaDatos" [value]="element.pkId">
              {{element.chNombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="TEDDatos"
          [label]="translation.DatosNominativos"
          [disabled]="tiposEntradaDatos.length < 1"
          [placeHolderTranslation]="translation.DatosNominativos"
          [list]="tiposEntradaDatos"
          textShow="Nombre"
          dataInput="TEDatosId"
          [data]="entryType.TEDDatos"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.DatosNominativos}}</mat-label>
          <mat-select placeholder="{{translation.DatosNominativos}}" formControlName="TEDDatos"
             >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let item of tiposEntradaDatos" [value]="item.TEDatosId">
              {{item.Nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>

    <!-------------------------------------------------------------------------------------------->
    <div *ngIf="editorRegistroForm.get('CentroCosteId')">
      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <app-input-filter
            *ngIf="entryType"
            formControlText="CentroCosteId"
            [label]="translation.CentroCoste"
            [disabled]="centroCosteDatos.length < 1"
            [placeHolderTranslation]="translation.CentroCoste"
            [list]="centroCosteDatos"
            textShow="CentroCoste"
            dataInput="CentroCosteId"
            [data]="entryType.CentroCosteId"
            (close)="closeInputFilter($event)"
          ></app-input-filter>
        </div>
        <!-- <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.CentroCoste}}</mat-label>
            <mat-select placeholder="{{translation.CentroCoste}}" formControlName="CentroCosteId"
               >
              <mat-option>--</mat-option>
              <mat-option *ngFor="let element of centroCosteDatos" [value]="element.CentroCosteId">
                {{element.CentroCoste}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <div class="col-sm-12 col-md-6">
          <app-input-filter
            *ngIf="entryType"
            formControlText="CanalId"
            [label]="translation.SubCanales"
            [disabled]="canalesDatos.length < 1"
            [placeHolderTranslation]="translation.SubCanales"
            [list]="canalesDatos"
            textShow="SubCanal"
            dataInput="pkId"
            [data]="entryType.CanalId"
            (close)="closeInputFilter($event)"
          ></app-input-filter>
        </div>
        <!-- <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.Canales}}</mat-label>
            <mat-select placeholder="{{translation.Canales}}" formControlName="CanalId" 
              >
              <mat-option>--</mat-option>
              <mat-option *ngFor="let item of canalesDatos" [value]="item.Id">
                {{item.Canal}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
    </div>
    <!-------------------------------------------------------------------------------------------->

    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <app-input-filter
          *ngIf="entryType"
          formControlText="CodigoTipoControl"
          [label]="translation.TipoControl"
          [disabled]="tiposTiposControl.length < 1"
          [placeHolderTranslation]="translation.TipoControlAcceso"
          [list]="tiposTiposControl"
          textShow="Nombre"
          dataInput="CodigoTipoControl"
          [data]="entryType.CodigoTipoControl"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoControl}}</mat-label>
          <mat-select placeholder="{{translation.TipoControlAcceso}}" formControlName="CodigoTipoControl"
            name="tipoTipoControl" >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let tipoGrupos3 of tiposTiposControl" [value]="tipoGrupos3.CodigoTipoControl">
              {{tipoGrupos3.Nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="col-sm-12 col-md-6">
        <!--<label>{{translation.ControlaAcceso}}</label>
        <label class="switch">
          <input formControlName="ControlarAcceso" type="checkbox"
            class="editor-campo-registro editor-campo-registro-check form-check-input form-control"
            >-->
        <app-input-filter
          *ngIf="entryType"
          formControlText="NivelesId"
          [label]="translation.NivelEntrada"
          [disabled]="NivelDatos.length < 1"
          [placeHolderTranslation]="translation.NivelEntrada"
          [list]="NivelDatos"
          textShow="Descripcion"
          dataInput="pkId"
          [data]="entryType.NivelesId"
          (close)="closeInputFilter($event)"
        ></app-input-filter>
        <!--<span class="slider round"></span>
        </label>-->
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12 col-md-4">
        <mat-slide-toggle color="primary" formControlName="ALaVenta">
          {{ translation.ALaVenta }}</mat-slide-toggle
        >
      </div>
      <div class="col-sm-12 col-md-4">
        <mat-slide-toggle color="primary" formControlName="ImprimirTicket">
          {{ translation.ImprimirTicket }}</mat-slide-toggle
        >
      </div>
      <div class="col-sm-12 col-md-4">
        <mat-slide-toggle color="primary" formControlName="CodigoBarras">
          {{ translation.ImprimirCodigoDeBarras }}</mat-slide-toggle
        >
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12 col-md-4">
        <mat-slide-toggle color="primary" formControlName="BarCodeInternet">
          {{ translation.ImprimirCodigoDeBarrasOnline }}</mat-slide-toggle
        >
      </div>
      <div class="col-sm-12 col-md-4">
        <mat-slide-toggle color="primary" formControlName="ImprimirPrecio">
          {{ translation.ImprimirPrecio }}</mat-slide-toggle
        >
      </div>
      <div class="col-sm-12 col-md-4">
        <mat-slide-toggle color="primary" formControlName="CuentaVisitante">
          {{ translation.ControlComoVisitante }}</mat-slide-toggle
        >
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12 col-md-4">
        <mat-slide-toggle
          color="primary"
          formControlName="ImprimirPromoInternet"
        >
          {{ translation.bImprimirPromoInternet }}</mat-slide-toggle
        >
      </div>
      <div class="col-sm-12 col-md-4">
        <mat-slide-toggle color="primary" formControlName="ControlarAcceso">
          {{ translation.ControlaAcceso }}</mat-slide-toggle
        >
      </div>
    </div>

    <!-- Botonera -->
    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{ translation.PosicionEnBotonera }}</mat-label>
          <mat-select
            placeholder="{{
              translation.TeclaRapidaEnBotoneraAsignacionActual
            }}"
            formControlName="TeclaRapida"
          >
            <mat-option>--</mat-option>
            <ng-container *ngFor="let element of tiposBotoneraEntrada">
              <mat-option
                *ngIf="element.NombreTipo != ''"
                disabled
                [value]="element.TeclaRapida"
              >
                {{ element.TeclaRapida }} : {{ element.NombreTipo }}
              </mat-option>
              <mat-option
                *ngIf="element.NombreTipo == ''"
                [value]="element.TeclaRapida"
              >
                {{ element.TeclaRapida }} : {{ element.NombreTipo }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row" style="margin-top: 20px">
      <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{ translation.Impresoras }}</mat-label>
          <mat-select
            placeholder="{{ translation.SeleccioneImpresoras }}"
            formControlName="ImpresoraSecundaria"
            name="tipoControlImpresora"
          >
            <mat-option>--</mat-option>
            <mat-option
              *ngFor="let impresora of global.impresorasSeleccionables"
              [value]="impresora.id"
            >
              {{ impresora.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{ translation.TipoButacas }}</mat-label>
          <mat-select
            placeholder="{{ translation.TiposDeButacas }}"
            formControlName="TipoButacaId"
            name="tipoTipoControlButacas"
          >
            <mat-option>--</mat-option>
            <mat-option
              *ngFor="let tipoGrupos4 of tiposTiposButaca"
              [value]="tipoGrupos4.Id"
            >
              {{ tipoGrupos4.Nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <app-json2control
          #jsonfield
          [StringJSON]="myJSON"
          [mayremove]="false"
          [mayadd]="true"
          [config]="configJSON"
          textHeader="Configuración Avanzada"
          contextHelp=""
        >
        </app-json2control>
      </div>
    </div>
    <!-- Recintos -->
    <div style="margin: 20px 5px 0 5px; text-align: center">
      <mat-label>{{ translation.Recintos }}</mat-label>

      <customan-dual-list
        [source]="recintosSinAsignar"
        [(destination)]="recintosAsignados"
        key="pkId"
        display="Nombre"
        [format]="format"
        [filter]="true"
      >
      </customan-dual-list>
    </div>
    <!-- <div class="form-group row" style="margin: 20px;">
      <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
        <mat-list class="w33 izquierda" dense>
          <p mat-subheader>{{translation.RecintosAsignados}}</p>
          <mat-list-item *ngFor="let recintoi of daTiposRecintos(true)">
            <div style="display: flex">
              <input class="small-input" type="text" [formControlName]="recintoi.pkId">
              <span mat-line>{{recintoi.Nombre}} <mat-icon (click)="desasignarTipoRecinto(recintoi, $event)">
                  arrow_forward_ios</mat-icon> </span>
            </div>
          </mat-list-item>
        </mat-list>
        <div class="w33 alinear-contenido-centrado">
          <mat-icon (click)="asignarTodosRecintos()">arrow_back_ios</mat-icon> {{translation.Todos}}
          <mat-icon (click)="deasignarTodosRecintos()">arrow_forward_ios</mat-icon>
          -<mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
          (click)="deasignarTodosRecintos()">arrow_forward_ios</mat-icon>-
        </div>
        <mat-list class="w33 derecha" dense>
          <p mat-subheader>{{translation.RecintosSinAsignar}}</p>
          <mat-list-item *ngFor="let recintod of filtrarTiposRecintos(true)">
            <span mat-line>
              <mat-icon (click)="asignarTipoRecinto(recintod, $event)">arrow_back_ios</mat-icon> {{recintod.Nombre}}
            </span>
          </mat-list-item>
        </mat-list>
      </div>
    </div> -->
    <div style="margin: 20px 5px 0 5px; text-align: center">
      <mat-label>{{ translation.Actividades }}</mat-label>

      <customan-dual-list
        [source]="actividadesSinAsignar"
        [(destination)]="actividadesAsignadas"
        key="pkId"
        display="Nombre"
        [format]="format"
        [filter]="true"
      >
      </customan-dual-list>
    </div>
    <!-- <div class="form-group row" style="margin: 20px;">
      <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
        <mat-list class="w33 izquierda" dense>
          <p mat-subheader>{{translation.ActividadesAsignadas}}</p>
          <mat-list-item *ngFor="let actividadi of daTiposRecintos(false)">
            <div style="display: flex">
              <input class="small-input" type="text" [formControlName]="actividadi.pkId">
              <span mat-line>{{actividadi.Nombre}} <mat-icon (click)="desasignarTipoActividad(actividadi, $event)">
                  arrow_forward_ios</mat-icon> </span>
            </div>
          </mat-list-item>
        </mat-list>
        <div class="w33 alinear-contenido-centrado">
          <mat-icon (click)="asignarTodasActividades()">arrow_back_ios</mat-icon> {{translation.Todos}}
          <mat-icon (click)="deasignarTodasActividades()">arrow_forward_ios</mat-icon>
          --<mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
          (click)="deasignarTodosRecintos()">arrow_forward_ios</mat-icon>--
        </div>
        <mat-list class="w33 derecha" dense>
          <p mat-subheader>{{translation.ActividadesSinAsignar}}</p>
          <mat-list-item *ngFor="let recintod of filtrarTiposRecintos(false)">
            <span mat-line>
              <mat-icon (click)="asignarTipoActividad(recintod, $event)">arrow_back_ios</mat-icon> {{recintod.Nombre}}
            </span>
          </mat-list-item>
        </mat-list>
      </div>
    </div> -->
    <!-------------------------------------------------->
    <div style="margin: 20px 5px 0 5px; text-align: center">
      <mat-label>{{ translation.Centros }}</mat-label>

      <customan-dual-list
        [source]="centrosSinAsignar"
        [(destination)]="centrosAsignados"
        key="pkId"
        display="Nombre"
        [format]="format"
        [filter]="true"
      >
      </customan-dual-list>
    </div>
    <!--   <div class="form-group row" style="margin: 20px;">
      <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
        <mat-list class="w33 izquierda" dense>
          <p mat-subheader>{{translation.CentrosAsignados}}</p>
          <mat-list-item *ngFor="let centrosi of daTiposCentros()">
            <span mat-line>{{centrosi.Nombre}} <mat-icon (click)="desasignarTipoCentro(centrosi, $event)">
                arrow_forward_ios</mat-icon> </span>
          </mat-list-item>
        </mat-list>
        <div class="w33 alinear-contenido-centrado">
          <mat-icon (click)="asignarTodosCentros()">arrow_back_ios</mat-icon> {{translation.Todos}}
          <mat-icon (click)="deasignarTodosCentros()">arrow_forward_ios</mat-icon>
          !--<mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
          (click)="deasignarTodosRecintos()">arrow_forward_ios</mat-icon>--
        </div>
        <mat-list class="w33 derecha" dense>
          <p mat-subheader>{{translation.CentrosSinAsignar}}</p>
          <mat-list-item *ngFor="let centrod of filtrarTiposCentros()">
            <span mat-line>
              <mat-icon (click)="asignarTipoCentro(centrod, $event)">arrow_back_ios</mat-icon> {{centrod.Nombre}}
            </span>
          </mat-list-item>
        </mat-list>
      </div>
    </div> -->
    <!--------------------------------------------------->
  </form>
</div>
